import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import moment from "moment-timezone";
import ScheduleTaskModal from "./ScheduleTaskModal";
import { useNavigate } from "react-router-dom";
import LogTask from "./LogTask";
import useAuth from "../../hooks/useAuth";
import { CheckSquare } from "react-feather";

const CompletedTask = ({type}) => {
  const navigate = useNavigate();
  const [resultCount, setResultCount] = useState(0);
  const [current, setOld] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrent] = useState("");
  const duration = [moment().subtract(90, "day").startOf('days'), moment().endOf('days')];
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const {user} = useAuth()

  useEffect(() => {
    if(type && user && !user[type]) {
      navigate('/')
    }
  }, [user, type])
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: cell => (
        <span onClick={() => btnClick( cell.row.original)} className="text-primary cursor-pointer">{cell.value}</span>
      )
    },
    {
      Header: "Description",
      accessor: "description",
      className: 'w-25',
      Cell: (cell) => <div className="text-nowrap overflow-hidden" title={cell.value}
                           style={{ textOverflow: "ellipsis", minWidth: 0 }}>{cell.value}</div>
    },
    {
      Header: "Completed Date",
      accessor: "completed",
      Cell: (cell) => <div>
        <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
      </div>
    },{
      Header: "Asset/Zone",
      accessor: "parentName",
      accessorName: "parentName",
      type: "linkStatus",
      reference: 'parentReference',
      navigateId: 'parentId',
      typeName: 'parentType',
    },
    {
      Header: "Completed By",
      accessor: "childName",
      accessorName: "childName",
      reference: 'childReference',
      type: "linkStatus",
      navigateId: 'childId',
      typeName: 'childType',
    }
  ];

  const handleSuccess = () => {
    notify.open({
      type: "success",
      message: "Changes Saved"
    });
    setShowModal(false);
    navigate('/scheduledtask')
  };

  const btnClick = (id) => {
    setOld(id);
    setCurrent("View");
    setShowModal(true);
  };


  return (
    <React.Fragment>
      <Helmet defer={false}  title="Completed Tasks" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><CheckSquare className='me-2' />Completed Tasks ({resultCount})</h1>
          <div>
            <LogTask handleSuccess={() => childRef.current.updateTable(1)}/>
            {user?.scheduleTasks && <Button onClick={() => {
              setOld(null);
              setCurrent("Create");
              setShowModal(true);
            }}>Schedule a Task</Button>}
          </div>
        </div>
        <Card className="bg-white p-3">
          <CommonTable sort={[{id: 'completed', desc: true}]} duration={duration} initDate showRange durationParam={'completed'} cssClass="table-fixed" ref={childRef} setCountParent={setResultCount}
                       apiName="tasks/completed" columns={columns} />
        </Card>
      </Container>
      <ScheduleTaskModal completed handleSuccess={handleSuccess} closeModal={() => setShowModal(false)} show={showModal}
                         action={currentAction} current={current} apiName="tasks/schedule" />
    </React.Fragment>
  );
};
export default CompletedTask;