import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import SearchBox from "../maps/components/SearchBox";
import useAuth from "../../hooks/useAuth";
import { globalConfig } from "../../config";

let drawingManager = null;
const PauseMap = () => {
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const { user } = useAuth();

  const apiHasLoaded = (map, maps) => {
    if(!map || !maps) return
    window.mapApi = maps;
    window.mapInstance = map;
    setInstance(map);
    setApi(maps);
    let polyOptions = {
      strokeWeight: 0,
      fillOpacity: 0.45,
      editable: true,
      draggable: true,
    };
    drawingManager = new maps.drawing.DrawingManager({
      drawingModes: [
        maps.drawing.OverlayType.CIRCLE,
        maps.drawing.OverlayType.POLYGON,
        maps.drawing.OverlayType.RECTANGLE,
      ],
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          maps.drawing.OverlayType.CIRCLE,
          maps.drawing.OverlayType.POLYGON
        ],
      },
      polylineOptions: {
        editable: true,
        draggable: true
      },
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: map
    });
  };

  useEffect(() => {
    return() => {
      drawingManager &&drawingManager.setMap(null)
      drawingManager = null
    }
  }, [])

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
    };
  };

  const handleDelete = () => {
  }

  const onCreate = (lat, lng) => {

  }

  const onSave = () => {

  }

  const onCancel = () => {

  }


  const onDelete = () => {
  }


  return <React.Fragment>
    {user?.editZones && <div className="mt-2 mb-2 d-flex align-items-center">
      <span>Click on the map to add or move this area.</span>
      <Button onClick={() => onSave()} className="ms-1">Save</Button>
      <Button onClick={() => onCancel()} className="ms-2" variant="secondary">Cancel</Button>
      <Button onClick={() => onDelete()} className="ms-2" variant="danger">Delete</Button>
    </div>}
    <div style={{ height: 500, width: "100%" }}>
      <GoogleMapReact
        options={getMapOptions}
        onClick={(ev) => {
          ev.event.preventDefault();
          onCreate(ev.lat, ev.lng)
        }}
        bootstrapURLKeys={{
          key: globalConfig.googleMapKey,
          libraries: ["places", "geometry", "drawing", "visualization"]
        }}
        center={{
          lat: 50,
          lng: 10
        }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        {mapInstance && mapApi &&  <SearchBox map={mapInstance} mapApi={mapApi} />}
      </GoogleMapReact>
    </div>
    <Modal show={showDelete} onHide={() => setShowDelete(false)}>
      <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
      <Modal.Body>
        <p>Are you sure that you wish to permanently delete this Geofence?</p>
        <p>Deletions are not reversible.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
        <Button variant="secondary" onClick={() => setShowDelete(false)}>Cancel</Button>
      </Modal.Footer>

    </Modal>
  </React.Fragment>
}
export default PauseMap