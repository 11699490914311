import React, { useContext, useRef, useState } from "react";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import { Wifi } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";

const Device = ({ id, onGetList, type, data }) => {
  const [showModal, setShow] = useState(false);
  const [curReader, setCurrentReader] = useState(false);
  const [showLinkModal, setShowLink] = useState(false);
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const [showWarning, setShowWarning] = useState(false);
  const { user } = useAuth();

  const columns = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: cell => {
        if(user?.role === 'Root') {
          return <NavLink to={{pathname: '/readers/edit', search: `?id=${cell.row.original?.id}`}}>{cell.value}</NavLink>
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      action: [{ name: "Unlink Reader", color: "danger", disabled: (!user?.editZones || user?.role === "Root") }]
    }
  ];

  const columns1 = [
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      Cell: cell => (
        <Button
          variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
          onClick={() => onOperate(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
      )
    },
    {
      Header: "#Serial",
      accessor: "serial"
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];

  const btnClick = (name, id) => {
    setCurrentReader(id);
    setShow(true);
  };

  const onSave = () => {
    api.post(`readers/${curReader}/unlink/${id}`).then(() => {
      childRef.current.updateTable(1);
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setShow(false);
    });
  };

  const onOperate = (name, curId, data) => {
    if (name === "edit") {
      api.post(`readers/${curId}/link/${id}`).then(() => {
        childRef.current.updateTable(1);
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
      });
    } else if (name === "warn") {
      setShowWarning(data);
    } else if (name === "relink") {
      api.post(`readers/${curId?.id}/unlink/${curId?.assetId || curId?.personId || curId?.zoneId}`)
        .then(res => {
          api.post(`readers/${curId?.id}/link/${id}`, { params: { parentId: id, id: curId } })
            .then(res => {
              notify.open({
                type: "success",
                message: "Changes Saved"
              });
              childRef.current.updateTable(1);
              setShowWarning(null);
            });
        });
    }
    setShowLink(false);
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className=""><Wifi size={18} /> Readers</h4>
          {((type === "zones" && user?.editZones) || (type === "assets" && user?.editAssets) || (type === "people" && user?.editPeople)) && user?.role !== "Root" &&
          <Button onClick={() => {
            setShowLink(true);
          }}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Link a Reader</Button>}
        </div>
        <Card className="bg-white p-3">
          <CommonTable btnClick={btnClick} onGetList={onGetList} ref={childRef} apiName={`zones/readers/${id}`}
                       parentId={id} columns={columns} />
        </Card>
      </Container>

      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton><Modal.Title>Unlink a Reader</Modal.Title></Modal.Header>
        <Modal.Body>
          <span>Are you sure you want to unlink this device ?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => onSave()}>Unlink</Button>
          <Button variant="secondary" onClick={() => {
            setShow(false);
            setCurrentReader(null);
          }}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLinkModal} size={"lg"} onHide={() => setShowLink(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Link a Reader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTable btnClick={onOperate} apiName="readers" columns={columns1} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLink(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWarning} onHide={() => setShowWarning(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
            from {showWarning?.assetCompoundName || showWarning?.personCompoundName || showWarning?.zoneCompoundName}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
          <Button variant="secondary" onClick={() => setShowWarning(null)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default Device;