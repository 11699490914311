import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";

import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";

const Calendar = ({ data, tab, attendance, type }) => {

  const [result, setResult] = useState([]);
  const [current, setCurrent] = useState(null);
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const calendarRef = useRef();

  useEffect(() => {
    if (data && attendance) {
      getAttendance();
    }
  }, [data, attendance]);

  useEffect(() => {
    if (tab === "calendar" && !render) {
      setRender(true);
    }
  }, [tab]);

  const getAttendance = () => {
    let attendanceArr = [];
    if (attendance && attendance.length > 0) {
      attendanceArr = attendance.map(item => {
        return {
          title: item?.parentName,
          start: moment(item?.start).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(item?.end).format("YYYY-MM-DD HH:mm:ss"),
          display: "block",
          backgroundColor: "#4BBF73",
          classNames: "attendance",
          duration: item?.duration,
          detail: item
        };
      });
    }
    setResult(attendanceArr);
  };

  const handleDateClick = (arg) => {
    if (arg.event.classNames[0] === "attendance") {
      setCurrent(arg.event._def?.extendedProps?.detail);
    }
  };

  const getDuration = (time) => {
    if (time <= 0) return "Unknown";
    let currentTime = moment.duration(time, "seconds");
    if (currentTime.days() > 0) {
      let hours = currentTime.days() * 24 + currentTime.hours();
      let min = currentTime.minutes();
      return hours + ":" + min;
    }
    return moment({
      h: currentTime.hours(),
      m: currentTime.minutes(),
      s: currentTime.seconds()
    }).format("HH:mm");
  };

  const isSameDay = (start, end) => {
    return start.split("T")[0] === end.split("T")[0];
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <div className="d-flex justify-content-end align-items-center mb-2">
          <div className="d-flex align-items-center">
            <div className="p-1 bg-success me-1 ">{` `}</div>
            <div>Attendance</div>
          </div>
        </div>
        {render && <FullCalendar
          ref={calendarRef}
          plugins={[bootstrapPlugin, dayGridPlugin, timeGridPlugin]}
          themeSystem="bootstrap"
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: ""
          }}
          eventClick={handleDateClick}
          events={result}
          bootstrapFontAwesome={false}
        />}
      </Container>
      <Modal show={current} onHide={() => setCurrent(null)}>
        <Modal.Header closeButton><Modal.Title>Attendance</Modal.Title></Modal.Header>
        {current && <Modal.Body>
          <NavLink onClick={() => setCurrent(null)} className="link-primary" to={{
            pathname: `/${current.parentType === "asset" ? "assets" : "zones"}/edit`,
            search: `?id=${current.parentId}`
          }}>{current.parentName}</NavLink>
          <span
            className="ms-1">{isSameDay(current.start, current.end) ? moment(current.start).format("DD/MM/YY") : `${moment(current.start).format("DD/MM/YY")} - ${moment(current.end).format("DD/MM/YY")}`}</span>
          <div>
            <div>
              <FontAwesomeIcon icon={faSignInAlt} fixedWidth />
              <span className="ms-1">Arrived:</span>
              <span>&nbsp; {moment(current.start).format("HH:mm")}</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
              <span className="ms-1">Departed:</span>
              <span>&nbsp; {current.end.indexOf("0001-01-01") > -1 ? "Missed" : moment(current.end).format("HH:mm")}</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faClock} fixedWidth />
              <span className="ms-1">Duration:</span>
              <span>&nbsp; {getDuration(current.duration)}</span>
            </div>
          </div>
        </Modal.Body>}
        <Modal.Footer>
          <Button onClick={() => {
            navigate(`/report/attendance?id=${current?.childId}&name=${encodeURIComponent(current?.childName)}&type=${type}${current?.end.indexOf("0001-01-01") > -1 ? "" : `&end=${moment(current?.end).format("YYYY-MM-DD HH:mm")}`}`);
          }}>View History</Button>
          <Button variant={"secondary"} onClick={() => setCurrent(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Calendar;
