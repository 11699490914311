import React, { useEffect, useRef, useState } from "react";
import { Card, Col, ProgressBar } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import api from "../../api";

const BatteryExchanges = ({ name, width }) => {

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const isUmount = useRef(false);

  useEffect(() => {
    getData();
    return () => {
      isUmount.current = true;
    };
  }, []);

  const getData = () => {
    setLoading(true)
    let res = [{ label: "Solar", value: 300 }, { label: "Standard ", value: 200 }];
    let total = 0
    api.get(`reports/averagebatterylifecycle`).then(r => {
      res[0].value = r.data?.solar
      res[1].value = r.data?.standard
      total = Number(r.data.solar) > Number(r.data.standard) ? Number(r.data.solar) : Number(r.data.standard)
      setTotal(total)
      setResult(res)
      setLoading(false)
    })
  };

  const columns = [
    {
      Header: "Battery",
      accessor: "label",
      disableSortBy: true,
      Cell: cell => (
        <span to={{ pathname: '/assets'}}>{cell.value}</span>
      )
    },
    {
      Header: "Lifecycle",
      accessor: "value",
      width: "200px",
      Cell: cell => (
        <span>{cell.value} hours</span>
      ),
      disableSortBy: true
    },
    {
      Header: "",
      accessor: "id",
      className: 'w-35',
      disableSortBy: true,
      Cell: cell => (
        <ProgressBar now={cell.row.original.value / total * 100} />
      )
    }
  ];

  return <React.Fragment>
    <Col key={name} lg={width / 100 * 12} className="mb-4">
      <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
        <div className="m-3 d-flex justify-content-between align-items-center">
          <h4 style={{ color: "#E0E0E0" }}>Average Battery Lifecycle</h4>
        </div>
        <CommonTable data={result} singlePage showRange={false} filterId={'parentHashtag'} id={'customer'} type="dashboard" columns={columns} />
      </Card>
    </Col>
  </React.Fragment>;
};
export default BatteryExchanges;