import { Alert, Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../api";
import React, { useRef } from "react";
import CustomOptions from "../task/CustomOptions";
import AsyncSelect from "react-select/async";
import { colourStyles } from "../../utils/staticMethods";

const NewModal = ({ modalType, setModalType, modalTitle, rule, onSuccess, zoneName }) => {

  const formRef = useRef();


  const onDeleteClick = () => {
    api.delete(`rules/${rule.id}`)
      .then(() => {
        onSuccess && onSuccess("delete");
        setModalType(false);
      });
  };

  const zonePromiseOptions = (inputValue) =>
    new Promise((resolve) => {
      api.get("zones?page=1&limit=10&filter=" + inputValue).then(res => {
        let arr = res.data;
        if (inputValue === "") {
          arr.push({ id: "Any Zone", compoundName: "Any Zone" });
          rule?.zoneId && res.data.filter(item => item.id === rule.zoneId).length === 0 && arr.push({
            id: rule.zoneId,
            compoundName: zoneName
          });
        }
        resolve(formatResponse(arr, "zone"));
      });
    });

  const formatResponse = (list, type) => {
    let arr = list.map(item => {
      return { value: item.id, label: item.compoundName, reference: item.reference, icon: type };
    });
    return arr;
  };

  return <Modal show={modalType} onHide={() => setModalType(false)}>
    <Modal.Header closeButton className="text-black">
      <Modal.Title>{modalTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(modalTitle.indexOf("Edit") > -1 || !rule) ? <Formik
        innerRef={formRef}
        initialValues={{
          ruleName: rule?.ruleName || "",
          lowerThreshold: rule?.lowerThreshold || "",
          upperThreshold: rule?.upperThreshold || "",
          zoneId: rule?.zoneId || "Any Zone",
          zoneName: zoneName || "Any Zone"
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          ruleName: Yup.string()
            .max(255)
            .required("Rule Name is required"),
          lowerThreshold: Yup.number(),
          upperThreshold: Yup.number()
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let data = {
            ruleName: values.ruleName,
            type: modalType
          };
          if (modalType === "speeding") data.upperThreshold = values.upperThreshold;
          if (modalType === "lowbattery" || modalType === "lowvoltage") data.lowerThreshold = values.lowerThreshold;
          if (modalType === "exitzone" || modalType === "enterzone") data.zoneId = values.zoneId;
          try {
            if (rule) {
              let newRule = JSON.parse(JSON.stringify(rule));
              for (let i in data) {
                if (i === "zoneId" && data[i] === "Any Zone") {
                  newRule[i] = null;
                } else {
                  newRule[i] = data[i];
                }
              }
              api.put("rules", newRule)
                .then(res => {
                  onSuccess && onSuccess("edit", res.data);
                  setModalType(false);
                });
            } else {
              let obj = {};
              for (let i in data) {
                if (i === "zoneId" && data[i] === "Any Zone") {
                  obj[i] = null;
                } else {
                  obj[i] = data[i];
                }
              }
              api.post("rules", obj)
                .then(res => {
                  onSuccess && onSuccess("create", res.data);
                  setModalType(false);
                });
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Rule Name</Form.Label>
              <Form.Control
                type="text"
                className="no-background-input"
                name="ruleName"
                value={values.ruleName}
                isInvalid={Boolean(touched.ruleName && errors.ruleName)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.ruleName && (
                <Form.Control.Feedback type="invalid">
                  {errors.ruleName}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {modalType === "speeding" && <Form.Group className="mb-3">
              <Form.Label>High Threshold (mph)</Form.Label>
              <Form.Control
                type="text"
                className="no-background-input"
                name="upperThreshold"
                value={values.upperThreshold}
                isInvalid={Boolean(touched.upperThreshold && errors.upperThreshold)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.upperThreshold && (
                <Form.Control.Feedback type="invalid">
                  {errors.upperThreshold}
                </Form.Control.Feedback>
              )}
            </Form.Group>}
            {(modalType === "lowbattery" || modalType === "lowvoltage") && <Form.Group className="mb-3">
              <Form.Label>Low Threshold ({`${modalType === "lowbattery" ? "%" : "mV"}`})</Form.Label>
              <Form.Control
                type="text"
                className="no-background-input"
                name="lowerThreshold"
                value={values.lowerThreshold}
                isInvalid={Boolean(touched.lowerThreshold && errors.lowerThreshold)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.lowerThreshold && (
                <Form.Control.Feedback type="invalid">
                  {errors.lowerThreshold}
                </Form.Control.Feedback>
              )}
            </Form.Group>}
            {(modalType === "exitzone" || modalType === "enterzone") && <Form.Group className="mb-3">
              <Form.Label>Zone</Form.Label>
              <AsyncSelect components={{ Option: CustomOptions }} onChange={e => {
                setFieldValue("zoneId", e.value);
                setFieldValue("zoneName", e.label);
              }} defaultInputValue={values.zoneName} defaultValue={{ value: values.zoneId, label: values.zoneName }}
                           styles={colourStyles}
                           defaultOptions
                           loadOptions={zonePromiseOptions} />
              {!!touched.zoneId && (
                <Form.Control.Feedback type="invalid">
                  {errors.zoneId}
                </Form.Control.Feedback>
              )}
            </Form.Group>}
            <div className="mt-3 w-100 d-flex justify-content-end">
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                {rule ? "Save" : "Next"}
              </Button>
              <Button
                className="m-2"
                variant="secondary"
                onClick={() => setModalType(null)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik> : <div>
        <p>Are you sure that you wish to permanently delete this Rule?</p>
        <p>Deletions are not reversible.</p>
      </div>}
    </Modal.Body>
    {modalTitle.indexOf("Confirm") > -1 && rule && <Modal.Footer><Button
      type="submit"
      variant="danger"
      onClick={onDeleteClick}
    >
      Delete
    </Button>
      <Button
        className="m-2"
        variant="secondary"
        onClick={() => setModalType(null)}
      >
        Cancel
      </Button></Modal.Footer>}
  </Modal>;
};
export default NewModal;