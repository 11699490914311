import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../../components/tables/CommonTable";
import * as queryString from "query-string";
import useAuth from "../../hooks/useAuth";
import NewCustomer from "./NewCustomer";
import {
  Award,
  Disc,
  Globe,
  MapPin,
  Monitor,
  Moon,
  Package,
  Plus,
  Radio,
  Speaker,
  Sun,
  UserPlus,
  Users,
  Wifi
} from "react-feather";
import moment from "moment-timezone";
import { setOtherSession, setSession } from "../../utils/jwt";
import NewAdmin from "./NewAdmin";
import WidgetTabs from "./WidgetTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import RootTopBar from "../../components/navbar/RootTopBar";

const CustomerEdit = () => {

  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [data, setData] = useState();
  const [modalTitle, setModalTitle] = useState(null);
  const [showDeleteModal, setShowDelete] = useState(false);
  const notify = useContext(NotyfContext);
  const [showAdd, setShowAdd] = useState(null);
  const [assetsCount, setAssetsCount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(0);
  const [chargerCount, setChargerCount] = useState(0);
  const [zonesCount, setZonesCount] = useState(0);
  const [trackersCount, setTrackersCount] = useState(0);
  const [tagsCount, setTagsCount] = useState(0);
  const [readersCount, setReadersCount] = useState(0);
  const [curTab, setTab] = useState("details");
  const { user, getProfile } = useAuth();
  const [ admin, setAdmin ] = useState();
  const childRef = useRef();

  const columnsAsset = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: "/assets/edit",
      stateId: "id"
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf('0001-01-01') === -1 ) {
          return <><MapPin width={14} height={14} className="me-1" />{moment(cell.value).fromNow()}</>
        }
        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>
      },
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      type: "hashtag",
      disableSortBy: true
    }
  ];

  const columnsPeople = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: "/people/edit",
      stateId: "id"
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf('0001-01-01') === -1 ) {
          return <><MapPin width={14} height={14} className="me-1" />{moment(cell.value).fromNow()}</>
        }
        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>
      },
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      type: "hashtag",
      disableSortBy: true
    }
  ];

  const columnsDevice = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: (cell) => {
        if (user?.role === 'Root') {
          return <NavLink to={{ pathname: "/trackers/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];
  const columnsDevice1 = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: (cell) => {
        if (user?.role === 'Root') {
          return <NavLink to={{ pathname: "/tags/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];
  const columnsDevice2 = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: (cell) => {
        if (user?.role === 'Root') {
          return <NavLink to={{ pathname: "/readers/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];
  const columnsDevice3 = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: (cell) => {
        if (user?.role === 'Root') {
          return <NavLink to={{ pathname: "/solarchargers/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];
  const columnsZones = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      stateId: "id",
      link: "/zones/edit"
    },
    {
      Header: "Contents",
      accessor: "contentsCount",
      disableSortBy: true
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      type: "hashtag",
      disableSortBy: true
    }
  ];

  const columns = [{
    Header: "Name",
    accessor: "compoundName",
    Cell: (cell) => {
      return <span>
        {cell.row.original?.role === 'Admin' && <><Award size={18} /><span className="ms-1 text-primary cursor-pointer" onClick={() => {setAdmin(cell.row.original); setShowAdd('edit')}}>{cell.value}</span></>}
        {cell.row.original?.role === 'User' && <><Monitor size={18} /><span className="ms-1" >{cell.value}</span></>}
      </span>
    }
  },
    {
      Header: "Email",
      accessor: "email",
      disableSortBy: true
    },
    {
      Header: "Login",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => {
        return <span onClick={() => onOperate(cell.row.original.id)} className="text-primary cursor-pointer">Login</span>
      },
      show: curTab === 'admins'
    },{
      Header: "Registered",
      accessor: "registered",
      disableSortBy: true,
      Cell: (cell) => {
        return <span>{cell.value ? 'Registered' : 'Not Registered'}</span>
      }
    },{
      Header: "Last Login",
      accessor: "lastLogin",
      disableSortBy: true,
      Cell: (cell)=> {
        return <span>{cell.value.indexOf('0001-01-01') > -1 ? 'Never' : moment(cell.value).fromNow()}</span>
      }
    },]

  const onOperate = (id) => {
    api.post(`accounts/customerlogin`, {id}).then(res => {
      let token = res.data?.accessToken;
      localStorage.removeItem('recentItem')
      let token1 = localStorage.getItem('accessToken')
      setOtherSession(token1);
      setSession(token);
      getProfile()
    })
  }


  useEffect(() => {
    getDetail();
  }, [id]);

  const getDetail = () => {
    api.get(`customers/${id}`).then(res => {
      setData(res.data);
    });
  };

  const onDelete = () => {
    api.delete(`customers/${data.id}`)
      .then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
        setModalTitle(null);
        window.history.back();
      });
  };

  const onUpdated = (value) => {
    setData(value)
  }

  const onUpdatedAdmin = () => {
    childRef.current.updateTable(1);
  }

  const onDeleteAdmin = () => {
    setShowAdd(null)
    setShowDelete(true)
  }

  const deleteAccount = () => {
    api.delete(`accounts/admin/${admin?.id}`).then(() => {
      onUpdatedAdmin();
      setShowDelete(false);
    })
  }

  useEffect(() => {
    if(curTab === 'admins') {
      childRef.current.resizeTable();
    } else {
      childRef.current.resetTable();
    }
  }, [curTab])

  return (
    <React.Fragment>
      <Helmet defer={false}  title={data?.name || 'Customer'} />
      <div className="mb-3 d-flex">
        <h3 className="mb-0">{data?.name}</h3>
        <RootTopBar homePage data={{...data, customerId: id, customerName: data?.name}} />
      </div>
      <div className="tab mt-3">
        <Tab.Container id="left-tabs-example" defaultActiveKey={curTab} activeKey={curTab} onSelect={(e) => setTab(e)}>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="details">Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="admins">Administrators</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="users">Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="widgets">Home Tiles</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="assetsTiles">Asset Tiles</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="assets">Assets</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="people">People</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="zones">Zones</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="trackers">Trackers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tags">Tags</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="readers">Readers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="solarchargers">Solar Chargers</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="details">
              <h4>
                <FontAwesomeIcon icon={faFileAlt} className='me-2'></FontAwesomeIcon> Details
              </h4>
              <NewCustomer apiName="customers" type={'edit'} customer={data} onDelete={() =>setModalTitle('Delete Customer')} onUpdated={(v) =>onUpdated(v)}/>
            </Tab.Pane>
            <Tab.Pane eventKey="widgets">
              <h4><Speaker size={18} className="me-2" />Tiles</h4>
              <WidgetTabs id={id} onUpdate={() =>getDetail()} data={data?.widgets} />
            </Tab.Pane>
            <Tab.Pane eventKey="admins">
              <Row className="mb-3 d-flex justify-content-between align-items-center">
                <Col className="d-flex align-items-center"><h4 className="mb-0"><UserPlus size={18} className={'me-2'} />Administrators</h4></Col>
                <Col className="d-flex justify-content-end"><Button onClick={() => {setAdmin(null);setShowAdd('create')}} className="d-flex align-items-center"><Plus size={16}/>Create New</Button></Col>
              </Row>
              <CommonTable ref={childRef} columns={columns} apiName={`accounts/administrators/${id}`} />
            </Tab.Pane>
            <Tab.Pane eventKey="users">
              <Row className="mb-3 d-flex justify-content-between align-items-center">
                <Col className="d-flex align-items-center"><h4 className="mb-0"><Users size={18} className={'me-2'} />Users</h4></Col>
              </Row>
              <CommonTable columns={columns} apiName={`accounts/users/${id}`} />
            </Tab.Pane>
            <Tab.Pane eventKey="assets">
              <div className="mb-3 d-flex align-items-center">
                <h4><Package size={18} className="me-2" />Assets ({assetsCount})</h4>
              </div>
              <CommonTable apiName={`customers/assets/${id}`} setCountParent={setAssetsCount} columns={columnsAsset}/>
            </Tab.Pane>
            <Tab.Pane eventKey="people">
              <div className="mb-3 d-flex align-items-center">
                <h4><Users size={18} className='me-2' />People ({peopleCount})</h4>
              </div>
              <CommonTable apiName={`customers/people/${id}`} setCountParent={setPeopleCount} columns={columnsPeople}/>
            </Tab.Pane>
            <Tab.Pane eventKey="zones">
              <div className="mb-3 d-flex align-items-center">
                <h4><Disc size={18} className='me-2' />Zones ({zonesCount})</h4>
              </div>
              <CommonTable apiName={`customers/zones/${id}`} setCountParent={setZonesCount} columns={columnsZones}/>
            </Tab.Pane>
            <Tab.Pane eventKey="trackers">
              <div className="mb-3 d-flex align-items-center">
                <h4><Globe size={18} className={'me-2'} />Trackers ({trackersCount})</h4>
              </div>
              <CommonTable apiName={`customers/trackers/${id}`} setCountParent={setTrackersCount} columns={columnsDevice}/>
            </Tab.Pane>
            <Tab.Pane eventKey="tags">
              <div className="mb-3 d-flex align-items-center">
                <h4><Radio size={18} className='me-2' />Tags ({tagsCount})</h4>
              </div>
              <CommonTable apiName={`customers/tags/${id}`} setCountParent={setTagsCount} columns={columnsDevice1}/>
            </Tab.Pane>
            <Tab.Pane eventKey="readers">
              <div className="mb-3 d-flex align-items-center">
                <h4><Wifi size={18} className='me-2' />Readers ({readersCount})</h4>
              </div>
              <CommonTable apiName={`customers/readers/${id}`} setCountParent={setReadersCount} columns={columnsDevice2}/>
            </Tab.Pane>
            <Tab.Pane eventKey="solarchargers">
              <div className="mb-3 d-flex align-items-center">
                <h4><Sun size={18} className='me-2' />Solar Chargers ({chargerCount})</h4>
              </div>
              <CommonTable apiName={`customers/solarchargers/${id}`} setCountParent={setChargerCount} columns={columnsDevice3}/>
            </Tab.Pane>
            <Tab.Pane eventKey="assetsTiles">
              <WidgetTabs id={id} onUpdate={() =>getDetail()} apiName={'customers/assetwidgets'} data={data?.assetWidgets} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <Modal show={modalTitle} onHide={() => setModalTitle(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
              <p>Are you sure that you wish to permanently delete this Customer?</p>
              <p>Deletions are not reversible.</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onDelete()} variant="danger">Delete</Button>
          <Button variant={'secondary'} onClick={() => setModalTitle(null)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAdd} onHide={() => setShowAdd(null)}>
        <Modal.Header closeButton><Modal.Title>{showAdd === 'create' ? `New Administrator` : 'Edit Administrator'}</Modal.Title></Modal.Header>
        <Modal.Body>
          <NewAdmin onDelete={onDeleteAdmin} onUpdated={onUpdatedAdmin} data={admin} id={id} type={showAdd} onCancelEdit={()=>setShowAdd(null)}/>
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to delete this account?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => deleteAccount()} variant="danger">Delete</Button>
          <Button variant={'secondary'} onClick={() => setShowDelete(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerEdit;