import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const NewCustomer = ({apiName, type='create', onDelete, customer, onUpdated}) => {

  const navigate = useNavigate();
  const notify = useContext(NotyfContext);

  return (
  <Card>
    <Card.Body>
      <Formik
        enableReinitialize
        initialValues={{
          globalCrud: customer ? customer.globalCrud : true,
          showTasks: customer ? customer.showTasks : true,
          showQrCodes: customer ? customer.showQrCodes : true,
          showFloorPlans: customer ? customer.showFloorPlans : true,
          showMap: customer ? customer.showMap : true,
          showEmailAlerts: customer ? customer.showEmailAlerts : true,
          showSmsAlerts: customer ? customer.showSmsAlerts : true,
          showTeamsAlerts: customer ? customer.showTeamsAlerts : true,
          showAttendanceRule: customer ? customer.showAttendanceRule : true,
          showZoneEntryRule: customer ? customer.showZoneEntryRule : true,
          defaultFollow: customer ? customer.defaultFollow : true,
          defaultShowOthers: customer ? customer.defaultShowOthers : true,
          showZoneExitRule: customer ? customer.showZoneExitRule : true,
          showLowBatteryRule: customer ? customer.showLowBatteryRule : true,
          showLowVoltageRule: customer ? customer.showLowVoltageRule : true,
          showMotionRule: customer ? customer.showMotionRule : true,
          showSpeedingRule: customer ? customer.showSpeedingRule : true,
          showSolarMonitoring: customer ? customer.showSolarMonitoring : true,
          showPermissionAssetTiles: customer ? customer.showPermissionAssetTiles : true,
          showPermissionTiles: customer ? customer.showPermissionTiles : true,
          name: customer?.name || '',
          reference: customer?.reference || '',
          id: customer?.id || null,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required("Customer Name is required"),
          reference: Yup.string().max(255),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if(type === 'create') {
              api.post(apiName, values)
                .then(res => {
                  navigate(`/${apiName}/edit?id=${res.data.id}`);
                })
            } else {
              api.put(apiName, values)
                .then(res => {
                  onUpdated && onUpdated(res.data)
                  notify.open({
                    type: "success",
                    message: "Changes Saved"
                  });
                })
            }

          } catch (error) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                size="md"
                type="text"
                name="name"
                value={values.name}
                isInvalid={Boolean(touched.name && errors.name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Reference</Form.Label>
              <Form.Control
                size="md"
                type="text"
                name="reference"
                value={values.reference}
                isInvalid={Boolean(touched.reference && errors.reference)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.reference && (
                <Form.Control.Feedback type="invalid">
                  {errors.reference}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <h5>General:</h5>
            <Row>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="globalCrud"
                  name="globalCrud"
                  label="User based editing rights"
                  checked={values.globalCrud}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showTasks"
                  name="showTasks"
                  label="Show Tasks"
                  checked={values.showTasks}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showQrCodes"
                  name="showQrCodes"
                  label="Show QrCodes"
                  checked={values.showQrCodes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showSolarMonitoring"
                  name="showSolarMonitoring"
                  label="Show Solar Monitoring"
                  checked={values.showSolarMonitoring}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showFloorPlans"
                  name="showFloorPlans"
                  label="Show FloorPlans"
                  checked={values.showFloorPlans}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showMap"
                  name="showMap"
                  label="Show Map"
                  checked={values.showMap}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="defaultFollow"
                  name="defaultFollow"
                  label="Map Default Following"
                  checked={values.defaultFollow}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="defaultShowOthers"
                  name="defaultShowOthers"
                  label="Map Default Show Others"
                  checked={values.defaultShowOthers}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <h5 className="mt-2">Contact Groups</h5>
            <Row>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showSmsAlerts"
                  name="showSmsAlerts"
                  label="Show Sms Alerts in Contact Groups"
                  checked={values.showSmsAlerts}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showEmailAlerts"
                  name="showEmailAlerts"
                  label="Show Email in Contact Groups"
                  checked={values.showEmailAlerts}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showTeamsAlerts"
                  name="showTeamsAlerts"
                  label="Show Teams Alerts in Contact Groups"
                  checked={values.showTeamsAlerts}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <h5 className="mt-2">Rules:</h5>
            <Row>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showAttendanceRule"
                  name="showAttendanceRule"
                  label="Show Attendance Rule"
                  checked={values.showAttendanceRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showZoneEntryRule"
                  name="showZoneEntryRule"
                  label="Show Zone Entry Rule"
                  checked={values.showZoneEntryRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showZoneExitRule"
                  name="showZoneExitRule"
                  label="Show Zone Exit Rule"
                  checked={values.showZoneExitRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showLowBatteryRule"
                  name="showLowBatteryRule"
                  label="Show Low Battery Rule"
                  checked={values.showLowBatteryRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showLowVoltageRule"
                  name="showLowVoltageRule"
                  label="Show Low Voltage Rule"
                  checked={values.showLowVoltageRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showMotionRule"
                  name="showMotionRule"
                  label="Show Motion Rule"
                  checked={values.showMotionRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showSpeedingRule"
                  name="showSpeedingRule"
                  label="Show Speeding Rule"
                  checked={values.showSpeedingRule}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <h5 className="mt-2">Access Profiles:</h5>
            <Row>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showPermissionTiles"
                  name="showPermissionTiles"
                  label="Show Home Tiles"
                  checked={values.showPermissionTiles}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={4}>
                <Form.Check
                  type="switch"
                  id="showPermissionAssetTiles"
                  name="showPermissionAssetTiles"
                  label="Show Asset Tiles"
                  checked={values.showPermissionAssetTiles}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            {type === 'create' && <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                size="md"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </div>}
            {type === 'edit' && <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                size="md"
                disabled={isSubmitting}
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="ms-1"
                size="md"
                onClick={() => {onDelete && onDelete()}}
              >
                Delete
              </Button>
            </div>}
          </Form>
        )}
      </Formik>
    </Card.Body>
  </Card>
  );
}

export default NewCustomer;