import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircleSolid, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { AlertCircle, BatteryCharging, Clock, LogIn, LogOut, Move, PauseCircle, TrendingDown } from "react-feather";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import defaultImg from "../assets/img/icon/Aircraft/Airliner_Black.png";

// return table expanded icon
export const faMinusIcon = () => {
  return <span className="fa-layers fa-fw fa-lg me-1 align-middle">
    <FontAwesomeIcon icon={faCircle} color="white" />
  <FontAwesomeIcon icon={faCircleSolid} color="#D9534F" transform="shrink-3" />
  <FontAwesomeIcon icon={faMinus} transform="shrink-6" inverse />
</span>;
};

// calculate middle point with p1 and p2
export const middle = (P1, P2) => {
  let lat = (P1.lat() + P2.lat()) / 2;
  let lng = P1.lng() - P2.lng();
  if (lng <= 180 && lng >= -180) {
    lng = (P1.lng() + P2.lng()) / 2;
  } else {
    lng = (P1.lng() + P2.lng() + 360) / 2;
  }
  return { lat, lng };
};

// get display name with type name
export const getName = (name) => {
  if (name=== 'assets') return 'Asset'
  if (name=== 'people') return 'Person'
  if (name=== 'zones') return 'Zone'
  if (name=== 'readers') return 'Reader'
  if (name=== 'tackers') return 'Tracker'
  if (name=== 'tags') return 'Tag'
}

// It is for recentItem widget, these data will save to localstorage
export const filterStorage = (obj) => {
  let storage = JSON.parse(localStorage.getItem('recentItem')) || []
  let index = -1
  for(let i = 0; i < storage.length; i++) {
    if(storage[i].id === obj.id) {
      index = i
      break
    }
  }
  if(index > -1) {
    storage.splice(index, 1)
    storage.unshift(obj)
  } else {
    storage.unshift(obj)
  }

  if(storage.length > 5) {
    storage = storage.slice(0, 5)
  }
  localStorage.setItem('recentItem', JSON.stringify(storage))

}

// return table expanded icon
export const faPlusIcon = () => {
  return <span className="fa-layers fa-fw fa-lg me-1 align-middle">
      <FontAwesomeIcon icon={faCircle} color="white" />
  <FontAwesomeIcon icon={faCircleSolid} color="#3F80EA" transform="shrink-3" />
  <FontAwesomeIcon icon={faPlus} transform="shrink-6" inverse />
</span>;
};

// return if table expanded
export const returnTableExpand = (row, cell) => {
  return row?.allCells[0]?.column?.Header === cell.column.Header ? <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? faMinusIcon() : faPlusIcon()}
          </span> : null
}

// calculate if the cell is expanded
export const needShowColumn = (cell, expand) => {
  let arr = expand.filter(item => item?.Header === cell?.Header)
  return arr.length === 0
}


export const downloadFile = (response) => {
  let date = new Date().toLocaleString()
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${date}.csv`); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}

// get image resource in frontend
export const getUrl = (url) => {
  if(!url) return '';
  let fileName = url.split(".")[0];
  let formatName = fileName.replaceAll("-", "_");
  if (formatName.indexOf("1") === 0) {
    formatName = formatName.replace("1", "a_1");
  } else if (formatName.indexOf("0") === 0) {
    formatName = formatName.replace("0", "o_0");
  } else if (formatName.indexOf("3") === 0) {
    formatName = formatName.replace("3", "c_3");
  }
  if (formatName === "package") {
    formatName = "package_0";
  }
  return formatName;
};
// update marker
export const updateMarker = (arr, markerArr, infoItem) => {
  arr.forEach(item => {
    let m = markerArr.filter(item1 => (item1.id === item.id));
    if (m.length > 0 && m[0].idsArr.length <= 1) {
      m[0].setPosition({ lat: Number(item.latitude), lng: Number(item.longitude) });
      if(infoItem) {
        let filter = infoItem.filter(info => info.lastPosition.id === m[0].id)
        if(filter.length > 0) {
          filter[0].lastPosition.longitude = Number(item.longitude)
          filter[0].lastPosition.latitude = Number(item.latitude)
        }
      }
      m[0].setIcon({...m[0].icon ,url: imageSet[getUrl(item.icon)] ? require("../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg});
    }
  });

};

export const getRes = (num, digit = 3) => {
  if (Number(num) === Math.floor(num)) return Math.floor(num);
  let res = [], addNum = 0;
  num += '';
  let [zs, xs] = num.split('.');
  let [, symbol, zsNum] = /^(\-?)(\d+\.\d+|\d+)$/.exec(zs);
  for (let i = 0; i < xs.length; i++) {
    const ele = xs[i];
    if (ele !== 0) {
      if (xs[i + digit] && xs[i + digit] > 4) {
        addNum = 1;
        for (let j = i + (digit - 1); j >= 0; j--) {
          if (Number(xs[j]) + addNum === 10) res[j] = '0';
          else {
            res[j] = Number(xs[j]) + addNum;
            addNum = 0;
          }
        }
      }
      else {
        for (let k = i; k <= i + (digit - 1); k++) {
          res[k] = xs[k];
        }
      }
      break;
    } else res[i] = xs[i];
  }
  return Number(symbol + (Number(zsNum) + addNum) + '.' + res.join(''));
}

export const rad = (x) => {
  return x * Math.PI / 180;
};

export const getDistance = (p1, p2) => {
  let R = 6378137; // Earth’s mean radius in meter
  let dLat = rad(p2.lat() - p1.lat());
  let dLong = rad(p2.lng() - p1.lng());
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d; // returns the distance in meter
};

// everytime get response from nearby endpoints, calculate array of markers need to add, and array of markers need to remove.
export const resArr = (arr1, arr2) => {
  let returnArr1 = []
  // remove
  if(arr1.length > 0 && arr1[0].idsArr) {
    let arr = []
    arr1.forEach(item => {
      arr = item.ids.concat(arr)
    })
    returnArr1 = arr.filter(item => arr2.every(val => {
      return item.id !== val.id
    }))
    let movedMarker = []
    for(let i = 0; i < arr1.length; i++) {
      for(let j = 0; j < arr2.length; j++) {
        if(arr1[i].position && arr1[i].position.lat().toFixed(5) === Number(arr2[j].latitude).toFixed(5) && arr1[i].position.lng().toFixed(5) === Number(arr2[j].longitude).toFixed(5) && arr1[i].type !== 'zones' && arr2[j].type !== 'zone' && !arr1[i].idsArr.includes(arr2[j].id)) {
          movedMarker.push(arr2[j].id)
        }
      }
    }
    let markerNeedCombine = arr1.filter(item => {
      return movedMarker.includes(item.id) && item.idsArr.length <= 1
    })

    let returnArr2 = arr2.filter(v => {
      for(let i = 0; i< arr1.length; i++) {
        if(arr1[i].idsArr.length > 1 && arr1[i].idsArr.includes(v.id) && (Number(v.latitude).toFixed(5) !== arr1[i].position.lat().toFixed(5) || Number(v.longitude).toFixed(5) !== arr1[i].position.lng().toFixed(5))) {
          return true
        }
      }
      return false
    })
    return returnArr1.concat(returnArr2).concat(markerNeedCombine)
  } else {
    return arr1.filter((v) => arr2.every((val) => {
      if (val.idsArr && val.idsArr.length > 0) {
        // add
        if(val.idsArr.length > 1 && val.idsArr.includes(v.id) && (Number(v.latitude).toFixed(5) !== val.position.lat().toFixed(5) || Number(v.longitude).toFixed(5) !== val.position.lng().toFixed(5))) {
          return true
        }
        return !val.idsArr.includes(v.id);
      }
      return false
    }))
  }
};


// rule icon
export const getIcon = (icon) => {
  if (icon === "attendance") {
    return <Clock size={18} />;
  } else if (icon === "exitzone") {
    return <LogOut size={18} />;
  } else if (icon === "enterzone") {
    return <LogIn size={18} />;
  } else if (icon === "lowbattery") {
    return <BatteryCharging size={18} />;
  } else if (icon === "lowvoltage") {
    return <TrendingDown size={18} />;
  } else if (icon === "motion") {
    return <Move size={18} />;
  } else if (icon === "speeding") {
    return <AlertCircle size={18} />;
  } else if (icon === "pausetracking") {
    return <PauseCircle size={18} />;
  }
  return null;
};

// styles for selector component
export const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#7f838e",
    backgroundColor: "none",
    "&:hover": {
      borderColor: "#7f838e"
    },
    color: "#BFC1C6"
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#BFC1C6"
  }),
  indicatorSeparator: () => ({
    backgroundColor: "#7f838e"
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#eaeaec",
    backgroundColor: (state.isSelected) ? "#3F80EA" : (state.isFocused) ? "#343a4b" : "#293042",
    paddingLeft: "0.75rem",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    borderBottom: "1px solid rgba(255, 255, 255, 0.125)"
  }),
  group: () => ({
    margin: 0
  }),
  groupHeading: () => ({
    color: "#A9ACB3",
    paddingLeft: "0.5rem",
    background: "#343a4b",
    borderBottom: "1px solid rgba(255, 255, 255, 0.125)"
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#293042",
    border: "1px solid #7f838e"
  })
};

// visible radius for each zoom level
export const radiusZoomLevel = {
  23: 7,
  22: 13,
  21: 25,
  20: 50,
  19: 100,
  18: 200,
  17: 400,
  16: 800,
  15: 1500,
  14: 3000,
  13: 6000,
  12: 12000,
  11: 23000,
  10: 45000,
  9: 90000,
  8: 180000,
  7: 360000,
  6: 720000,
  5: 1440000,
  4: 2880000,
  3: 5760000,
  2: 12000000,
  1: 24000000,
  0: 48000000
};




