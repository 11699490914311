import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CommonTable from "../../components/tables/CommonTable";
import { Monitor } from "react-feather";

const columns = [
  {
    Header: "Profile Name",
    accessor: "permissionName",
    type: 'link',
    link: '/accessprofiles/edit',
    stateId: "id"
  }, {
    Header: 'Users',
    accessor: 'userCount',
    disableSortBy: true
  }, {
    Header: 'Assets',
    accessor: 'assetCount',
    disableSortBy: true,
    Cell: cell => (
      <span>
        {cell.row.original.allAssets ? 'All' : cell.value}
      </span>
    )
  }, {
    Header: 'People',
    accessor: 'personCount',
    disableSortBy: true,
    Cell: cell => (
      <span>
        {cell.row.original.allPeople ? 'All' : cell.value}
      </span>
    )
  }, {
    Header: 'Zones',
    accessor: 'zoneCount',
    disableSortBy: true,
    Cell: cell => (
      <span>
        {cell.row.original.allZones ? 'All' : cell.value}
      </span>
    )
  }
];


function AccessProfiles() {

  const [resultCount, setresultCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const notify = useContext(NotyfContext);
  const navigate = useNavigate();

  const onSave = () => {
    api.post(`accessprofiles`, {permissionName: name}).then(res => {
      notify.open({
        type: 'success',
        message: 'Changes Saved'
      })
      setShowModal(false);
      navigate(`/accessprofiles/edit?id=${res.data.id}`);
    });
  }

  return (
    <React.Fragment>
      <Helmet defer={false}  title="Access Profiles" />
      <Container fluid className="p-0">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <h1 className="h3"><Monitor className={'me-2'} />Access Profiles ({resultCount})</h1>
          <Button onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>
        </div>
        <Card className="bg-white p-3">
          <CommonTable  setCountParent={setresultCount} apiName="accessprofiles"  columns={columns} />
        </Card>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Access Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Profile Name</div>
          <Form.Control value={name} onChange={e => setName(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
           <Button variant="primary" onClick={() => onSave()}>Save</Button>
          <Button variant={'secondary'}  onClick={() => setShowModal(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default AccessProfiles;
