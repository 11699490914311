import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { Alert, Button, Card, Form } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import moment from "moment-timezone";
import useAuth from "../../hooks/useAuth";
import axios from "axios";

let data = {};
let source1 = null;

const AdminDetail = (props) => {

  const notify = useContext(NotyfContext);
  const [permissions, setPermissions] = useState([]);
  const { user } = useAuth();
  let sendType = null;

  useEffect(() => {
    if (props.data.id) {
      accessProfile();
    }
  }, [props.data?.id]);

  useEffect(() => {
    return () => {
      if (source1) {
        source1.cancel("request canceled");
      }
    };
  }, []);

  const accessProfile = () => {
    if (source1) {
      source1.cancel("request canceled");
    }
    source1 = axios.CancelToken.source();
    api.get(`accessprofiles?page=1&limit=999`, { cancelToken: source1.token}).then(res => setPermissions(res.data));
  };

  const saveAndSend = () => {
    api.put(`accounts/user`, data)
      .then(res => {
        props.setPerson(res.data);
        notify.open({
          type: "success",
          message: "Email has been sent !"
        });
      });
  };
  const justSave = () => {
    api.put(`accounts/user`, data).then(res => {
      props.setPerson(res.data);
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
    })
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <h4>
            <FontAwesomeIcon icon={faCog}></FontAwesomeIcon> System Access
          </h4>
          <Formik
            enableReinitialize
            initialValues={{
              email: props.data.email || '',
              create: props.data.create || false,
              edit: props.data.edit || false,
              delete: props.data.delete || false,
              permissionId: props.data.permissionId || '',
              submit: false
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email("Invalid email")
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              data = JSON.parse(JSON.stringify(props.data));
              data.id = props.data.id;
              data.email = values.email;
              data.create = values.create;
              data.edit = values.edit;
              data.delete = values.delete;
              data.permissionId = values.permissionId || null;
              if (sendType === 1) {
                data.sendemail = true
                saveAndSend()
                return;
              } else {
                try {
                  data.sendemail = false
                  justSave()
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Label><span>Access Profile -</span><NavLink to={{pathname : props.data?.permissionId ? `/accessprofiles/edit`: '/accessprofiles', search: props.data?.permissionId ? `?id=${props.data?.permissionId}`: ''}}> view
                    profile</NavLink></Form.Label>
                  <Form.Select
                    name="permissionId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.permissionId}
                    size="lg"
                  >
                    <option value={''} >No Access</option>
                    {permissions.map(item => <option key={item.id} value={item.id}>{item.permissionName}</option>)}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="email"
                    value={values.email}
                    isInvalid={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {user?.globalCrud && <>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="create"
                      label="Create"
                      checked={values.create}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="edit"
                      label="Edit"
                      checked={values.edit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="delete"
                      label="Delete"
                      checked={values.delete}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </>}
                <div className="mt-4 font-weight-bold text-dark">
                  Registration Status: {props.data.registered ? "Registered" : "Not Registered"}
                </div>
                <div className="mt-2">
                  Last
                  Login: {props.data.lastLogin && props.data.lastLogin.indexOf("0001-01-01") > -1 ? "Never" : moment(props.data.lastLogin).format("DD/MM/YY HH:mm")}
                </div>
                <div className="mt-3">
                  {!props.data.registered && <Button className="me-2" disabled={isSubmitting} type="submit" onClick={() => sendType = 1}>{'Save and send registration email'}</Button>}
                  <Button disabled={isSubmitting} type="submit" onClick={() => sendType = 2} variant="success">Save</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default AdminDetail;