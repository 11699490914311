import React, { useEffect, useState } from "react";
import { globalConfig } from "../config";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmog, faTemperatureLow, faWind } from "@fortawesome/free-solid-svg-icons";

const WeatherBar = ({ data }) => {
  const [weather, setWeather] = useState(null);
  useEffect(() => {
    fetch(`https://api.weatherstack.com/current?access_key=${globalConfig.weatherApiKey}&query=${data?.latitude},${data?.longitude}`)
      .then((data) => {
        return data.json();
      })
      .then((ret) => {
        setWeather(ret);
      });
  }, [data.id]);

  return <React.Fragment>
    {weather && <Card className="mb-0 border-top">
      <Card.Body>
        <ul className="list-unstyled col-sm-12">
          <li data-toggle="tooltip" title="" data-placement="right">
            <h6>LIVE WEATHER</h6>
          </li>
          <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faTemperatureLow} fixedWidth className="me-2"  />
            Temperature: {weather.current.temperature} °C
          </li>
          <li data-toggle="tooltip" title="" data-placement="right" className="d-flex align-items-center">
            <img alt='weather_icon' style={{width: '16px'}} className="me-2" src={weather.current.weather_icons[0]} />
            Weather: {weather.current.weather_descriptions.join(',')}
          </li>
          <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faWind} fixedWidth className="me-2"  />
            Wind: {Math.round(weather.current.wind_speed * 0.6214)} mph
          </li>
          <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faSmog} fixedWidth className="me-2"  />
            Precip: {weather.current.precip} mm
          </li>
        </ul>
      </Card.Body>
    </Card>}
  </React.Fragment>;
};
export default WeatherBar;