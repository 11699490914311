import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import moment from "moment-timezone";

const BatteryVoltagePage = () => {

  const location = useLocation();
  const { id, name, date } = queryString.parse(location.search);

  const getDate = (value) => {
    let startDate;
    let endDate;
    if (value === "twoWeek") {
      startDate = moment().subtract(14, "day").startOf("days");
      endDate = moment().endOf("days");
    } else if (value === "month") {
      startDate = moment().subtract(30, "day").startOf("days");
      endDate = moment().endOf("days");
    } else {
      startDate = moment().subtract(7, "day").startOf("days");
      endDate = moment().endOf("days");
    }
    return [startDate, endDate]
  }

  const duration = getDate(date)

  const columns = [
    {
      Header: "Date/Time",
      accessor: "dateTime",
      type: "date",
      dateFormat: "DD/MM/YYYY HH:mm:ss"
    },
    {
      Header: "Supply",
      accessor: "supply",
      Cell: cell => (
        <span>{cell.value ? cell.value + " Mv" : "0"}</span>
      )
    },
  ];

  return  <React.Fragment>
    <Helmet defer={false}  title={`Battery Voltage`} />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="mb-3">Battery Voltage - <NavLink to={{ pathname: "/assets/edit", search: `?id=${id}` }}>{name}</NavLink>
        </h3>
      </div>
      <Card className="bg-white p-3">
        <CommonTable durationSubstract={duration} showRange delayLoading
                     durationParam="dateTime" apiName={`assets/supply/${id}`} columns={columns}/>
      </Card>
    </Container>
  </React.Fragment>
}
export default BatteryVoltagePage