// AirCraft
const Airliner_Black = "Aircraft/Airliner_Black.png";
const Airliner_Blue = "Aircraft/Airliner_Blue.png";
const Airliner_Green = "Aircraft/Airliner_Green.png";
const Airliner_Grey = "Aircraft/Airliner_Grey.png";
const Airliner_Red = "Aircraft/Airliner_Red.png";
const Airliner_Yellow = "Aircraft/Airliner_Yellow.png";
const Airplane_Black = "Aircraft/Airplane_Black.png";
const Airplane_Blue = "Aircraft/Airplane_Blue.png";
const Airplane_Green = "Aircraft/Airplane_Green.png";
const Airplane_Grey = "Aircraft/Airplane_Grey.png";
const Airplane_Red = "Aircraft/Airplane_Red.png";
const Airplane_Yellow = "Aircraft/Airplane_Yellow.png";
const Helicopter_Black = "Aircraft/Helicopter_Black.png";
const Helicopter_Blue = "Aircraft/Helicopter_Blue.png";
const Helicopter_Green = "Aircraft/Helicopter_Green.png";
const Helicopter_Grey = "Aircraft/Helicopter_Grey.png";
const Helicopter_Red = "Aircraft/Helicopter_Red.png";
const Helicopter_Yellow = "Aircraft/Helicopter_Yellow.png";

// Bikes

const Bicycle_Black = "Bikes/Bicycle_Black.png";
const Bicycle_Blue = "Bikes/Bicycle_Blue.png";
const Bicycle_Green = "Bikes/Bicycle_Green.png";
const Bicycle_Grey = "Bikes/Bicycle_Grey.png";
const Bicycle_Red = "Bikes/Bicycle_Red.png";
const Bicycle_Yellow = "Bikes/Bicycle_Yellow.png";
const CruiserMotorcycle_Black = "Bikes/CruiserMotorcycle_Black.png";
const CruiserMotorcycle_Blue = "Bikes/CruiserMotorcycle_Blue.png";
const CruiserMotorcycle_Green = "Bikes/CruiserMotorcycle_Green.png";
const CruiserMotorcycle_Grey = "Bikes/CruiserMotorcycle_Grey.png";
const CruiserMotorcycle_Red = "Bikes/CruiserMotorcycle_Red.png";
const CruiserMotorcycle_Yellow = "Bikes/CruiserMotorcycle_Yellow.png";
const Motorcycle_Black = "Bikes/Motorcycle_Black.png";
const Motorcycle_Blue = "Bikes/Motorcycle_Blue.png";
const Motorcycle_Green = "Bikes/Motorcycle_Green.png";
const Motorcycle_Grey = "Bikes/Motorcycle_Grey.png";
const Motorcycle_Red = "Bikes/Motorcycle_Red.png";
const Motorcycle_Yellow = "Bikes/Motorcycle_Yellow.png";
const QuadBike_Black = "Bikes/QuadBike_Black.png";
const QuadBike_Blue = "Bikes/QuadBike_Blue.png";
const QuadBike_Green = "Bikes/QuadBike_Green.png";
const QuadBike_Grey = "Bikes/QuadBike_Grey.png";
const QuadBike_Red = "Bikes/QuadBike_Red.png";
const QuadBike_Yellow = "Bikes/QuadBike_Yellow.png";
const Scooter_Black = "Bikes/Scooter_Black.png";
const Scooter_Blue = "Bikes/Scooter_Blue.png";
const Scooter_Green = "Bikes/Scooter_Green.png";
const Scooter_Grey = "Bikes/Scooter_Grey.png";
const Scooter_Red = "Bikes/Scooter_Red.png";
const Scooter_Yellow = "Bikes/Scooter_Yellow.png";
const TouringMotorcycle_Black = "Bikes/TouringMotorcycle_Black.png";
const TouringMotorcycle_Blue = "Bikes/TouringMotorcycle_Blue.png";
const TouringMotorcycle_Gray = "Bikes/TouringMotorcycle_Gray.png";
const TouringMotorcycle_Green = "Bikes/TouringMotorcycle_Green.png";
const TouringMotorcycle_Red = "Bikes/TouringMotorcycle_Red.png";
const TouringMotorcycle_Yellow = "Bikes/TouringMotorcycle_Yellow.png";

// Watercraft
const Barge_Black = "Watercraft/Barge_Black.png";
const Barge_Blue = "Watercraft/Barge_Blue.png";
const Barge_Green = "Watercraft/Barge_Green.png";
const Barge_Grey = "Watercraft/Barge_Grey.png";
const Barge_Red = "Watercraft/Barge_Red.png";
const Barge_Yellow = "Watercraft/Barge_Yellow.png";
const Boat_Black = "Watercraft/Boat_Black.png";
const Boat_Blue = "Watercraft/Boat_Blue.png";
const Boat_Gray = "Watercraft/Boat_Gray.png";
const Boat_Green = "Watercraft/Boat_Green.png";
const Boat_Red = "Watercraft/Boat_Red.png";
const Boat_Yellow = "Watercraft/Boat_Yellow.png";
const BulkCarrier_Black = "Watercraft/BulkCarrier_Black.png";
const BulkCarrier_Blue = "Watercraft/BulkCarrier_Blue.png";
const BulkCarrier_Green = "Watercraft/BulkCarrier_Green.png";
const BulkCarrier_Grey = "Watercraft/BulkCarrier_Grey.png";
const BulkCarrier_Red = "Watercraft/BulkCarrier_Red.png";
const BulkCarrier_Yellow = "Watercraft/BulkCarrier_Yellow.png";
const Motorboat_Black = "Watercraft/Motorboat_Black.png";
const Motorboat_Blue = "Watercraft/Motorboat_Blue.png";
const Motorboat_Green = "Watercraft/Motorboat_Green.png";
const Motorboat_Grey = "Watercraft/Motorboat_Grey.png";
const Motorboat_Red = "Watercraft/Motorboat_Red.png";
const Motorboat_Yellow = "Watercraft/Motorboat_Yellow.png";
const Sailboat_Black = "Watercraft/Sailboat_Black.png";
const Sailboat_Blue = "Watercraft/Sailboat_Blue.png";
const Sailboat_Green = "Watercraft/Sailboat_Green.png";
const Sailboat_Grey = "Watercraft/Sailboat_Grey.png";
const Sailboat_Red = "Watercraft/Sailboat_Red.png";
const Sailboat_Yellow = "Watercraft/Sailboat_Yellow.png";
const Tanker_Black = "Watercraft/Tanker_Black.png";
const Tanker_Blue = "Watercraft/Tanker_Blue.png";
const Tanker_Green = "Watercraft/Tanker_Green.png";
const Tanker_Grey = "Watercraft/Tanker_Grey.png";
const Tanker_Red = "Watercraft/Tanker_Red.png";
const Tanker_Yellow = "Watercraft/Tanker_Yellow.png";
const WaterMotorcycle_Black = "Watercraft/WaterMotorcycle_Black.png";
const WaterMotorcycle_Blue = "Watercraft/WaterMotorcycle_Blue.png";
const WaterMotorcycle_Green = "Watercraft/WaterMotorcycle_Green.png";
const WaterMotorcycle_Grey = "Watercraft/WaterMotorcycle_Grey.png";
const WaterMotorcycle_Red = "Watercraft/WaterMotorcycle_Red.png";
const WaterMotorcycle_Yellow = "Watercraft/WaterMotorcycle_Yellow.png";

// Car

const Cabriolet_Black = "Car/Cabriolet_Black.png";
const Cabriolet_Blue = "Car/Cabriolet_Blue.png";
const Cabriolet_Green = "Car/Cabriolet_Green.png";
const Cabriolet_Grey = "Car/Cabriolet_Grey.png";
const Cabriolet_Red = "Car/Cabriolet_Red.png";
const Cabriolet_Yellow = "Car/Cabriolet_Yellow.png";
const CamperVan_Black = "Car/CamperVan_Black.png";
const CamperVan_Blue = "Car/CamperVan_Blue.png";
const CamperVan_Green = "Car/CamperVan_Green.png";
const CamperVan_Grey = "Car/CamperVan_Grey.png";
const CamperVan_Red = "Car/CamperVan_Red.png";
const CamperVan_Yellow = "Car/CamperVan_Yellow.png";
const Car_Black = "Car/Car_Black.png";
const Car_Blue = "Car/Car_Blue.png";
const Car_Green = "Car/Car_Green.png";
const Car_Grey = "Car/Car_Grey.png";
const Car_Red = "Car/Car_Red.png";
const Car_Yellow = "Car/Car_Yellow.png";
const ExecutiveCar_Black = "Car/ExecutiveCar_Black.png";
const ExecutiveCar_Blue = "Car/ExecutiveCar_Blue.png";
const ExecutiveCar_Green = "Car/ExecutiveCar_Green.png";
const ExecutiveCar_Grey = "Car/ExecutiveCar_Grey.png";
const ExecutiveCar_Red = "Car/ExecutiveCar_Red.png";
const ExecutiveCar_Yellow = "Car/ExecutiveCar_Yellow.png";
const Hatchback_Black = "Car/Hatchback_Black.png";
const Hatchback_Blue = "Car/Hatchback_Blue.png";
const Hatchback_Green = "Car/Hatchback_Green.png";
const Hatchback_Grey = "Car/Hatchback_Grey.png";
const Hatchback_Red = "Car/Hatchback_Red.png";
const Hatchback_Yellow = "Car/Hatchback_Yellow.png";
const HybridCar_Black = "Car/HybridCar_Black.png";
const HybridCar_Blue = "Car/HybridCar_Blue.png";
const HybridCar_Green = "Car/HybridCar_Green.png";
const HybridCar_Grey = "Car/HybridCar_Grey.png";
const HybridCar_Red = "Car/HybridCar_Red.png";
const HybridCar_Yellow = "Car/HybridCar_Yellow.png";
const Jeep_Black = "Car/Jeep_Black.png";
const Jeep_Blue = "Car/Jeep_Blue.png";
const Jeep_Green = "Car/Jeep_Green.png";
const Jeep_Grey = "Car/Jeep_Grey.png";
const Jeep_Red = "Car/Jeep_Red.png";
const Jeep_Yellow = "Car/Jeep_Yellow.png";
const Limousine_Black = "Car/Limousine_Black.png";
const Limousine_Blue = "Car/Limousine_Blue.png";
const Limousine_Green = "Car/Limousine_Green.png";
const Limousine_Grey = "Car/Limousine_Grey.png";
const Limousine_Red = "Car/Limousine_Red.png";
const Limousine_Yellow = "Car/Limousine_Yellow.png";
const LuxuryCar__Black = "Car/LuxuryCar__Black.png";
const LuxuryCar__Blue = "Car/LuxuryCar__Blue.png";
const LuxuryCar__Gray = "Car/LuxuryCar__Gray.png";
const LuxuryCar__Green = "Car/LuxuryCar__Green.png";
const LuxuryCar__Red = "Car/LuxuryCar__Red.png";
const LuxuryCar__Yellow = "Car/LuxuryCar__Yellow.png";
const Minibus_Black = "Car/Minibus_Black.png";
const Minibus_Blue = "Car/Minibus_Blue.png";
const Minibus_Green = "Car/Minibus_Green.png";
const Minibus_Grey = "Car/Minibus_Grey.png";
const Minibus_Red = "Car/Minibus_Red.png";
const Minibus_Yellow = "Car/Minibus_Yellow.png";
const Minicar_Black = "Car/Minicar_Black.png";
const Minicar_Blue = "Car/Minicar_Blue.png";
const Minicar_Green = "Car/Minicar_Green.png";
const Minicar_Grey = "Car/Minicar_Grey.png";
const Minicar_Red = "Car/Minicar_Red.png";
const Minicar_Yellow = "Car/Minicar_Yellow.png";
const MinicarTrailer_Black = "Car/MinicarTrailer_Black.png";
const MinicarTrailer_Blue = "Car/MinicarTrailer_Blue.png";
const MinicarTrailer_Green = "Car/MinicarTrailer_Green.png";
const MinicarTrailer_Grey = "Car/MinicarTrailer_Grey.png";
const MinicarTrailer_Red = "Car/MinicarTrailer_Red.png";
const MinicarTrailer_Yellow = "Car/MinicarTrailer_Yellow.png";
const Minitruck_Black = "Car/Minitruck_Black.png";
const Minitruck_Blue = "Car/Minitruck_Blue.png";
const Minitruck_Green = "Car/Minitruck_Green.png";
const Minitruck_Grey = "Car/Minitruck_Grey.png";
const Minitruck_Red = "Car/Minitruck_Red.png";
const Minitruck_Yellow = "Car/Minitruck_Yellow.png";
const MUV_Black = "Car/MUV_Black.png";
const MUV_Blue = "Car/MUV_Blue.png";
const MUV_Green = "Car/MUV_Green.png";
const MUV_Grey = "Car/MUV_Grey.png";
const MUV_Red = "Car/MUV_Red.png";
const MUV_Yellow = "Car/MUV_Yellow.png";
const PanelVan_Black = "Car/PanelVan_Black.png";
const PanelVan_Blue = "Car/PanelVan_Blue.png";
const PanelVan_Green = "Car/PanelVan_Green.png";
const PanelVan_Grey = "Car/PanelVan_Grey.png";
const PanelVan_Red = "Car/PanelVan_Red.png";
const PanelVan_Yellow = "Car/PanelVan_Yellow.png";
const Pickup_Black = "Car/Pickup_Black.png";
const Pickup_Blue = "Car/Pickup_Blue.png";
const Pickup_Green = "Car/Pickup_Green.png";
const Pickup_Grey = "Car/Pickup_Grey.png";
const Pickup_Red = "Car/Pickup_Red.png";
const Pickup_Yellow = "Car/Pickup_Yellow.png";
const ServiceVan_Black = "Car/ServiceVan_Black.png";
const ServiceVan_Blue = "Car/ServiceVan_Blue.png";
const ServiceVan_Green = "Car/ServiceVan_Green.png";
const ServiceVan_Grey = "Car/ServiceVan_Grey.png";
const ServiceVan_Red = "Car/ServiceVan_Red.png";
const ServiceVan_Yellow = "Car/ServiceVan_Yellow.png";
const SportsCar_Black = "Car/SportsCar_Black.png";
const SportsCar_Blue = "Car/SportsCar_Blue.png";
const SportsCar_Green = "Car/SportsCar_Green.png";
const SportsCar_Grey = "Car/SportsCar_Grey.png";
const SportsCar_Red = "Car/SportsCar_Red.png";
const SportsCar_Yellow = "Car/SportsCar_Yellow.png";
const StationWagon_Black = "Car/StationWagon_Black.png";
const StationWagon_Blue = "Car/StationWagon_Blue.png";
const StationWagon_Green = "Car/StationWagon_Green.png";
const StationWagon_Grey = "Car/StationWagon_Grey.png";
const StationWagon_Red = "Car/StationWagon_Red.png";
const StationWagon_Yellow = "Car/StationWagon_Yellow.png";
const SUV_Black = "Car/SUV_Black.png";
const SUV_Blue = "Car/SUV_Blue.png";
const SUV_Green = "Car/SUV_Green.png";
const SUV_Grey = "Car/SUV_Grey.png";
const SUV_Red = "Car/SUV_Red.png";
const SUV_Yellow = "Car/SUV_Yellow.png";
const Van_Black = "Car/Van_Black.png";
const Van_Blue = "Car/Van_Blue.png";
const Van_Green = "Car/Van_Green.png";
const Van_Grey = "Car/Van_Grey.png";
const Van_Red = "Car/Van_Red.png";
const Van_Yellow = "Car/Van_Yellow.png";

// Lights
const trafficlight = "Lights/trafficlight.png";
const amber_light = "Lights/amber_light.png";
const green_light = "Lights/green_light.png";
const grey_light = "Lights/grey_light.png";
const red_light = "Lights/red_light.png";
const traffic_light_none = "Lights/traffic_light_none.png";
const traffic_light_green = "Lights/traffic_light_green.png";
const traffic_light_red = "Lights/traffic_light_red.png";

// Machinery

const BackhoeLoader_Black = "Machinery/BackhoeLoader_Black.png";
const BackhoeLoader_Blue = "Machinery/BackhoeLoader_Blue.png";
const BackhoeLoader_Green = "Machinery/BackhoeLoader_Green.png";
const BackhoeLoader_Grey = "Machinery/BackhoeLoader_Grey.png";
const BackhoeLoader_Red = "Machinery/BackhoeLoader_Red.png";
const BackhoeLoader_Yellow = "Machinery/BackhoeLoader_Yellow.png";
const Bulldozer_Black = "Machinery/Bulldozer_Black.png";
const Bulldozer_Blue = "Machinery/Bulldozer_Blue.png";
const Bulldozer_Green = "Machinery/Bulldozer_Green.png";
const Bulldozer_Grey = "Machinery/Bulldozer_Grey.png";
const Bulldozer_Red = "Machinery/Bulldozer_Red.png";
const Bulldozer_Yellow = "Machinery/Bulldozer_Yellow.png";
const CompactExcavator_Black = "Machinery/CompactExcavator_Black.png";
const CompactExcavator_Blue = "Machinery/CompactExcavator_Blue.png";
const CompactExcavator_Green = "Machinery/CompactExcavator_Green.png";
const CompactExcavator_Grey = "Machinery/CompactExcavator_Grey.png";
const CompactExcavator_Red = "Machinery/CompactExcavator_Red.png";
const CompactExcavator_Yellow = "Machinery/CompactExcavator_Yellow.png";
const Excavator_Black = "Machinery/Excavator_Black.png";
const Excavator_Blue = "Machinery/Excavator_Blue.png";
const Excavator_Green = "Machinery/Excavator_Green.png";
const Excavator_Grey = "Machinery/Excavator_Grey.png";
const Excavator_Red = "Machinery/Excavator_Red.png";
const Excavator_Yellow = "Machinery/Excavator_Yellow.png";
const FarmTractor_Black = "Machinery/FarmTractor_Black.png";
const FarmTractor_Blue = "Machinery/FarmTractor_Blue.png";
const FarmTractor_Green = "Machinery/FarmTractor_Green.png";
const FarmTractor_Grey = "Machinery/FarmTractor_Grey.png";
const FarmTractor_Red = "Machinery/FarmTractor_Red.png";
const FarmTractor_Yellow = "Machinery/FarmTractor_Yellow.png";
const ForkliftTruck__Black = "Machinery/ForkliftTruck__Black.png";
const ForkliftTruck__Blue = "Machinery/ForkliftTruck__Blue.png";
const ForkliftTruck__Green = "Machinery/ForkliftTruck__Green.png";
const ForkliftTruck__Grey = "Machinery/ForkliftTruck__Grey.png";
const ForkliftTruck__Red = "Machinery/ForkliftTruck__Red.png";
const ForkliftTruck__Yellow = "Machinery/ForkliftTruck__Yellow.png";
const ForkliftTruck_Loaded_Black = "Machinery/ForkliftTruck_Loaded_Black.png";
const ForkliftTruck_Loaded_Blue = "Machinery/ForkliftTruck_Loaded_Blue.png";
const ForkliftTruck_Loaded_Green = "Machinery/ForkliftTruck_Loaded_Green.png";
const ForkliftTruck_Loaded_Grey = "Machinery/ForkliftTruck_Loaded_Grey.png";
const ForkliftTruck_Loaded_Red = "Machinery/ForkliftTruck_Loaded_Red.png";
const ForkliftTruck_Loaded_Yellow = "Machinery/ForkliftTruck_Loaded_Yellow.png";
const LandfillCompactor_Black = "Machinery/LandfillCompactor_Black.png";
const LandfillCompactor_Blue = "Machinery/LandfillCompactor_Blue.png";
const LandfillCompactor_Green = "Machinery/LandfillCompactor_Green.png";
const LandfillCompactor_Grey = "Machinery/LandfillCompactor_Grey.png";
const LandfillCompactor_Red = "Machinery/LandfillCompactor_Red.png";
const LandfillCompactor_Yellow = "Machinery/LandfillCompactor_Yellow.png";
const Loader_Black = "Machinery/Loader_Black.png";
const Loader_Blue = "Machinery/Loader_Blue.png";
const Loader_Green = "Machinery/Loader_Green.png";
const Loader_Grey = "Machinery/Loader_Grey.png";
const Loader_Red = "Machinery/Loader_Red.png";
const Loader_Yellow = "Machinery/Loader_Yellow.png";
const MaterialHandler_Black = "Machinery/MaterialHandler_Black.png";
const MaterialHandler_Blue = "Machinery/MaterialHandler_Blue.png";
const MaterialHandler_Green = "Machinery/MaterialHandler_Green.png";
const MaterialHandler_Grey = "Machinery/MaterialHandler_Grey.png";
const MaterialHandler_Red = "Machinery/MaterialHandler_Red.png";
const MaterialHandler_Yellow = "Machinery/MaterialHandler_Yellow.png";
const RoadRoller_Black = "Machinery/RoadRoller_Black.png";
const RoadRoller_Blue = "Machinery/RoadRoller_Blue.png";
const RoadRoller_Green = "Machinery/RoadRoller_Green.png";
const RoadRoller_Grey = "Machinery/RoadRoller_Grey.png";
const RoadRoller_Red = "Machinery/RoadRoller_Red.png";
const RoadRoller_Yellow = "Machinery/RoadRoller_Yellow.png";
const RoadSweeper_Black = "Machinery/RoadSweeper_Black.png";
const RoadSweeper_Blue = "Machinery/RoadSweeper_Blue.png";
const RoadSweeper_Green = "Machinery/RoadSweeper_Green.png";
const RoadSweeper_Grey = "Machinery/RoadSweeper_Grey.png";
const RoadSweeper_Red = "Machinery/RoadSweeper_Red.png";
const RoadSweeper_Yellow = "Machinery/RoadSweeper_Yellow.png";
const RollerCompactor_Black = "Machinery/RollerCompactor_Black.png";
const RollerCompactor_Blue = "Machinery/RollerCompactor_Blue.png";
const RollerCompactor_Green = "Machinery/RollerCompactor_Green.png";
const RollerCompactor_Grey = "Machinery/RollerCompactor_Grey.png";
const RollerCompactor_Red = "Machinery/RollerCompactor_Red.png";
const RollerCompactor_Yellow = "Machinery/RollerCompactor_Yellow.png";
const SkidSteerLoader_Black = "Machinery/SkidSteerLoader_Black.png";
const SkidSteerLoader_Blue = "Machinery/SkidSteerLoader_Blue.png";
const SkidSteerLoader_Green = "Machinery/SkidSteerLoader_Green.png";
const SkidSteerLoader_Grey = "Machinery/SkidSteerLoader_Grey.png";
const SkidSteerLoader_Red = "Machinery/SkidSteerLoader_Red.png";
const SkidSteerLoader_Yellow = "Machinery/SkidSteerLoader_Yellow.png";
const SkipLoader_Black = "Machinery/SkipLoader_Black.png";
const SkipLoader_Blue = "Machinery/SkipLoader_Blue.png";
const SkipLoader_Green = "Machinery/SkipLoader_Green.png";
const SkipLoader_Grey = "Machinery/SkipLoader_Grey.png";
const SkipLoader_Red = "Machinery/SkipLoader_Red.png";
const SkipLoader_Yellow = "Machinery/SkipLoader_Yellow.png";
const SoilStabilizer_Black = "Machinery/SoilStabilizer_Black.png";
const SoilStabilizer_Blue = "Machinery/SoilStabilizer_Blue.png";
const SoilStabilizer_Green = "Machinery/SoilStabilizer_Green.png";
const SoilStabilizer_Grey = "Machinery/SoilStabilizer_Grey.png";
const SoilStabilizer_Red = "Machinery/SoilStabilizer_Red.png";
const SoilStabilizer_Yellow = "Machinery/SoilStabilizer_Yellow.png";
const SteamRoller_Black = "Machinery/SteamRoller_Black.png";
const SteamRoller_Blue = "Machinery/SteamRoller_Blue.png";
const SteamRoller_Green = "Machinery/SteamRoller_Green.png";
const SteamRoller_Grey = "Machinery/SteamRoller_Grey.png";
const SteamRoller_Red = "Machinery/SteamRoller_Red.png";
const SteamRoller_Yellow = "Machinery/SteamRoller_Yellow.png";
const StreetSweeper_Black = "Machinery/StreetSweeper_Black.png";
const StreetSweeper_Blue = "Machinery/StreetSweeper_Blue.png";
const StreetSweeper_Green = "Machinery/StreetSweeper_Green.png";
const StreetSweeper_Grey = "Machinery/StreetSweeper_Grey.png";
const StreetSweeper_Red = "Machinery/StreetSweeper_Red.png";
const StreetSweeper_Yellow = "Machinery/StreetSweeper_Yellow.png";
const TelescopicHandler_Black = "Machinery/TelescopicHandler_Black.png";
const TelescopicHandler_Blue = "Machinery/TelescopicHandler_Blue.png";
const TelescopicHandler_Green = "Machinery/TelescopicHandler_Green.png";
const TelescopicHandler_Grey = "Machinery/TelescopicHandler_Grey.png";
const TelescopicHandler_Red = "Machinery/TelescopicHandler_Red.png";
const TelescopicHandler_Yellow = "Machinery/TelescopicHandler_Yellow.png";
const TruckMountedCrane__Black = "Machinery/TruckMountedCrane__Black.png";
const TruckMountedCrane__Blue = "Machinery/TruckMountedCrane__Blue.png";
const TruckMountedCrane__Green = "Machinery/TruckMountedCrane__Green.png";
const TruckMountedCrane__Grey = "Machinery/TruckMountedCrane__Grey.png";
const TruckMountedCrane__Red = "Machinery/TruckMountedCrane__Red.png";
const TruckMountedCrane__Yellow = "Machinery/TruckMountedCrane__Yellow.png";
const TruckMountedCrane_Working_Black = "Machinery/TruckMountedCrane_Working_Black.png";
const TruckMountedCrane_Working_Blue = "Machinery/TruckMountedCrane_Working_Blue.png";
const TruckMountedCrane_Working_Green = "Machinery/TruckMountedCrane_Working_Green.png";
const TruckMountedCrane_Working_Grey = "Machinery/TruckMountedCrane_Working_Grey.png";
const TruckMountedCrane_Working_Red = "Machinery/TruckMountedCrane_Working_Red.png";
const TruckMountedCrane_Working_Yellow = "Machinery/TruckMountedCrane_Working_Yellow.png";
const WheeledExcavator_Black = "Machinery/WheeledExcavator_Black.png";
const WheeledExcavator_Blue = "Machinery/WheeledExcavator_Blue.png";
const WheeledExcavator_Green = "Machinery/WheeledExcavator_Green.png";
const WheeledExcavator_Grey = "Machinery/WheeledExcavator_Grey.png";
const WheeledExcavator_Red = "Machinery/WheeledExcavator_Red.png";
const WheeledExcavator_Yellow = "Machinery/WheeledExcavator_Yellow.png";
const WreckingBall_Black = "Machinery/WreckingBall_Black.png";
const WreckingBall_Blue = "Machinery/WreckingBall_Blue.png";
const WreckingBall_Green = "Machinery/WreckingBall_Green.png";
const WreckingBall_Grey = "Machinery/WreckingBall_Grey.png";
const WreckingBall_Red = "Machinery/WreckingBall_Red.png";
const WreckingBall_Yellow = "Machinery/WreckingBall_Yellow.png";

// Public transport

const Coach_Black = "PublicTransport/Coach_Black.png";
const Coach_Blue = "PublicTransport/Coach_Blue.png";
const Coach_Green = "PublicTransport/Coach_Green.png";
const Coach_Grey = "PublicTransport/Coach_Grey.png";
const Coach_Red = "PublicTransport/Coach_Red.png";
const Coach_Yellow = "PublicTransport/Coach_Yellow.png";
const CommuterBus_Black = "PublicTransport/CommuterBus_Black.png";
const CommuterBus_Blue = "PublicTransport/CommuterBus_Blue.png";
const CommuterBus_Green = "PublicTransport/CommuterBus_Green.png";
const CommuterBus_Grey = "PublicTransport/CommuterBus_Grey.png";
const CommuterBus_Red = "PublicTransport/CommuterBus_Red.png";
const CommuterBus_Yellow = "PublicTransport/CommuterBus_Yellow.png";
const Taxi_Black = "PublicTransport/Taxi_Black.png";
const Taxi_Blue = "PublicTransport/Taxi_Blue.png";
const Taxi_Green = "PublicTransport/Taxi_Green.png";
const Taxi_Grey = "PublicTransport/Taxi_Grey.png";
const Taxi_Red = "PublicTransport/Taxi_Red.png";
const Taxi_Yellow = "PublicTransport/Taxi_Yellow.png";
const TransitBus_Black = "PublicTransport/TransitBus_Black.png";
const TransitBus_Blue = "PublicTransport/TransitBus_Blue.png";
const TransitBus_Green = "PublicTransport/TransitBus_Green.png";
const TransitBus_Grey = "PublicTransport/TransitBus_Grey.png";
const TransitBus_Red = "PublicTransport/TransitBus_Red.png";
const TransitBus_Yellow = "PublicTransport/TransitBus_Yellow.png";

// Trailers

const BoxTrailer_Black = "Trailers/BoxTrailer_Black.png";
const BoxTrailer_Blue = "Trailers/BoxTrailer_Blue.png";
const BoxTrailer_Green = "Trailers/BoxTrailer_Green.png";
const BoxTrailer_Grey = "Trailers/BoxTrailer_Grey.png";
const BoxTrailer_Red = "Trailers/BoxTrailer_Red.png";
const BoxTrailer_Yellow = "Trailers/BoxTrailer_Yellow.png";
const Caravan_Black = "Trailers/Caravan_Black.png";
const Caravan_Blue = "Trailers/Caravan_Blue.png";
const Caravan_Green = "Trailers/Caravan_Green.png";
const Caravan_Grey = "Trailers/Caravan_Grey.png";
const Caravan_Red = "Trailers/Caravan_Red.png";
const Caravan_Yellow = "Trailers/Caravan_Yellow.png";
const FullTrailer_Black = "Trailers/FullTrailer_Black.png";
const FullTrailer_Blue = "Trailers/FullTrailer_Blue.png";
const FullTrailer_Green = "Trailers/FullTrailer_Green.png";
const FullTrailer_Grey = "Trailers/FullTrailer_Grey.png";
const FullTrailer_Red = "Trailers/FullTrailer_Red.png";
const FullTrailer_Yellow = "Trailers/FullTrailer_Yellow.png";
const SuperSilentGeneratorTrailer_Black = "Trailers/SuperSilentGeneratorTrailer_Black.png";
const SuperSilentGeneratorTrailer_Blue = "Trailers/SuperSilentGeneratorTrailer_Blue.png";
const SuperSilentGeneratorTrailer_Green = "Trailers/SuperSilentGeneratorTrailer_Green.png";
const SuperSilentGeneratorTrailer_Grey = "Trailers/SuperSilentGeneratorTrailer_Grey.png";
const SuperSilentGeneratorTrailer_Red = "Trailers/SuperSilentGeneratorTrailer_Red.png";
const SuperSilentGeneratorTrailer_Yellow = "Trailers/SuperSilentGeneratorTrailer_Yellow.png";
const TankerTrailer_Black = "Trailers/TankerTrailer_Black.png";
const TankerTrailer_Blue = "Trailers/TankerTrailer_Blue.png";
const TankerTrailer_Green = "Trailers/TankerTrailer_Green.png";
const TankerTrailer_Grey = "Trailers/TankerTrailer_Grey.png";
const TankerTrailer_Red = "Trailers/TankerTrailer_Red.png";
const TankerTrailer_Yellow = "Trailers/TankerTrailer_Yellow.png";
const TravelTrailer_Black = "Trailers/TravelTrailer_Black.png";
const TravelTrailer_Blue = "Trailers/TravelTrailer_Blue.png";
const TravelTrailer_Green = "Trailers/TravelTrailer_Green.png";
const TravelTrailer_Grey = "Trailers/TravelTrailer_Grey.png";
const TravelTrailer_Red = "Trailers/TravelTrailer_Red.png";
const TravelTrailer_Yellow = "Trailers/TravelTrailer_Yellow.png";
const WaterBowserTrailer_Black = "Trailers/WaterBowserTrailer_Black.png";
const WaterBowserTrailer_Blue = "Trailers/WaterBowserTrailer_Blue.png";
const WaterBowserTrailer_Green = "Trailers/WaterBowserTrailer_Green.png";
const WaterBowserTrailer_Grey = "Trailers/WaterBowserTrailer_Grey.png";
const WaterBowserTrailer_Red = "Trailers/WaterBowserTrailer_Red.png";
const WaterBowserTrailer_Yellow = "Trailers/WaterBowserTrailer_Yellow.png";

// Trucks

const BinTruck_Black = "Trucks/BinTruck_Black.png";
const BinTruck_Blue = "Trucks/BinTruck_Blue.png";
const BinTruck_Green = "Trucks/BinTruck_Green.png";
const BinTruck_Grey = "Trucks/BinTruck_Grey.png";
const BinTruck_Red = "Trucks/BinTruck_Red.png";
const BinTruck_Yellow = "Trucks/BinTruck_Yellow.png";
const FuelTankTruck_Black = "Trucks/FuelTankTruck_Black.png";
const FuelTankTruck_Blue = "Trucks/FuelTankTruck_Blue.png";
const FuelTankTruck_Green = "Trucks/FuelTankTruck_Green.png";
const FuelTankTruck_Grey = "Trucks/FuelTankTruck_Grey.png";
const FuelTankTruck_Red = "Trucks/FuelTankTruck_Red.png";
const FuelTankTruck_Yellow = "Trucks/FuelTankTruck_Yellow.png";
const HaulTruck_Black = "Trucks/HaulTruck_Black.png";
const HaulTruck_Blue = "Trucks/HaulTruck_Blue.png";
const HaulTruck_Green = "Trucks/HaulTruck_Green.png";
const HaulTruck_Grey = "Trucks/HaulTruck_Grey.png";
const HaulTruck_Red = "Trucks/HaulTruck_Red.png";
const HaulTruck_Yellow = "Trucks/HaulTruck_Yellow.png";
const Lorry_Black = "Trucks/Lorry_Black.png";
const Lorry_Blue = "Trucks/Lorry_Blue.png";
const Lorry_Green = "Trucks/Lorry_Green.png";
const Lorry_Grey = "Trucks/Lorry_Grey.png";
const Lorry_Red = "Trucks/Lorry_Red.png";
const Lorry_Yellow = "Trucks/Lorry_Yellow.png";
const LorryCab_Black = "Trucks/LorryCab_Black.png";
const LorryCab_Blue = "Trucks/LorryCab_Blue.png";
const LorryCab_Green = "Trucks/LorryCab_Green.png";
const LorryCab_Grey = "Trucks/LorryCab_Grey.png";
const LorryCab_Red = "Trucks/LorryCab_Red.png";
const LorryCab_Yellow = "Trucks/LorryCab_Yellow.png";
const LorryTrailer_Black = "Trucks/LorryTrailer_Black.png";
const LorryTrailer_Blue = "Trucks/LorryTrailer_Blue.png";
const LorryTrailer_Green = "Trucks/LorryTrailer_Green.png";
const LorryTrailer_Grey = "Trucks/LorryTrailer_Grey.png";
const LorryTrailer_Red = "Trucks/LorryTrailer_Red.png";
const LorryTrailer_Yellow = "Trucks/LorryTrailer_Yellow.png";
const PetroleumTankTruck_Black = "Trucks/PetroleumTankTruck_Black.png";
const PetroleumTankTruck_Blue = "Trucks/PetroleumTankTruck_Blue.png";
const PetroleumTankTruck_Green = "Trucks/PetroleumTankTruck_Green.png";
const PetroleumTankTruck_Grey = "Trucks/PetroleumTankTruck_Grey.png";
const PetroleumTankTruck_Red = "Trucks/PetroleumTankTruck_Red.png";
const PetroleumTankTruck_Yellow = "Trucks/PetroleumTankTruck_Yellow.png";
const SkipTruck_Black = "Trucks/SkipTruck_Black.png";
const SkipTruck_Blue = "Trucks/SkipTruck_Blue.png";
const SkipTruck_Green = "Trucks/SkipTruck_Green.png";
const SkipTruck_Grey = "Trucks/SkipTruck_Grey.png";
const SkipTruck_Red = "Trucks/SkipTruck_Red.png";
const SkipTruck_Yellow = "Trucks/SkipTruck_Yellow.png";
const TankerTruck_Black = "Trucks/TankerTruck_Black.png";
const TankerTruck_Blue = "Trucks/TankerTruck_Blue.png";
const TankerTruck_Green = "Trucks/TankerTruck_Green.png";
const TankerTruck_Grey = "Trucks/TankerTruck_Grey.png";
const TankerTruck_Red = "Trucks/TankerTruck_Red.png";
const TankerTruck_Yellow = "Trucks/TankerTruck_Yellow.png";
const TractorTrailer2_Black = "Trucks/TractorTrailer2_Black.png";
const TractorTrailer2_Blue = "Trucks/TractorTrailer2_Blue.png";
const TractorTrailer2_Green = "Trucks/TractorTrailer2_Green.png";
const TractorTrailer2_Grey = "Trucks/TractorTrailer2_Grey.png";
const TractorTrailer2_Red = "Trucks/TractorTrailer2_Red.png";
const TractorTrailer2_Yellow = "Trucks/TractorTrailer2_Yellow.png";
const TractorTrailer_Black = "Trucks/TractorTrailer_Black.png";
const TractorTrailer_Blue = "Trucks/TractorTrailer_Blue.png";
const TractorTrailer_Green = "Trucks/TractorTrailer_Green.png";
const TractorTrailer_Grey = "Trucks/TractorTrailer_Grey.png";
const TractorTrailer_Red = "Trucks/TractorTrailer_Red.png";
const TractorTrailer_Yellow = "Trucks/TractorTrailer_Yellow.png";
const Truck_Black = "Trucks/Truck_Black.png";
const Truck_Blue = "Trucks/Truck_Blue.png";
const Truck_Green = "Trucks/Truck_Green.png";
const Truck_Grey = "Trucks/Truck_Grey.png";
const Truck_Red = "Trucks/Truck_Red.png";
const Truck_Yellow = "Trucks/Truck_Yellow.png";
const TruckV_Black = "Trucks/TruckV_Black.png";
const TruckV_Blue = "Trucks/TruckV_Blue.png";
const TruckV_Green = "Trucks/TruckV_Green.png";
const TruckV_Grey = "Trucks/TruckV_Grey.png";
const TruckV_Red = "Trucks/TruckV_Red.png";
const TruckV_Yellow = "Trucks/TruckV_Yellow.png";

// Transportation

const aeroplane = "Transportation/aeroplane.png";
const airplane = "Transportation/airplane.png";
const auto_ricksaw = "Transportation/auto-ricksaw.png";
const bike = "Transportation/bike.png";
const boat = "Transportation/boat.png";
const bus = "Transportation/bus.png";
const bus_1 = "Transportation/bus-1.png";
const cable_car_cabin = "Transportation/cable-car-cabin.png";
const car = "Transportation/car.png";
const car_1 = "Transportation/car-1.png";
const car_2 = "Transportation/car-2.png";
const car_3 = "Transportation/car-3.png";
const catamaran = "Transportation/catamaran.png";
const chairlift = "Transportation/chairlift.png";
const classic_car = "Transportation/classic-car.png";
const helicopter = "Transportation/helicopter.png";
const hot_air_balloon = "Transportation/hot-air-balloon.png";
const jeep = "Transportation/jeep.png";
const locomotive = "Transportation/locomotive.png";
const lorry = "Transportation/lorry.png";
const minibus = "Transportation/minibus.png";
const monorail = "Transportation/monorail.png";
const motorbike = "Transportation/motorbike.png";
const quad = "Transportation/quad.png";
const sailboat = "Transportation/sailboat.png";
const sailing_ship = "Transportation/sailing-ship.png";
const school_bus = "Transportation/school-bus.png";
const ship = "Transportation/ship.png";
const ship_1 = "Transportation/ship-1.png";
const ship_2 = "Transportation/ship-2.png";
const ship_3 = "Transportation/ship-3.png";
const sidecar = "Transportation/sidecar.png";
const spaceship = "Transportation/spaceship.png";
const sportive_car = "Transportation/sportive-car.png";
const submarine = "Transportation/submarine.png";
const taxi = "Transportation/taxi.png";
const taxi_1 = "Transportation/taxi-1.png";
const tractor = "Transportation/tractor.png";
const train = "Transportation/train.png";
const train_1 = "Transportation/train-1.png";
const train_2 = "Transportation/train-2.png";
const tram = "Transportation/tram.png";
const tricycle = "Transportation/tricycle.png";
const trolleybus = "Transportation/trolleybus.png";
const truck = "Transportation/truck.png";
const ufo = "Transportation/ufo.png";
const underground = "Transportation/underground.png";
const van = "Transportation/van.png";
const van_1 = "Transportation/van-1.png";
const zeppelin = "Transportation/zeppelin.png";

// Assets

const a_151_actions = "Assets/151-actions.png";
const a_151_advert = "Assets/151-advert.png";
const a_151_antennas = "Assets/151-antennas.png";
const a_151_archives = "Assets/151-archives.png";
const a_151_attached = "Assets/151-attached.png";
const a_151_banker = "Assets/151-banker.png";
const a_151_barrow = "Assets/151-barrow.png";
const a_151_bed_side = "Assets/151-bed-side.png";
const a_151_beds = "Assets/151-beds.png";
const a_151_blackboard = "Assets/151-blackboard.png";
const a_151_blocked = "Assets/151-blocked.png";
const a_151_book_bag = "Assets/151-book-bag.png";
const a_151_book_bag_1 = "Assets/151-book-bag-1.png";
const a_151_bridges = "Assets/151-bridges.png";
const a_151_calculations = "Assets/151-calculations.png";
const a_151_carrier = "Assets/151-carrier.png";
const a_151_ceremonial = "Assets/151-ceremonial.png";
const a_151_chairs = "Assets/151-chairs.png";
const a_151_cleaned = "Assets/151-cleaned.png";
const a_151_closing = "Assets/151-closing.png";
const a_151_cooling = "Assets/151-cooling.png";
const a_151_demostration = "Assets/151-demostration.png";
const a_151_deposit = "Assets/151-deposit.png";
const a_151_dialogue = "Assets/151-dialogue.png";
const a_151_disturbance = "Assets/151-disturbance.png";
const a_151_domestic_phone = "Assets/151-domestic-phone.png";
const a_151_door = "Assets/151-door.png";
const a_151_door_key = "Assets/151-door-key.png";
const a_151_doorway = "Assets/151-doorway.png";
const a_151_draws = "Assets/151-draws.png";
const a_151_driller = "Assets/151-driller.png";
const a_151_electric = "Assets/151-electric.png";
const a_151_electric_1 = "Assets/151-electric-1.png";
const a_151_electric_2 = "Assets/151-electric-2.png";
const a_151_entrance = "Assets/151-entrance.png";
const a_151_facade = "Assets/151-facade.png";
const a_151_facade_1 = "Assets/151-facade-1.png";
const a_151_fader = "Assets/151-fader.png";
const a_151_file_storage = "Assets/151-file-storage.png";
const a_151_fireplace = "Assets/151-fireplace.png";
const a_151_fireplace_1 = "Assets/151-fireplace-1.png";
const a_151_flats = "Assets/151-flats.png";
const a_151_flats_1 = "Assets/151-flats-1.png";
const a_151_frame = "Assets/151-frame.png";
const a_151_garden_work = "Assets/151-garden-work.png";
const a_151_gardener = "Assets/151-gardener.png";
const a_151_gardening = "Assets/151-gardening.png";
const a_151_glamour = "Assets/151-glamour.png";
const a_151_graphic_design = "Assets/151-graphic-design.png";
const a_151_graphs = "Assets/151-graphs.png";
const a_151_hacksaw = "Assets/151-hacksaw.png";
const a_151_hand_gesture = "Assets/151-hand-gesture.png";
const a_151_hand_phones = "Assets/151-hand-phones.png";
const a_151_headphones = "Assets/151-headphones.png";
const a_151_health_care = "Assets/151-health-care.png";
const a_151_hearth = "Assets/151-hearth.png";
const a_151_house = "Assets/151-house.png";
const a_151_house_things = "Assets/151-house-things.png";
const a_151_house_things_1 = "Assets/151-house-things-1.png";
const a_151_house_things_2 = "Assets/151-house-things-2.png";
const a_151_houses = "Assets/151-houses.png";
const a_151_indoor = "Assets/151-indoor.png";
const a_151_industrial = "Assets/151-industrial.png";
const a_151_industrial_park = "Assets/151-industrial-park.png";
const a_151_interior_design = "Assets/151-interior-design.png";
const a_151_invention = "Assets/151-invention.png";
const a_151_keyword = "Assets/151-keyword.png";
const a_151_kilograms = "Assets/151-kilograms.png";
const a_151_ladders = "Assets/151-ladders.png";
const a_151_line_chart = "Assets/151-line-chart.png";
const a_151_lover = "Assets/151-lover.png";
const a_151_mail = "Assets/151-mail.png";
const a_151_mailed = "Assets/151-mailed.png";
const a_151_mallet = "Assets/151-mallet.png";
const a_151_mansory = "Assets/151-mansory.png";
const a_151_maps_and_flags = "Assets/151-maps-and-flags.png";
const a_151_maps_and_flags_1 = "Assets/151-maps-and-flags-1.png";
const a_151_maps_and_flags_2 = "Assets/151-maps-and-flags-2.png";
const a_151_maps_and_flags_3 = "Assets/151-maps-and-flags-3.png";
const a_151_maps_and_flags_4 = "Assets/151-maps-and-flags-4.png";
const a_151_maps_and_flags_5 = "Assets/151-maps-and-flags-5.png";
const a_151_maps_and_flags_6 = "Assets/151-maps-and-flags-6.png";
const a_151_measuring = "Assets/151-measuring.png";
const a_151_measuring_1 = "Assets/151-measuring-1.png";
const a_151_mechanics = "Assets/151-mechanics.png";
const a_151_metallic_blind = "Assets/151-metallic-blind.png";
const a_151_money_icons = "Assets/151-money-icons.png";
const a_151_monumental = "Assets/151-monumental.png";
const a_151_motorhome = "Assets/151-motorhome.png";
const a_151_online_store = "Assets/151-online-store.png";
const a_151_packaging = "Assets/151-packaging.png";
const a_151_paint_roller = "Assets/151-paint-roller.png";
const a_151_painted = "Assets/151-painted.png";
const a_151_paper_printer = "Assets/151-paper-printer.png";
const a_151_paper_work = "Assets/151-paper-work.png";
const a_151_picket = "Assets/151-picket.png";
const a_151_pincers = "Assets/151-pincers.png";
const a_151_plan = "Assets/151-plan.png";
const a_151_plans = "Assets/151-plans.png";
const a_151_plumbering = "Assets/151-plumbering.png";
const a_151_plumbering_1 = "Assets/151-plumbering-1.png";
const a_151_printed = "Assets/151-printed.png";
const a_151_property = "Assets/151-property.png";
const a_151_pruners = "Assets/151-pruners.png";
const a_151_purses = "Assets/151-purses.png";
const a_151_radiators = "Assets/151-radiators.png";
const a_151_reading_glasses = "Assets/151-reading-glasses.png";
const a_151_real_estate = "Assets/151-real-estate.png";
const a_151_real_estate_1 = "Assets/151-real-estate-1.png";
const a_151_real_estate_2 = "Assets/151-real-estate-2.png";
const a_151_real_estate_3 = "Assets/151-real-estate-3.png";
const a_151_real_estate_4 = "Assets/151-real-estate-4.png";
const a_151_real_estate_5 = "Assets/151-real-estate-5.png";
const a_151_real_estate_6 = "Assets/151-real-estate-6.png";
const a_151_relax = "Assets/151-relax.png";
const a_151_religious = "Assets/151-religious.png";
const a_151_rent = "Assets/151-rent.png";
const a_151_reparation = "Assets/151-reparation.png";
const a_151_roof = "Assets/151-roof.png";
const a_151_sand_clock = "Assets/151-sand-clock.png";
const a_151_savings = "Assets/151-savings.png";
const a_151_security_system = "Assets/151-security-system.png";
const a_151_security_system_1 = "Assets/151-security-system-1.png";
const a_151_seventeen = "Assets/151-seventeen.png";
const a_151_shower = "Assets/151-shower.png";
const a_151_single = "Assets/151-single.png";
const a_151_sit_down = "Assets/151-sit-down.png";
const a_151_skyline = "Assets/151-skyline.png";
const a_151_step_ladder = "Assets/151-step-ladder.png";
const a_151_storehouse = "Assets/151-storehouse.png";
const a_151_studying = "Assets/151-studying.png";
const a_151_switch_off = "Assets/151-switch-off.png";
const a_151_text_file = "Assets/151-text-file.png";
const a_151_title = "Assets/151-title.png";
const a_151_tools_and_utensils = "Assets/151-tools-and-utensils.png";
const a_151_transportation = "Assets/151-transportation.png";
const a_151_trees = "Assets/151-trees.png";
const a_151_trucks = "Assets/151-trucks.png";
const a_151_trucks_1 = "Assets/151-trucks-1.png";
const a_151_turned_off = "Assets/151-turned-off.png";
const a_151_up = "Assets/151-up.png";
const a_151_up_1 = "Assets/151-up-1.png";
const a_151_up_2 = "Assets/151-up-2.png";
const a_151_ventilation = "Assets/151-ventilation.png";
const a_151_wc = "Assets/151-wc.png";
const a_151_wheel_chair = "Assets/151-wheel-chair.png";
const a_151_wheelbarrow = "Assets/151-wheelbarrow.png";
const a_151_winch = "Assets/151-winch.png";
const a_151_work_tools = "Assets/151-work-tools.png";
const a_151_worker = "Assets/151-worker.png";

// GPS

const compass = "GPS/compass.png";
const compass_1 = "GPS/compass-1.png";
const gps = "GPS/gps.png";
const location3 = "GPS/location3.png";
const location_1 = "GPS/location-1.png";
const location_2 = "GPS/location-2.png";
const location_3 = "GPS/location-3.png";
const locations = "GPS/locations.png";
const map = "GPS/map.png";
const map_1 = "GPS/map-1.png";
const map_2 = "GPS/map-2.png";
const map_3 = "GPS/map-3.png";
const navigator = "GPS/navigator.png";
const panel = "GPS/panel.png";
const pin = "GPS/pin.png";
const placeholder = "GPS/placeholder.png";
const placeholder_1 = "GPS/placeholder-1.png";
const placeholder_2 = "GPS/placeholder-2.png";
const placeholder_3 = "GPS/placeholder-3.png";
const placeholder_4 = "GPS/placeholder-4.png";
const placeholder_5 = "GPS/placeholder-5.png";
const placeholder_6 = "GPS/placeholder-6.png";
const placeholder_7 = "GPS/placeholder-7.png";
const push_pin = "GPS/push-pin.png";
const route = "GPS/route.png";
const route_1 = "GPS/route-1.png";
const satellite = "GPS/satellite.png";
const smartphone = "GPS/smartphone.png";
const smartphone_1 = "GPS/smartphone-1.png";
const target = "GPS/target.png";
const location = "GPS/location.png";

// Professions

const astronaut = "Professions/astronaut.png";
const personplaceholder = "Professions/personplaceholder.png";
const athlete = "Professions/athlete.png";
const builder = "Professions/builder.png";
const carpenter = "Professions/carpenter.png";
const cashier = "Professions/cashier.png";
const chef = "Professions/chef.png";
const chemist = "Professions/chemist.png";
const clown = "Professions/clown.png";
const concierge = "Professions/concierge.png";
const courier = "Professions/courier.png";
const detective = "Professions/detective.png";
const diver = "Professions/diver.png";
const dj = "Professions/dj.png";
const doctor = "Professions/doctor.png";
const dyer = "Professions/dyer.png";
const electrician = "Professions/electrician.png";
const farmer = "Professions/farmer.png";
const firefighter = "Professions/firefighter.png";
const fisherman = "Professions/fisherman.png";
const gardener = "Professions/gardener.png";
const hairdresser = "Professions/hairdresser.png";
const lumberjack = "Professions/lumberjack.png";
const magician = "Professions/magician.png";
const maid = "Professions/maid.png";
const manager = "Professions/manager.png";
const mechanic = "Professions/mechanic.png";
const miner = "Professions/miner.png";
const nurse = "Professions/nurse.png";
const painter = "Professions/painter.png";
const pensioner = "Professions/pensioner.png";
const photographer = "Professions/photographer.png";
const pilot = "Professions/pilot.png";
const plumber = "Professions/plumber.png";
const policeman = "Professions/policeman.png";
const politician = "Professions/politician.png";
const postman = "Professions/postman.png";
const priest = "Professions/priest.png";
const professor = "Professions/professor.png";
const programmer = "Professions/programmer.png";
const sailor = "Professions/sailor.png";
const secretary = "Professions/secretary.png";
const showman = "Professions/showman.png";
const singer = "Professions/singer.png";
const soldier = "Professions/soldier.png";
const stewardess = "Professions/stewardess.png";
const support = "Professions/support.png";
const taxi_driver = "Professions/taxi-driver.png";
const teacher = "Professions/teacher.png";
const waiter = "Professions/waiter.png";
const writer = "Professions/writer.png";

// Logistics

const o_001_distribution_5 = "Logistics/001-distribution-5.png";
const o_002_aerial = "Logistics/002-aerial.png";
const o_003_frontal_view = "Logistics/003-frontal-view.png";
const o_004_distribution_4 = "Logistics/004-distribution-4.png";
const o_005_clipboards = "Logistics/005-clipboards.png";
const o_006_oceanic = "Logistics/006-oceanic.png";
const o_007_placeholders_1 = "Logistics/007-placeholders-1.png";
const o_008_international = "Logistics/008-international.png";
const o_009_packages_2 = "Logistics/009-packages-2.png";
const o_010_tools_and_utensils_3 = "Logistics/010-tools-and-utensils-3.png";
const o_011_weekly_calendar = "Logistics/011-weekly-calendar.png";
const o_012_placeholders = "Logistics/012-placeholders.png";
const o_013_distribution_3 = "Logistics/013-distribution-3.png";
const o_014_shopping_carts_1 = "Logistics/014-shopping-carts-1.png";
const o_015_maps_and_flags_1 = "Logistics/015-maps-and-flags-1.png";
const o_016_organizing = "Logistics/016-organizing.png";
const o_017_maps_and_flags = "Logistics/017-maps-and-flags.png";
const o_018_tools_and_utensils_2 = "Logistics/018-tools-and-utensils-2.png";
const o_019_call_center_1 = "Logistics/019-call-center-1.png";
const o_020_protected = "Logistics/020-protected.png";
const o_021_cubes = "Logistics/021-cubes.png";
const o_022_distribution_2 = "Logistics/022-distribution-2.png";
const o_023_call_center = "Logistics/023-call-center.png";
const o_024_bussiness = "Logistics/024-bussiness.png";
const o_025_charter = "Logistics/025-charter.png";
const o_026_trolley_cart = "Logistics/026-trolley-cart.png";
const o_027_distribution_1 = "Logistics/027-distribution-1.png";
const o_028_tools_and_utensils_1 = "Logistics/028-tools-and-utensils-1.png";
const o_029_airplanes = "Logistics/029-airplanes.png";
const o_030_verified = "Logistics/030-verified.png";
const o_031_shopping_carts = "Logistics/031-shopping-carts.png";
const o_032_transportation = "Logistics/032-transportation.png";
const o_033_packages_1 = "Logistics/033-packages-1.png";
const o_034_changing = "Logistics/034-changing.png";
const o_035_packages = "Logistics/035-packages.png";
const o_036_symbolic = "Logistics/036-symbolic.png";
const o_037_packing = "Logistics/037-packing.png";
const o_038_trucks = "Logistics/038-trucks.png";
const o_039_tools_and_utensils = "Logistics/039-tools-and-utensils.png";
const o_040_circular_arrows = "Logistics/040-circular-arrows.png";
const o_041_debit_card = "Logistics/041-debit-card.png";
const o_042_24_hours = "Logistics/042-24-hours.png";
const o_043_distribution = "Logistics/043-distribution.png";
const o_044_cargo_ship = "Logistics/044-cargo-ship.png";

// Tools

const atomic = "Tools/atomic.png";
const awl = "Tools/awl.png";
const axe = "Tools/axe.png";
const barrels = "Tools/barrels.png";
const battery_1 = "Tools/battery-1.png";
const battery = "Tools/battery.png";
const box = "Tools/box.png";
const charging = "Tools/charging.png";
const chisel = "Tools/chisel.png";
const clamp = "Tools/clamp.png";
const coal = "Tools/coal.png";
const cutter = "Tools/cutter.png";
const driller = "Tools/driller.png";
const ecology = "Tools/ecology.png";
const electric_car = "Tools/electric-car.png";
const electric_tower = "Tools/electric-tower.png";
const eolic_energy = "Tools/eolic-energy.png";
const factory = "Tools/factory.png";
const faucet = "Tools/faucet.png";
const file = "Tools/file.png";
const gas = "Tools/gas.png";
const gas_station = "Tools/gas-station.png";
const glue = "Tools/glue.png";
const hammer = "Tools/hammer.png";
const hammer_1 = "Tools/hammer-1.png";
const hammer_2 = "Tools/hammer-2.png";
const house = "Tools/house.png";
const hydro_power = "Tools/hydro-power.png";
const jigsaw = "Tools/jigsaw.png";
const ladder = "Tools/ladder.png";
const level = "Tools/level.png";
const light_bulb = "Tools/light-bulb.png";
const low_battery = "Tools/low-battery.png";
const marker = "Tools/marker.png";
const mat = "Tools/mat.png";
const measuring_tape = "Tools/measuring-tape.png";
const nail = "Tools/nail.png";
const needle = "Tools/needle.png";
const nut = "Tools/nut.png";
const oil_platform = "Tools/oil-platform.png";
const paint_brush = "Tools/paint-brush.png";
const paint_brush_1 = "Tools/paint-brush-1.png";
const paint_brush_2 = "Tools/paint-brush-2.png";
const paint_bucket = "Tools/paint-bucket.png";
const paint_palette = "Tools/paint-palette.png";
const paint_roller = "Tools/paint-roller.png";
const pantone = "Tools/pantone.png";
const pencil = "Tools/pencil.png";
const pliers = "Tools/pliers.png";
const pliers_1 = "Tools/pliers-1.png";
const pliers_2 = "Tools/pliers-2.png";
const plug = "Tools/plug.png";
const power_button = "Tools/power-button.png";
const power_plant = "Tools/power-plant.png";
const rain = "Tools/rain.png";
const recycle = "Tools/recycle.png";
const renewable_energy = "Tools/renewable-energy.png";
const renewable_energy_1 = "Tools/renewable-energy-1.png";
const ruler = "Tools/ruler.png";
const ruler_1 = "Tools/ruler-1.png";
const save_energy = "Tools/save-energy.png";
const saw = "Tools/saw.png";
const saw_1 = "Tools/saw-1.png";
const scissors = "Tools/scissors.png";
const screw = "Tools/screw.png";
const screwdriver = "Tools/screwdriver.png";
const set_square = "Tools/set-square.png";
const shovel = "Tools/shovel.png";
const shovel_1 = "Tools/shovel-1.png";
const socket = "Tools/socket.png";
const solar_energy = "Tools/solar-energy.png";
const spatula = "Tools/spatula.png";
const tape = "Tools/tape.png";
const toolbox = "Tools/toolbox.png";
const usb_cable = "Tools/usb-cable.png";
const workplace = "Tools/workplace.png";
const wrench = "Tools/wrench.png";
const wrench_1 = "Tools/wrench-1.png";
const wrench_2 = "Tools/wrench-2.png";

// Shipping

const barcode = "Shipping/barcode.png";
const box_1 = "Shipping/box-1.png";
const boxes = "Shipping/boxes.png";
const calendar = "Shipping/calendar.png";
const clipboard = "Shipping/clipboard.png";
const container = "Shipping/container.png";
const delivery = "Shipping/delivery.png";
const delivery_1 = "Shipping/delivery-1.png";
const delivery_2 = "Shipping/delivery-2.png";
const delivery_3 = "Shipping/delivery-3.png";
const delivery_truck = "Shipping/delivery-truck.png";
const envelope = "Shipping/envelope.png";
const find = "Shipping/find.png";
const fragile = "Shipping/fragile.png";
const gift = "Shipping/gift.png";
const laptop = "Shipping/laptop.png";
const package_0 = "Shipping/package.png";
const package_1 = "Shipping/package-1.png";
const package_2 = "Shipping/package-2.png";
const package_3 = "Shipping/package-3.png";
const package_4 = "Shipping/package-4.png";
const package_5 = "Shipping/package-5.png";
const package_6 = "Shipping/package-6.png";
const package_7 = "Shipping/package-7.png";
const safety = "Shipping/safety.png";
const shipping = "Shipping/shipping.png";
const stroller = "Shipping/stroller.png";
const up = "Shipping/up.png";
const warehouse = "Shipping/warehouse.png";
const warehouse_1 = "Shipping/warehouse-1.png";
const warehouse_2 = "Shipping/warehouse-2.png";
const weight = "Shipping/weight.png";
const worldwide = "Shipping/worldwide.png";

// Ecommerce

const auction = "Ecommerce/auction.png";
const badge = "Ecommerce/badge.png";
const badge_1 = "Ecommerce/badge-1.png";
const badge_2 = "Ecommerce/badge-2.png";
const badge_3 = "Ecommerce/badge-3.png";
const badge_4 = "Ecommerce/badge-4.png";
const bedside_table = "Ecommerce/bedside-table.png";
const billing_machine = "Ecommerce/billing-machine.png";
const browser = "Ecommerce/browser.png";
const browser_1 = "Ecommerce/browser-1.png";
const calculator = "Ecommerce/calculator.png";
const cash_register = "Ecommerce/cash-register.png";
const click = "Ecommerce/click.png";
const clock = "Ecommerce/clock.png";
const closed = "Ecommerce/closed.png";
const coin = "Ecommerce/coin.png";
const coin_1 = "Ecommerce/coin-1.png";
const coin_stack = "Ecommerce/coin-stack.png";
const comments = "Ecommerce/comments.png";
const compact_disc = "Ecommerce/compact-disc.png";
const cosmetics = "Ecommerce/cosmetics.png";
const coupon = "Ecommerce/coupon.png";
const credit_card = "Ecommerce/credit-card.png";
const credit_card_1 = "Ecommerce/credit-card-1.png";
const credit_card_2 = "Ecommerce/credit-card-2.png";
const customer_service = "Ecommerce/customer-service.png";
const delivery_cart = "Ecommerce/delivery-cart.png";
const delivery_truck_1 = "Ecommerce/delivery-truck-1.png";
const diamond = "Ecommerce/diamond.png";
const discount = "Ecommerce/discount.png";
const email = "Ecommerce/email.png";
const faq = "Ecommerce/faq.png";
const gift_card = "Ecommerce/gift-card.png";
const groceries = "Ecommerce/groceries.png";
const help = "Ecommerce/help.png";
const like = "Ecommerce/like.png";
const money = "Ecommerce/money.png";
const money_bag = "Ecommerce/money-bag.png";
const monitor = "Ecommerce/monitor.png";
const news = "Ecommerce/news.png";
const notepad = "Ecommerce/notepad.png";
const online_shop = "Ecommerce/online-shop.png";
const online_shop_1 = "Ecommerce/online-shop-1.png";
const online_shop_2 = "Ecommerce/online-shop-2.png";
const online_shop_3 = "Ecommerce/online-shop-3.png";
const open = "Ecommerce/open.png";
const order = "Ecommerce/order.png";
const padlock = "Ecommerce/padlock.png";
const piggy_bank = "Ecommerce/piggy-bank.png";
const price_tag = "Ecommerce/price-tag.png";
const promotion = "Ecommerce/promotion.png";
const purse = "Ecommerce/purse.png";
const qr_code = "Ecommerce/qr-code.png";
const receipt = "Ecommerce/receipt.png";
const refund = "Ecommerce/refund.png";
const return_0 = "Ecommerce/return.png";
const review = "Ecommerce/review.png";
const rocket_ship = "Ecommerce/rocket-ship.png";
const safebox = "Ecommerce/safebox.png";
const search = "Ecommerce/search.png";
const search_1 = "Ecommerce/search-1.png";
const shield = "Ecommerce/shield.png";
const shirt = "Ecommerce/shirt.png";
const shirt_1 = "Ecommerce/shirt-1.png";
const shopping_bag = "Ecommerce/shopping-bag.png";
const shopping_bag_1 = "Ecommerce/shopping-bag-1.png";
const shopping_bag_2 = "Ecommerce/shopping-bag-2.png";
const shopping_basket = "Ecommerce/shopping-basket.png";
const shopping_basket_1 = "Ecommerce/shopping-basket-1.png";
const shopping_basket_2 = "Ecommerce/shopping-basket-2.png";
const shopping_basket_3 = "Ecommerce/shopping-basket-3.png";
const shopping_basket_4 = "Ecommerce/shopping-basket-4.png";
const shopping_cart = "Ecommerce/shopping-cart.png";
const shopping_cart_1 = "Ecommerce/shopping-cart-1.png";
const shopping_cart_2 = "Ecommerce/shopping-cart-2.png";
const shopping_cart_3 = "Ecommerce/shopping-cart-3.png";
const shopping_cart_4 = "Ecommerce/shopping-cart-4.png";
const star = "Ecommerce/star.png";
const statistics = "Ecommerce/statistics.png";
const stock = "Ecommerce/stock.png";
const stock_1 = "Ecommerce/stock-1.png";
const stock_2 = "Ecommerce/stock-2.png";
const stopwatch = "Ecommerce/stopwatch.png";
const store = "Ecommerce/store.png";
const survey = "Ecommerce/survey.png";
const user = "Ecommerce/user.png";
const wallet = "Ecommerce/wallet.png";
const wishlist = "Ecommerce/wishlist.png";
const wishlist_1 = "Ecommerce/wishlist-1.png";

// Traffic

const o_001_highway_1 = "Traffic/001-highway-1.png";
const o_002_two_ways = "Traffic/002-two-ways.png";
const o_003_road_sign = "Traffic/003-road-sign.png";
const o_004_barrier = "Traffic/004-barrier.png";
const o_005_right_arrow = "Traffic/005-right-arrow.png";
const o_006_roundabout = "Traffic/006-roundabout.png";
const o_007_prohibition = "Traffic/007-prohibition.png";
const o_008_curves = "Traffic/008-curves.png";
const o_009_intersection_3 = "Traffic/009-intersection-3.png";
const o_010_caravan_1 = "Traffic/010-caravan-1.png";
const o_011_caravan = "Traffic/011-caravan.png";
const o_012_highway = "Traffic/012-highway.png";
const o_013_traffic_sign_1 = "Traffic/013-traffic-sign-1.png";
const o_014_traffic_sign = "Traffic/014-traffic-sign.png";
const o_015_warning_2 = "Traffic/015-warning-2.png";
const o_016_gas_station = "Traffic/016-gas-station.png";
const o_017_turn_right_2 = "Traffic/017-turn-right-2.png";
const o_018_coffee_shop = "Traffic/018-coffee-shop.png";
const o_019_bicycle_1 = "Traffic/019-bicycle-1.png";
const o_020_bicycle = "Traffic/020-bicycle.png";
const o_021_bump = "Traffic/021-bump.png";
const o_022_intersection_2 = "Traffic/022-intersection-2.png";
const o_023_turn_1 = "Traffic/023-turn-1.png";
const o_024_turn_right_1 = "Traffic/024-turn-right-1.png";
const o_025_turn_left_1 = "Traffic/025-turn-left-1.png";
const o_026_intersection_1 = "Traffic/026-intersection-1.png";
const o_027_intersection = "Traffic/027-intersection.png";
const o_028_waiting = "Traffic/028-waiting.png";
const o_029_horn = "Traffic/029-horn.png";
const o_030_narrow_road = "Traffic/030-narrow-road.png";
const o_031_warning_1 = "Traffic/031-warning-1.png";
const o_032_hill = "Traffic/032-hill.png";
const o_033_truck = "Traffic/033-truck.png";
const o_034_pedestrian_2 = "Traffic/034-pedestrian-2.png";
const o_035_pedestrian_1 = "Traffic/035-pedestrian-1.png";
const o_036_traffic_light = "Traffic/036-traffic-light.png";
const o_037_turn = "Traffic/037-turn.png";
const o_038_slippery = "Traffic/038-slippery.png";
const o_039_no_entry = "Traffic/039-no-entry.png";
const o_040_speed_limit = "Traffic/040-speed-limit.png";
const o_041_humps = "Traffic/041-humps.png";
const o_042_falling_rocks = "Traffic/042-falling-rocks.png";
const o_043_pedestrian = "Traffic/043-pedestrian.png";
const o_044_turn_right = "Traffic/044-turn-right.png";
const o_045_turn_left = "Traffic/045-turn-left.png";
const o_046_u_turn = "Traffic/046-u-turn.png";
const o_047_warning = "Traffic/047-warning.png";
const o_048_no_parking = "Traffic/048-no-parking.png";
const o_049_parking = "Traffic/049-parking.png";
const o_050_danger = "Traffic/050-danger.png";

// Marine

const o_001_pirate_3 = "Marine/001-pirate-3.png";
const o_001_whale = "Marine/001-whale.png";
const o_002_hook = "Marine/002-hook.png";
const o_002_lifesaver = "Marine/002-lifesaver.png";
const o_003_round_shot = "Marine/003-round-shot.png";
const o_003_sailor_cap_2 = "Marine/003-sailor-cap-2.png";
const o_004_anchor_2 = "Marine/004-anchor-2.png";
const o_004_starfish = "Marine/004-starfish.png";
const o_005_anchor_1 = "Marine/005-anchor-1.png";
const o_005_cannon = "Marine/005-cannon.png";
const o_006_dolphin = "Marine/006-dolphin.png";
const o_006_parrot = "Marine/006-parrot.png";
const o_007_island = "Marine/007-island.png";
const o_007_knot_1 = "Marine/007-knot-1.png";
const o_008_pirate_2 = "Marine/008-pirate-2.png";
const o_008_sailor_cap_1 = "Marine/008-sailor-cap-1.png";
const o_009_flag = "Marine/009-flag.png";
const o_009_key = "Marine/009-key.png";
const o_010_barrel = "Marine/010-barrel.png";
const o_010_seagull_1 = "Marine/010-seagull-1.png";
const o_011_boat = "Marine/011-boat.png";
const o_011_paddle = "Marine/011-paddle.png";
const o_012_pirate_ship = "Marine/012-pirate-ship.png";
const o_012_porthole = "Marine/012-porthole.png";
const o_013_bollard = "Marine/013-bollard.png";
const o_013_gold_ingots = "Marine/013-gold-ingots.png";
const o_014_lighthouse_1 = "Marine/014-lighthouse-1.png";
const o_014_placeholder = "Marine/014-placeholder.png";
const o_015_buoys = "Marine/015-buoys.png";
const o_015_skull_and_bones = "Marine/015-skull-and-bones.png";
const o_016_flippers = "Marine/016-flippers.png";
const o_016_sword = "Marine/016-sword.png";
const o_017_pirate_1 = "Marine/017-pirate-1.png";
const o_017_shirt = "Marine/017-shirt.png";
const o_018_chains = "Marine/018-chains.png";
const o_018_pipe = "Marine/018-pipe.png";
const o_019_eyepatch = "Marine/019-eyepatch.png";
const o_019_pipe_1 = "Marine/019-pipe-1.png";
const o_020_compass_2 = "Marine/020-compass-2.png";
const o_020_gallows = "Marine/020-gallows.png";
const o_021_coin = "Marine/021-coin.png";
const o_021_radar_1 = "Marine/021-radar-1.png";
const o_022_compass_1 = "Marine/022-compass-1.png";
const o_022_rum = "Marine/022-rum.png";
const o_023_money_bag = "Marine/023-money-bag.png";
const o_023_radar = "Marine/023-radar.png";
const o_024_diving_helmet = "Marine/024-diving-helmet.png";
const o_024_pirate = "Marine/024-pirate.png";
const o_025_bottle = "Marine/025-bottle.png";
const o_025_treasure_map = "Marine/025-treasure-map.png";
const o_026_captain = "Marine/026-captain.png";
const o_026_wooden_leg = "Marine/026-wooden-leg.png";
const o_027_helm = "Marine/027-helm.png";
const o_027_yatch = "Marine/027-yatch.png";
const o_028_message_in_a_bottle = "Marine/028-message-in-a-bottle.png";
const o_028_oars = "Marine/028-oars.png";
const o_029_lighthouse = "Marine/029-lighthouse.png";
const o_029_ship = "Marine/029-ship.png";
const o_030_binoculars = "Marine/030-binoculars.png";
const o_030_torch = "Marine/030-torch.png";
const o_031_jolly_roger = "Marine/031-jolly-roger.png";
const o_031_propeller = "Marine/031-propeller.png";
const o_032_bell = "Marine/032-bell.png";
const o_032_hook = "Marine/032-hook.png";
const o_033_dice = "Marine/033-dice.png";
const o_033_map = "Marine/033-map.png";
const o_034_sailor_cap = "Marine/034-sailor-cap.png";
const o_034_telescope = "Marine/034-telescope.png";
const o_035_knot = "Marine/035-knot.png";
const o_035_tortoise = "Marine/035-tortoise.png";
const o_036_letter = "Marine/036-letter.png";
const o_036_waves = "Marine/036-waves.png";
const o_037_anchor = "Marine/037-anchor.png";
const o_037_pipe = "Marine/037-pipe.png";
const o_038_compass = "Marine/038-compass.png";
const o_038_lifebuoy = "Marine/038-lifebuoy.png";
const o_039_bomb = "Marine/039-bomb.png";
const o_039_rudder_1 = "Marine/039-rudder-1.png";
const o_040_sailboat = "Marine/040-sailboat.png";
const o_040_treasure = "Marine/040-treasure.png";
const o_041_starfish = "Marine/041-starfish.png";
const o_042_lighthouse = "Marine/042-lighthouse.png";
const o_043_spyglass = "Marine/043-spyglass.png";
const o_044_fish = "Marine/044-fish.png";
const o_045_compass = "Marine/045-compass.png";
const o_046_anchor = "Marine/046-anchor.png";
const o_047_seagull = "Marine/047-seagull.png";
const o_048_rudder = "Marine/048-rudder.png";
const o_049_snorkel = "Marine/049-snorkel.png";
const o_050_life_jacket = "Marine/050-life-jacket.png";

// Emergencies

const o_001_air_pump = "Emergencies/001-air-pump.png";
const o_002_helicopter = "Emergencies/002-helicopter.png";
const o_003_defibrillator = "Emergencies/003-defibrillator.png";
const o_004_toolbox = "Emergencies/004-toolbox.png";
const o_005_smartphone = "Emergencies/005-smartphone.png";
const o_006_heart = "Emergencies/006-heart.png";
const o_007_hospital_2 = "Emergencies/007-hospital-2.png";
const o_008_tools = "Emergencies/008-tools.png";
const o_009_caution = "Emergencies/009-caution.png";
const o_010_hospital_1 = "Emergencies/010-hospital-1.png";
const o_011_pin = "Emergencies/011-pin.png";
const o_012_blood_transfusion = "Emergencies/012-blood-transfusion.png";
const o_013_firefighter_1 = "Emergencies/013-firefighter-1.png";
const o_014_barrier = "Emergencies/014-barrier.png";
const o_015_fire_truck = "Emergencies/015-fire-truck.png";
const o_016_police_badge = "Emergencies/016-police-badge.png";
const o_017_hospital = "Emergencies/017-hospital.png";
const o_018_bucket = "Emergencies/018-bucket.png";
const o_019_smoke_detector = "Emergencies/019-smoke-detector.png";
const o_020_shovel = "Emergencies/020-shovel.png";
const o_021_walkie_talkie = "Emergencies/021-walkie-talkie.png";
const o_022_firefighter = "Emergencies/022-firefighter.png";
const o_023_stretcher = "Emergencies/023-stretcher.png";
const o_024_lifebuoy = "Emergencies/024-lifebuoy.png";
const o_025_fire_extinguisher = "Emergencies/025-fire-extinguisher.png";
const o_026_warning = "Emergencies/026-warning.png";
const o_027_hydrant = "Emergencies/027-hydrant.png";
const o_028_matches = "Emergencies/028-matches.png";
const o_029_phone_call = "Emergencies/029-phone-call.png";
const o_030_life_jacket = "Emergencies/030-life-jacket.png";
const o_031_band_aid = "Emergencies/031-band-aid.png";
const o_032_lighter = "Emergencies/032-lighter.png";
const o_033_megaphone = "Emergencies/033-megaphone.png";
const o_034_sos = "Emergencies/034-sos.png";
const o_035_whistle = "Emergencies/035-whistle.png";
const o_036_flashlight = "Emergencies/036-flashlight.png";
const o_037_sprinkler = "Emergencies/037-sprinkler.png";
const o_038_fire_alarm_1 = "Emergencies/038-fire-alarm-1.png";
const o_039_gas_mask = "Emergencies/039-gas-mask.png";
const o_040_ambulance = "Emergencies/040-ambulance.png";
const o_041_emergency_call = "Emergencies/041-emergency-call.png";
const o_042_first_aid_kit = "Emergencies/042-first-aid-kit.png";
const o_043_fire = "Emergencies/043-fire.png";
const o_044_hose = "Emergencies/044-hose.png";
const o_045_exit = "Emergencies/045-exit.png";
const o_046_axe = "Emergencies/046-axe.png";
const o_047_cone = "Emergencies/047-cone.png";
const o_048_ladder = "Emergencies/048-ladder.png";
const o_049_siren = "Emergencies/049-siren.png";
const o_050_fire_alarm = "Emergencies/050-fire-alarm.png";

// Hospital

const ambulance = "Hospital/ambulance.png";
const band_aid = "Hospital/band-aid.png";
const bandage = "Hospital/bandage.png";
const blood_donation = "Hospital/blood-donation.png";
const blood_sample = "Hospital/blood-sample.png";
const blood_transfusion = "Hospital/blood-transfusion.png";
const broken_bone = "Hospital/broken-bone.png";
const cardiogram = "Hospital/cardiogram.png";
const clinic_history = "Hospital/clinic-history.png";
const coffee_cup = "Hospital/coffee-cup.png";
const crutches = "Hospital/crutches.png";
const dna = "Hospital/dna.png";
const dropper = "Hospital/dropper.png";
const electrocardiogram = "Hospital/electrocardiogram.png";
const eye = "Hospital/eye.png";
const eye_drops = "Hospital/eye-drops.png";
const first_aid_kit = "Hospital/first-aid-kit.png";
const flask = "Hospital/flask.png";
const hospital = "Hospital/hospital.png";
const hospital_1 = "Hospital/hospital-1.png";
const hospital_2 = "Hospital/hospital-2.png";
const hospital_bed = "Hospital/hospital-bed.png";
const id_card = "Hospital/id-card.png";
const mask = "Hospital/mask.png";
const medicine = "Hospital/medicine.png";
const medicine_1 = "Hospital/medicine-1.png";
const medicine_2 = "Hospital/medicine-2.png";
const medicine_3 = "Hospital/medicine-3.png";
const medicine_4 = "Hospital/medicine-4.png";
const microscope = "Hospital/microscope.png";
const optometry = "Hospital/optometry.png";
const pill = "Hospital/pill.png";
const pills = "Hospital/pills.png";
const pills_1 = "Hospital/pills-1.png";
const prescription = "Hospital/prescription.png";
const serum = "Hospital/serum.png";
const stethoscope = "Hospital/stethoscope.png";
const tag = "Hospital/tag.png";
const test_tube = "Hospital/test-tube.png";
const thermometer = "Hospital/thermometer.png";
const tooth = "Hospital/tooth.png";
const vaccine = "Hospital/vaccine.png";
const virus = "Hospital/virus.png";

// Safety

const o_001_fire = "Safety/001-fire.png";
const o_002_umbrella = "Safety/002-umbrella.png";
const o_003_shield_1 = "Safety/003-shield-1.png";
const o_004_lifebuoy = "Safety/004-lifebuoy.png";
const o_005_cone = "Safety/005-cone.png";
const o_006_eye = "Safety/006-eye.png";
const o_007_helmet = "Safety/007-helmet.png";
const o_008_safety_belt = "Safety/008-safety-belt.png";
const o_009_first_aid_kit = "Safety/009-first-aid-kit.png";
const o_010_barbed_wire = "Safety/010-barbed-wire.png";
const o_011_shield = "Safety/011-shield.png";
const o_012_cctv_1 = "Safety/012-cctv-1.png";
const o_013_cctv = "Safety/013-cctv.png";
const o_014_fire_alarm_1 = "Safety/014-fire-alarm-1.png";
const o_015_hose = "Safety/015-hose.png";
const o_016_hydrant = "Safety/016-hydrant.png";
const o_017_flasher = "Safety/017-flasher.png";
const o_018_kitchen_hood = "Safety/018-kitchen-hood.png";
const o_019_air_compressor = "Safety/019-air-compressor.png";
const o_020_gas_mask = "Safety/020-gas-mask.png";
const o_021_carbon_monoxide = "Safety/021-carbon-monoxide.png";
const o_022_extinguisher = "Safety/022-extinguisher.png";
const o_023_sprinkler = "Safety/023-sprinkler.png";
const o_024_pull = "Safety/024-pull.png";
const o_025_fire_alarm = "Safety/025-fire-alarm.png";
const o_026_smoke_detector = "Safety/026-smoke-detector.png";
const o_027_safety_code = "Safety/027-safety-code.png";
const o_028_signal = "Safety/028-signal.png";
const o_029_strongbox = "Safety/029-strongbox.png";
const o_030_password = "Safety/030-password.png";
const o_031_key = "Safety/031-key.png";
const o_032_keyhole = "Safety/032-keyhole.png";
const o_033_exit_1 = "Safety/033-exit-1.png";
const o_034_door = "Safety/034-door.png";
const o_035_padlock_1 = "Safety/035-padlock-1.png";
const o_036_padlock = "Safety/036-padlock.png";
const o_037_house = "Safety/037-house.png";
const o_038_exit = "Safety/038-exit.png";
const o_039_bell = "Safety/039-bell.png";
const o_040_stop = "Safety/040-stop.png";
const o_041_radiation = "Safety/041-radiation.png";
const o_042_forbidden = "Safety/042-forbidden.png";
const o_043_not_touch = "Safety/043-not-touch.png";
const o_044_no_smoking = "Safety/044-no-smoking.png";
const o_045_no_fire = "Safety/045-no-fire.png";
const o_046_high_voltage = "Safety/046-high-voltage.png";
const o_047_fire_sign = "Safety/047-fire-sign.png";
const o_048_warning = "Safety/048-warning.png";
const o_049_bomb = "Safety/049-bomb.png";
const o_050_antenna = "Safety/050-antenna.png";

// Military

const o_001_knife = "Military/001-knife.png";
const o_002_bomb_1 = "Military/002-bomb-1.png";
const o_003_radar = "Military/003-radar.png";
const o_004_medal_1 = "Military/004-medal-1.png";
const o_005_launcher_1 = "Military/005-launcher-1.png";
const o_006_helmet = "Military/006-helmet.png";
const o_007_chamber = "Military/007-chamber.png";
const o_008_helicopter = "Military/008-helicopter.png";
const o_009_soldier = "Military/009-soldier.png";
const o_010_lieutenant = "Military/010-lieutenant.png";
const o_011_shotgun = "Military/011-shotgun.png";
const o_012_backpack = "Military/012-backpack.png";
const o_013_bomb_detonation_1 = "Military/013-bomb-detonation-1.png";
const o_014_bomb_detonation = "Military/014-bomb-detonation.png";
const o_015_fighter_plane = "Military/015-fighter-plane.png";
const o_016_dynamite = "Military/016-dynamite.png";
const o_017_first_aid_kit_1 = "Military/017-first-aid-kit-1.png";
const o_018_boat = "Military/018-boat.png";
const o_019_submarine = "Military/019-submarine.png";
const o_020_compass = "Military/020-compass.png";
const o_021_insignia = "Military/021-insignia.png";
const o_022_first_aid_kit = "Military/022-first-aid-kit.png";
const o_023_placeholder = "Military/023-placeholder.png";
const o_024_jet = "Military/024-jet.png";
const o_025_flag = "Military/025-flag.png";
const o_026_boots = "Military/026-boots.png";
const o_027_gas_mask = "Military/027-gas-mask.png";
const o_028_bullet_1 = "Military/028-bullet-1.png";
const o_029_launcher = "Military/029-launcher.png";
const o_030_satellite_dish = "Military/030-satellite-dish.png";
const o_031_parachute = "Military/031-parachute.png";
const o_032_gun = "Military/032-gun.png";
const o_033_bulletproof = "Military/033-bulletproof.png";
const o_034_swiss_army_knife = "Military/034-swiss-army-knife.png";
const o_035_bullet = "Military/035-bullet.png";
const o_036_binoculars = "Military/036-binoculars.png";
const o_037_target = "Military/037-target.png";
const o_038_walkie_talkie = "Military/038-walkie-talkie.png";
const o_039_rank = "Military/039-rank.png";
const o_040_barbed = "Military/040-barbed.png";
const o_041_bomb = "Military/041-bomb.png";
const o_042_radiation = "Military/042-radiation.png";
const o_043_chevron = "Military/043-chevron.png";
const o_044_map = "Military/044-map.png";
const o_045_medal = "Military/045-medal.png";
const o_046_tank = "Military/046-tank.png";
const o_047_tent = "Military/047-tent.png";
const o_048_dog_tags_military = "Military/048-dog-tags-military.png";
const o_049_grenade = "Military/049-grenade.png";
const o_050_badge = "Military/050-badge.png";

// Office
const adhesive_tape = "Office/adhesive-tape.png";
const bookshelf = "Office/bookshelf.png";
const briefcase = "Office/briefcase.png";
const business_card = "Office/business-card.png";
const cabinet = "Office/cabinet.png";
const computer = "Office/computer.png";
const copy_machine = "Office/copy-machine.png";
const documents = "Office/documents.png";
const fax = "Office/fax.png";
const folder = "Office/folder.png";
const folder_1 = "Office/folder-1.png";
const folder_2 = "Office/folder-2.png";
const garbage = "Office/garbage.png";
const headphones = "Office/headphones.png";
const highlighter = "Office/highlighter.png";
const hole_puncher = "Office/hole-puncher.png";
const lamp = "Office/lamp.png";
const letters = "Office/letters.png";
const mail = "Office/mail.png";
const mouse = "Office/mouse.png";
const mug = "Office/mug.png";
const note = "Office/note.png";
const notebook = "Office/notebook.png";
const notebook_1 = "Office/notebook-1.png";
const office_chair = "Office/office-chair.png";
const paper_shredder = "Office/paper-shredder.png";
const pen = "Office/pen.png";
const pencil_case = "Office/pencil-case.png";
const pendrive = "Office/pendrive.png";
const plant = "Office/plant.png";
const presentation = "Office/presentation.png";
const printer = "Office/printer.png";
const projector = "Office/projector.png";
const router = "Office/router.png";
const rubber_stamp = "Office/rubber-stamp.png";
const stapler = "Office/stapler.png";
const table = "Office/table.png";
const telephone = "Office/telephone.png";
const uniform = "Office/uniform.png";
const webcam = "Office/webcam.png";

// Energy all duplicate

// Family
const o_001_couple_3 = "Family/001-couple-3.png";
const o_002_house = "Family/002-house.png";
const o_003_stroller = "Family/003-stroller.png";
const o_004_son = "Family/004-son.png";
const o_005_suitcase = "Family/005-suitcase.png";
const o_006_family_2 = "Family/006-family-2.png";
const o_007_pet_house = "Family/007-pet-house.png";
const o_008_cups = "Family/008-cups.png";
const o_009_photo_album = "Family/009-photo-album.png";
const o_010_fireplace = "Family/010-fireplace.png";
const o_012_insurance = "Family/012-insurance.png";
const o_013_swing = "Family/013-swing.png";
const o_014_sofa = "Family/014-sofa.png";
const o_015_couple_2 = "Family/015-couple-2.png";
const o_016_home_1 = "Family/016-home-1.png";
const o_017_daughter = "Family/017-daughter.png";
const o_018_gift_1 = "Family/018-gift-1.png";
const o_019_shopping_basket = "Family/019-shopping-basket.png";
const o_020_television = "Family/020-television.png";
const o_021_divorce = "Family/021-divorce.png";
const o_022_picture_1 = "Family/022-picture-1.png";
const o_023_briefcase = "Family/023-briefcase.png";
const o_024_graduate = "Family/024-graduate.png";
const o_025_pacifier = "Family/025-pacifier.png";
const o_026_beach_ball = "Family/026-beach-ball.png";
const o_027_love = "Family/027-love.png";
const o_028_couple_1 = "Family/028-couple-1.png";
const o_029_couple = "Family/029-couple.png";
const o_030_scooter = "Family/030-scooter.png";
const o_031_gift = "Family/031-gift.png";
const o_032_picture = "Family/032-picture.png";
const o_033_wedding_rings = "Family/033-wedding-rings.png";
const o_034_wedding_cake = "Family/034-wedding-cake.png";
const o_035_newlyweds = "Family/035-newlyweds.png";
const o_036_grave = "Family/036-grave.png";
const o_037_crib = "Family/037-crib.png";
const o_038_cat = "Family/038-cat.png";
const o_039_father = "Family/039-father.png";
const o_040_dog = "Family/040-dog.png";
const o_041_care = "Family/041-care.png";
const o_042_family_1 = "Family/042-family-1.png";
const o_043_grandparents = "Family/043-grandparents.png";
const o_044_family = "Family/044-family.png";
const o_045_home = "Family/045-home.png";
const o_046_motherhood = "Family/046-motherhood.png";
const o_047_grandfather = "Family/047-grandfather.png";
const o_048_grandmother = "Family/048-grandmother.png";
const o_049_newborn = "Family/049-newborn.png";
const o_050_mother = "Family/050-mother.png";

// Home

const alarm_clock = "Home/alarm-clock.png";
const alcoholic = "Home/alcoholic.png";
const alcoholic_1 = "Home/alcoholic-1.png";
const bake = "Home/bake.png";
const bathing = "Home/bathing.png";
const bedroom = "Home/bedroom.png";
const bedroom_1 = "Home/bedroom-1.png";
const bedroom_2 = "Home/bedroom-2.png";
const bedroom_3 = "Home/bedroom-3.png";
const bedroom_4 = "Home/bedroom-4.png";
const bedroom_5 = "Home/bedroom-5.png";
const bedroom_6 = "Home/bedroom-6.png";
const bookcase = "Home/bookcase.png";
const carpet = "Home/carpet.png";
const chairs = "Home/chairs.png";
const chairs_1 = "Home/chairs-1.png";
const chairs_2 = "Home/chairs-2.png";
const chinese_food = "Home/chinese-food.png";
const coffee_cup_1 = "Home/coffee-cup-1.png";
const coffee_cup_2 = "Home/coffee-cup-2.png";
const coffee_shop = "Home/coffee-shop.png";
const coffees = "Home/coffees.png";
const comfortable = "Home/comfortable.png";
const comfortable_1 = "Home/comfortable-1.png";
const coolnes = "Home/coolnes.png";
const couch = "Home/couch.png";
const door_key = "Home/door-key.png";
const drinking = "Home/drinking.png";
const elegant = "Home/elegant.png";
const firefighting = "Home/firefighting.png";
const fry = "Home/fry.png";
const game_console = "Home/game-console.png";
const garbage_bin = "Home/garbage-bin.png";
const gardening = "Home/gardening.png";
const hair_salon = "Home/hair-salon.png";
const hangers = "Home/hangers.png";
const home_repair = "Home/home-repair.png";
const hot_drink = "Home/hot-drink.png";
const hygienic = "Home/hygienic.png";
const hygienic_1 = "Home/hygienic-1.png";
const invention = "Home/invention.png";
const japanese_food = "Home/japanese-food.png";
const kitchen_pack = "Home/kitchen-pack.png";
const kitchen_pack_1 = "Home/kitchen-pack-1.png";
const kitchen_pack_2 = "Home/kitchen-pack-2.png";
const kitchen_pack_3 = "Home/kitchen-pack-3.png";
const kitchen_pack_4 = "Home/kitchen-pack-4.png";
const kitchen_pack_5 = "Home/kitchen-pack-5.png";
const kitchen_pack_6 = "Home/kitchen-pack-6.png";
const kitchen_pack_7 = "Home/kitchen-pack-7.png";
const kitchen_pack_8 = "Home/kitchen-pack-8.png";
const kitchen_pack_9 = "Home/kitchen-pack-9.png";
const kitchen_pack_10 = "Home/kitchen-pack-10.png";
const kitchen_pack_11 = "Home/kitchen-pack-11.png";
const kitchen_pack_12 = "Home/kitchen-pack-12.png";
const kitchen_pack_13 = "Home/kitchen-pack-13.png";
const kitchen_pack_14 = "Home/kitchen-pack-14.png";
const kitchen_pack_15 = "Home/kitchen-pack-15.png";
const kitchen_pack_16 = "Home/kitchen-pack-16.png";
const kitchen_tools = "Home/kitchen-tools.png";
const laptop_computer = "Home/laptop-computer.png";
const livingroom = "Home/livingroom.png";
const livingroom_1 = "Home/livingroom-1.png";
const livingroom_2 = "Home/livingroom-2.png";
const passkey = "Home/passkey.png";
const phone_call = "Home/phone-call.png";
const phone_receiver = "Home/phone-receiver.png";
const plugging = "Home/plugging.png";
const plugging_1 = "Home/plugging-1.png";
const protein = "Home/protein.png";
const real_estate = "Home/real-estate.png";
const real_estate_1 = "Home/real-estate-1.png";
const real_estate_2 = "Home/real-estate-2.png";
const real_estate_3 = "Home/real-estate-3.png";
const reflecting = "Home/reflecting.png";
const refreshment = "Home/refreshment.png";
const restauran = "Home/restauran.png";
const restroom = "Home/restroom.png";
const seat = "Home/seat.png";
const seatting = "Home/seatting.png";
const showers = "Home/showers.png";
const sinks = "Home/sinks.png";
const sleepy = "Home/sleepy.png";
const studying = "Home/studying.png";
const studying_1 = "Home/studying-1.png";
const studying_2 = "Home/studying-2.png";
const supper = "Home/supper.png";
const technological = "Home/technological.png";
const televisions = "Home/televisions.png";
const televisions_1 = "Home/televisions-1.png";
const ventilator = "Home/ventilator.png";
const wooden_chair = "Home/wooden-chair.png";
const yard = "Home/yard.png";

// Multimedia

const c_3d_glasses = "Multimedia/3d-glasses.png";
const antenna = "Multimedia/antenna.png";
const camera = "Multimedia/camera.png";
const camera_1 = "Multimedia/camera-1.png";
const camera_2 = "Multimedia/camera-2.png";
const camera_3 = "Multimedia/camera-3.png";
const cd = "Multimedia/cd.png";
const chat = "Multimedia/chat.png";
const clapperboard = "Multimedia/clapperboard.png";
const cloud = "Multimedia/cloud.png";
const dvd = "Multimedia/dvd.png";
const equalizer = "Multimedia/equalizer.png";
const film = "Multimedia/film.png";
const floppy_disk = "Multimedia/floppy-disk.png";
const game = "Multimedia/game.png";
const ipod = "Multimedia/ipod.png";
const ipod_shuffle = "Multimedia/ipod-shuffle.png";
const keyboard = "Multimedia/keyboard.png";
const loudspeaker = "Multimedia/loudspeaker.png";
const loudspeaker_1 = "Multimedia/loudspeaker-1.png";
const memory_card = "Multimedia/memory-card.png";
const microphone = "Multimedia/microphone.png";
const microphone_1 = "Multimedia/microphone-1.png";
const mobile_phone = "Multimedia/mobile-phone.png";
const modem = "Multimedia/modem.png";
const negative = "Multimedia/negative.png";
const negative_film = "Multimedia/negative-film.png";
const next_track = "Multimedia/next-track.png";
const pause = "Multimedia/pause.png";
const photos = "Multimedia/photos.png";
const play = "Multimedia/play.png";
const previous_track = "Multimedia/previous-track.png";
const radio = "Multimedia/radio.png";
const rec = "Multimedia/rec.png";
const speaker = "Multimedia/speaker.png";
const stop = "Multimedia/stop.png";
const tablet = "Multimedia/tablet.png";
const tv = "Multimedia/tv.png";
const tv_1 = "Multimedia/tv-1.png";
const web_cam = "Multimedia/web-cam.png";

// Arrows

const o_093_chevron = "Arrows/093-chevron.png";
const o_093_chevron_1 = "Arrows/093-chevron-1.png";
const o_093_chevron_2 = "Arrows/093-chevron-2.png";
const o_093_chevron_3 = "Arrows/093-chevron-3.png";
const o_093_chevron_4 = "Arrows/093-chevron-4.png";
const o_093_chevron_5 = "Arrows/093-chevron-5.png";
const o_093_chevron_6 = "Arrows/093-chevron-6.png";
const o_093_chevron_7 = "Arrows/093-chevron-7.png";
const o_093_chevron_8 = "Arrows/093-chevron-8.png";
const o_093_chevron_9 = "Arrows/093-chevron-9.png";
const o_093_chevron_10 = "Arrows/093-chevron-10.png";
const o_093_chevron_11 = "Arrows/093-chevron-11.png";
const o_093_compress = "Arrows/093-compress.png";
const o_093_compress_1 = "Arrows/093-compress-1.png";
const o_093_curved_arrow = "Arrows/093-curved-arrow.png";
const o_093_curved_arrow_1 = "Arrows/093-curved-arrow-1.png";
const o_093_curved_arrow_2 = "Arrows/093-curved-arrow-2.png";
const o_093_curved_arrow_3 = "Arrows/093-curved-arrow-3.png";
const o_093_diagonal_arrow = "Arrows/093-diagonal-arrow.png";
const o_093_diagonal_arrow_1 = "Arrows/093-diagonal-arrow-1.png";
const o_093_diagonal_arrow_2 = "Arrows/093-diagonal-arrow-2.png";
const o_093_diagonal_arrow_3 = "Arrows/093-diagonal-arrow-3.png";
const o_093_diagonal_arrow_4 = "Arrows/093-diagonal-arrow-4.png";
const o_093_diagonal_arrow_5 = "Arrows/093-diagonal-arrow-5.png";
const o_093_diagonal_arrow_6 = "Arrows/093-diagonal-arrow-6.png";
const o_093_diagonal_arrow_7 = "Arrows/093-diagonal-arrow-7.png";
const o_093_diagonal_arrow_8 = "Arrows/093-diagonal-arrow-8.png";
const o_093_diagonal_arrow_9 = "Arrows/093-diagonal-arrow-9.png";
const o_093_diagonal_arrow_10 = "Arrows/093-diagonal-arrow-10.png";
const o_093_diagonal_arrow_11 = "Arrows/093-diagonal-arrow-11.png";
const o_093_diagonal_arrow_12 = "Arrows/093-diagonal-arrow-12.png";
const o_093_diagonal_arrow_13 = "Arrows/093-diagonal-arrow-13.png";
const o_093_diagonal_arrow_14 = "Arrows/093-diagonal-arrow-14.png";
const o_093_diagonal_arrow_15 = "Arrows/093-diagonal-arrow-15.png";
const o_093_down_arrow = "Arrows/093-down-arrow.png";
const o_093_down_arrow_1 = "Arrows/093-down-arrow-1.png";
const o_093_down_arrow_2 = "Arrows/093-down-arrow-2.png";
const o_093_down_arrow_3 = "Arrows/093-down-arrow-3.png";
const o_093_down_arrow_4 = "Arrows/093-down-arrow-4.png";
const o_093_down_arrow_5 = "Arrows/093-down-arrow-5.png";
const o_093_exchange = "Arrows/093-exchange.png";
const o_093_exchange_1 = "Arrows/093-exchange-1.png";
const o_093_expand = "Arrows/093-expand.png";
const o_093_expand_1 = "Arrows/093-expand-1.png";
const o_093_expand_2 = "Arrows/093-expand-2.png";
const o_093_expand_3 = "Arrows/093-expand-3.png";
const o_093_left_arrow = "Arrows/093-left-arrow.png";
const o_093_left_arrow_1 = "Arrows/093-left-arrow-1.png";
const o_093_left_arrow_2 = "Arrows/093-left-arrow-2.png";
const o_093_left_arrow_3 = "Arrows/093-left-arrow-3.png";
const o_093_left_arrow_4 = "Arrows/093-left-arrow-4.png";
const o_093_left_arrow_5 = "Arrows/093-left-arrow-5.png";
const o_093_left_arrow_6 = "Arrows/093-left-arrow-6.png";
const o_093_move = "Arrows/093-move.png";
const o_093_move_1 = "Arrows/093-move-1.png";
const o_093_redo = "Arrows/093-redo.png";
const o_093_redo_1 = "Arrows/093-redo-1.png";
const o_093_resize = "Arrows/093-resize.png";
const o_093_resize_1 = "Arrows/093-resize-1.png";
const o_093_resize_2 = "Arrows/093-resize-2.png";
const o_093_resize_3 = "Arrows/093-resize-3.png";
const o_093_right_arrow = "Arrows/093-right-arrow.png";
const o_093_right_arrow_1 = "Arrows/093-right-arrow-1.png";
const o_093_right_arrow_2 = "Arrows/093-right-arrow-2.png";
const o_093_right_arrow_3 = "Arrows/093-right-arrow-3.png";
const o_093_right_arrow_4 = "Arrows/093-right-arrow-4.png";
const o_093_right_arrow_5 = "Arrows/093-right-arrow-5.png";
const o_093_right_arrow_6 = "Arrows/093-right-arrow-6.png";
const o_093_right_arrow_7 = "Arrows/093-right-arrow-7.png";
const o_093_scroll = "Arrows/093-scroll.png";
const o_093_scroll_1 = "Arrows/093-scroll-1.png";
const o_093_sort = "Arrows/093-sort.png";
const o_093_sort_1 = "Arrows/093-sort-1.png";
const o_093_sort_2 = "Arrows/093-sort-2.png";
const o_093_sort_3 = "Arrows/093-sort-3.png";
const o_093_turn_left = "Arrows/093-turn-left.png";
const o_093_turn_left_1 = "Arrows/093-turn-left-1.png";
const o_093_turn_left_2 = "Arrows/093-turn-left-2.png";
const o_093_turn_left_3 = "Arrows/093-turn-left-3.png";
const o_093_turn_right = "Arrows/093-turn-right.png";
const o_093_turn_right_1 = "Arrows/093-turn-right-1.png";
const o_093_turn_right_2 = "Arrows/093-turn-right-2.png";
const o_093_turn_right_3 = "Arrows/093-turn-right-3.png";
const o_093_undo = "Arrows/093-undo.png";
const o_093_undo_1 = "Arrows/093-undo-1.png";
const o_093_up_arrow = "Arrows/093-up-arrow.png";
const o_093_up_arrow_1 = "Arrows/093-up-arrow-1.png";
const o_093_up_arrow_2 = "Arrows/093-up-arrow-2.png";
const o_093_up_arrow_3 = "Arrows/093-up-arrow-3.png";
const o_093_up_arrow_4 = "Arrows/093-up-arrow-4.png";
const o_093_up_arrow_5 = "Arrows/093-up-arrow-5.png";
const o_093_up_arrow_6 = "Arrows/093-up-arrow-6.png";

export const Arrows = [
  o_093_chevron,
  o_093_chevron_1,
  o_093_chevron_2,
  o_093_chevron_3,
  o_093_chevron_4,
  o_093_chevron_5,
  o_093_chevron_6,
  o_093_chevron_7,
  o_093_chevron_8,
  o_093_chevron_9,
  o_093_chevron_10,
  o_093_chevron_11,
  o_093_compress,
  o_093_compress_1,
  o_093_curved_arrow,
  o_093_curved_arrow_1,
  o_093_curved_arrow_2,
  o_093_curved_arrow_3,
  o_093_diagonal_arrow,
  o_093_diagonal_arrow_1,
  o_093_diagonal_arrow_2,
  o_093_diagonal_arrow_3,
  o_093_diagonal_arrow_4,
  o_093_diagonal_arrow_5,
  o_093_diagonal_arrow_6,
  o_093_diagonal_arrow_7,
  o_093_diagonal_arrow_8,
  o_093_diagonal_arrow_9,
  o_093_diagonal_arrow_10,
  o_093_diagonal_arrow_11,
  o_093_diagonal_arrow_12,
  o_093_diagonal_arrow_13,
  o_093_diagonal_arrow_14,
  o_093_diagonal_arrow_15,
  o_093_down_arrow,
  o_093_down_arrow_1,
  o_093_down_arrow_2,
  o_093_down_arrow_3,
  o_093_down_arrow_4,
  o_093_down_arrow_5,
  o_093_exchange,
  o_093_exchange_1,
  o_093_expand,
  o_093_expand_1,
  o_093_expand_2,
  o_093_expand_3,
  o_093_left_arrow,
  o_093_left_arrow_1,
  o_093_left_arrow_2,
  o_093_left_arrow_3,
  o_093_left_arrow_4,
  o_093_left_arrow_5,
  o_093_left_arrow_6,
  o_093_move,
  o_093_move_1,
  o_093_redo,
  o_093_redo_1,
  o_093_resize,
  o_093_resize_1,
  o_093_resize_2,
  o_093_resize_3,
  o_093_right_arrow,
  o_093_right_arrow_1,
  o_093_right_arrow_2,
  o_093_right_arrow_3,
  o_093_right_arrow_4,
  o_093_right_arrow_5,
  o_093_right_arrow_6,
  o_093_right_arrow_7,
  o_093_scroll,
  o_093_scroll_1,
  o_093_sort,
  o_093_sort_1,
  o_093_sort_2,
  o_093_sort_3,
  o_093_turn_left,
  o_093_turn_left_1,
  o_093_turn_left_2,
  o_093_turn_left_3,
  o_093_turn_right,
  o_093_turn_right_1,
  o_093_turn_right_2,
  o_093_turn_right_3,
  o_093_undo,
  o_093_undo_1,
  o_093_up_arrow,
  o_093_up_arrow_1,
  o_093_up_arrow_2,
  o_093_up_arrow_3,
  o_093_up_arrow_4,
  o_093_up_arrow_5,
  o_093_up_arrow_6,
]

export const Multimedia = [
  c_3d_glasses,
  antenna,
  camera,
  camera_1,
  camera_2,
  camera_3,
  cd,
  chat,
  clapperboard,
  cloud,
  compact_disc,
  dvd,
  equalizer,
  film,
  floppy_disk,
  game,
  ipod,
  ipod_shuffle,
  keyboard,
  loudspeaker,
  loudspeaker_1,
  memory_card,
  microphone,
  microphone_1,
  mobile_phone,
  modem,
  negative,
  negative_film,
  next_track,
  pause,
  photos,
  play,
  previous_track,
  radio,
  rec,
  speaker,
  stop,
  tablet,
  tv,
  tv_1,
  web_cam
];
export const Home = [
  alarm_clock,
  alcoholic,
  alcoholic_1,
  bake,
  bathing,
  bedroom,
  bedroom_1,
  bedroom_2,
  bedroom_3,
  bedroom_4,
  bedroom_5,
  bedroom_6,
  bookcase,
  carpenter,
  carpet,
  chairs,
  chairs_1,
  chairs_2,
  chinese_food,
  coffee_cup_1,
  coffee_cup_2,
  coffee_shop,
  coffees,
  comfortable,
  comfortable_1,
  coolnes,
  couch,
  door_key,
  drinking,
  elegant,
  firefighting,
  fry,
  game_console,
  garbage_bin,
  gardener,
  gardening,
  groceries,
  hair_salon,
  hangers,
  home_repair,
  hot_drink,
  hygienic,
  hygienic_1,
  invention,
  japanese_food,
  kitchen_pack,
  kitchen_pack_1,
  kitchen_pack_2,
  kitchen_pack_3,
  kitchen_pack_4,
  kitchen_pack_5,
  kitchen_pack_6,
  kitchen_pack_7,
  kitchen_pack_8,
  kitchen_pack_9,
  kitchen_pack_10,
  kitchen_pack_11,
  kitchen_pack_12,
  kitchen_pack_13,
  kitchen_pack_14,
  kitchen_pack_15,
  kitchen_pack_16,
  kitchen_tools,
  laptop_computer,
  livingroom,
  livingroom_1,
  livingroom_2,
  maid,
  painter,
  passkey,
  phone_call,
  phone_receiver,
  plugging,
  plugging_1,
  protein,
  real_estate,
  real_estate_1,
  real_estate_2,
  real_estate_3,
  reflecting,
  refreshment,
  restauran,
  restroom,
  seat,
  seatting,
  showers,
  sinks,
  sleepy,
  studying,
  studying_1,
  studying_2,
  supper,
  technological,
  televisions,
  televisions_1,
  ventilator,
  wooden_chair,
  yard
];

export const Family = [
  o_001_couple_3,
  o_002_house,
  o_003_stroller,
  o_004_son,
  o_005_suitcase,
  o_006_family_2,
  o_007_pet_house,
  o_008_cups,
  o_009_photo_album,
  o_010_fireplace,
  o_011_pin,
  o_012_insurance,
  o_013_swing,
  o_014_sofa,
  o_015_couple_2,
  o_016_home_1,
  o_017_daughter,
  o_018_gift_1,
  o_019_shopping_basket,
  o_020_television,
  o_021_divorce,
  o_022_picture_1,
  o_023_briefcase,
  o_024_graduate,
  o_025_pacifier,
  o_026_beach_ball,
  o_027_love,
  o_028_couple_1,
  o_029_couple,
  o_030_scooter,
  o_031_gift,
  o_032_picture,
  o_033_wedding_rings,
  o_034_wedding_cake,
  o_035_newlyweds,
  o_036_grave,
  o_037_crib,
  o_038_cat,
  o_039_father,
  o_040_dog,
  o_041_care,
  o_042_family_1,
  o_043_grandparents,
  o_044_family,
  o_045_home,
  o_046_motherhood,
  o_047_grandfather,
  o_048_grandmother,
  o_049_newborn,
  o_050_mother
];

export const Energy = [
  atomic,
  barrels,
  battery,
  battery_1,
  charging,
  coal,
  ecology,
  electric_car,
  electric_tower,
  eolic_energy,
  factory,
  faucet,
  gas,
  gas_station,
  house,
  hydro_power,
  light_bulb,
  low_battery,
  oil_platform,
  plug,
  power_button,
  power_plant,
  rain,
  recycle,
  renewable_energy,
  renewable_energy_1,
  save_energy,
  socket,
  solar_energy,
  usb_cable
];

export const Office = [
  adhesive_tape,
  bookshelf,
  box,
  briefcase,
  business_card,
  cabinet,
  calculator,
  calendar,
  clipboard,
  clock,
  coffee_cup,
  computer,
  copy_machine,
  documents,
  fax,
  folder,
  folder_1,
  folder_2,
  garbage,
  headphones,
  highlighter,
  hole_puncher,
  id_card,
  lamp,
  laptop,
  letters,
  mail,
  mouse,
  mug,
  note,
  notebook,
  notebook_1,
  office_chair,
  paper_shredder,
  pen,
  pencil_case,
  pendrive,
  plant,
  presentation,
  printer,
  projector,
  push_pin,
  router,
  rubber_stamp,
  safebox,
  stapler,
  table,
  telephone,
  uniform,
  webcam
];

export const Military = [
  o_001_knife,
  o_002_bomb_1,
  o_003_radar,
  o_004_medal_1,
  o_005_launcher_1,
  o_006_helmet,
  o_007_chamber,
  o_008_helicopter,
  o_009_soldier,
  o_010_lieutenant,
  o_011_shotgun,
  o_012_backpack,
  o_013_bomb_detonation_1,
  o_014_bomb_detonation,
  o_015_fighter_plane,
  o_016_dynamite,
  o_017_first_aid_kit_1,
  o_018_boat,
  o_019_submarine,
  o_020_compass,
  o_021_insignia,
  o_022_first_aid_kit,
  o_023_placeholder,
  o_024_jet,
  o_025_flag,
  o_026_boots,
  o_027_gas_mask,
  o_028_bullet_1,
  o_029_launcher,
  o_030_satellite_dish,
  o_031_parachute,
  o_032_gun,
  o_033_bulletproof,
  o_034_swiss_army_knife,
  o_035_bullet,
  o_036_binoculars,
  o_037_target,
  o_038_walkie_talkie,
  o_039_rank,
  o_040_barbed,
  o_041_bomb,
  o_042_radiation,
  o_043_chevron,
  o_044_map,
  o_045_medal,
  o_046_tank,
  o_047_tent,
  o_048_dog_tags_military,
  o_049_grenade,
  o_050_badge
];
export const Safety = [
  o_001_fire,
  o_002_umbrella,
  o_003_shield_1,
  o_004_lifebuoy,
  o_005_cone,
  o_006_eye,
  o_007_helmet,
  o_008_safety_belt,
  o_009_first_aid_kit,
  o_010_barbed_wire,
  o_011_shield,
  o_012_cctv_1,
  o_013_cctv,
  o_014_fire_alarm_1,
  o_015_hose,
  o_016_hydrant,
  o_017_flasher,
  o_018_kitchen_hood,
  o_019_air_compressor,
  o_020_gas_mask,
  o_021_carbon_monoxide,
  o_022_extinguisher,
  o_023_sprinkler,
  o_024_pull,
  o_025_fire_alarm,
  o_026_smoke_detector,
  o_027_safety_code,
  o_028_signal,
  o_029_strongbox,
  o_030_password,
  o_031_key,
  o_032_keyhole,
  o_033_exit_1,
  o_034_door,
  o_035_padlock_1,
  o_036_padlock,
  o_037_house,
  o_038_exit,
  o_039_bell,
  o_040_stop,
  o_041_radiation,
  o_042_forbidden,
  o_043_not_touch,
  o_044_no_smoking,
  o_045_no_fire,
  o_046_high_voltage,
  o_047_fire_sign,
  o_048_warning,
  o_049_bomb,
  o_050_antenna
];

export const Hospital = [
  ambulance,
  band_aid,
  bandage,
  blood_donation,
  blood_sample,
  blood_transfusion,
  broken_bone,
  cardiogram,
  clinic_history,
  coffee_cup,
  crutches,
  dna,
  doctor,
  dropper,
  electrocardiogram,
  eye,
  eye_drops,
  first_aid_kit,
  flask,
  hospital,
  hospital_1,
  hospital_2,
  hospital_bed,
  id_card,
  laptop,
  mask,
  medicine,
  medicine_1,
  medicine_2,
  medicine_3,
  medicine_4,
  microscope,
  nurse,
  optometry,
  pill,
  pills,
  pills_1,
  placeholder,
  prescription,
  serum,
  shirt,
  smartphone,
  stethoscope,
  tag,
  test_tube,
  thermometer,
  tooth,
  vaccine,
  virus,
  weight
];

export const Emergencies = [
  o_001_air_pump,
  o_002_helicopter,
  o_003_defibrillator,
  o_004_toolbox,
  o_005_smartphone,
  o_006_heart,
  o_007_hospital_2,
  o_008_tools,
  o_009_caution,
  o_010_hospital_1,
  o_011_pin,
  o_012_blood_transfusion,
  o_013_firefighter_1,
  o_014_barrier,
  o_015_fire_truck,
  o_016_police_badge,
  o_017_hospital,
  o_018_bucket,
  o_019_smoke_detector,
  o_020_shovel,
  o_021_walkie_talkie,
  o_022_firefighter,
  o_023_stretcher,
  o_024_lifebuoy,
  o_025_fire_extinguisher,
  o_026_warning,
  o_027_hydrant,
  o_028_matches,
  o_029_phone_call,
  o_030_life_jacket,
  o_031_band_aid,
  o_032_lighter,
  o_033_megaphone,
  o_034_sos,
  o_035_whistle,
  o_036_flashlight,
  o_037_sprinkler,
  o_038_fire_alarm_1,
  o_039_gas_mask,
  o_040_ambulance,
  o_041_emergency_call,
  o_042_first_aid_kit,
  o_043_fire,
  o_044_hose,
  o_045_exit,
  o_046_axe,
  o_047_cone,
  o_048_ladder,
  o_049_siren,
  o_050_fire_alarm
];

export const Marine = [
  o_001_pirate_3,
  o_001_whale,
  o_002_hook,
  o_002_lifesaver,
  o_003_round_shot,
  o_003_sailor_cap_2,
  o_004_anchor_2,
  o_004_starfish,
  o_005_anchor_1,
  o_005_cannon,
  o_006_dolphin,
  o_006_parrot,
  o_007_island,
  o_007_knot_1,
  o_008_pirate_2,
  o_008_sailor_cap_1,
  o_009_flag,
  o_009_key,
  o_010_barrel,
  o_010_seagull_1,
  o_011_boat,
  o_011_paddle,
  o_012_pirate_ship,
  o_012_porthole,
  o_013_bollard,
  o_013_gold_ingots,
  o_014_lighthouse_1,
  o_014_placeholder,
  o_015_buoys,
  o_015_skull_and_bones,
  o_016_flippers,
  o_016_sword,
  o_017_pirate_1,
  o_017_shirt,
  o_018_chains,
  o_018_pipe,
  o_019_eyepatch,
  o_019_pipe_1,
  o_020_compass_2,
  o_020_gallows,
  o_021_coin,
  o_021_radar_1,
  o_022_compass_1,
  o_022_rum,
  o_023_money_bag,
  o_023_radar,
  o_024_diving_helmet,
  o_024_pirate,
  o_025_bottle,
  o_025_treasure_map,
  o_026_captain,
  o_026_wooden_leg,
  o_027_helm,
  o_027_yatch,
  o_028_message_in_a_bottle,
  o_028_oars,
  o_029_lighthouse,
  o_029_ship,
  o_030_binoculars,
  o_030_torch,
  o_031_jolly_roger,
  o_031_propeller,
  o_032_bell,
  o_032_hook,
  o_033_dice,
  o_033_map,
  o_034_sailor_cap,
  o_034_telescope,
  o_035_knot,
  o_035_tortoise,
  o_036_letter,
  o_036_waves,
  o_037_anchor,
  o_037_pipe,
  o_038_compass,
  o_038_lifebuoy,
  o_039_bomb,
  o_039_rudder_1,
  o_040_sailboat,
  o_040_treasure,
  o_041_starfish,
  o_042_lighthouse,
  o_043_spyglass,
  o_044_fish,
  o_045_compass,
  o_046_anchor,
  o_047_seagull,
  o_048_rudder,
  o_049_snorkel,
  o_050_life_jacket
];

export const Traffic = [
  o_001_highway_1,
  o_002_two_ways,
  o_003_road_sign,
  o_004_barrier,
  o_005_right_arrow,
  o_006_roundabout,
  o_007_prohibition,
  o_008_curves,
  o_009_intersection_3,
  o_010_caravan_1,
  o_011_caravan,
  o_012_highway,
  o_013_traffic_sign_1,
  o_014_traffic_sign,
  o_015_warning_2,
  o_016_gas_station,
  o_017_turn_right_2,
  o_018_coffee_shop,
  o_019_bicycle_1,
  o_020_bicycle,
  o_021_bump,
  o_022_intersection_2,
  o_023_turn_1,
  o_024_turn_right_1,
  o_025_turn_left_1,
  o_026_intersection_1,
  o_027_intersection,
  o_028_waiting,
  o_029_horn,
  o_030_narrow_road,
  o_031_warning_1,
  o_032_hill,
  o_033_truck,
  o_034_pedestrian_2,
  o_035_pedestrian_1,
  o_036_traffic_light,
  o_037_turn,
  o_038_slippery,
  o_039_no_entry,
  o_040_speed_limit,
  o_041_humps,
  o_042_falling_rocks,
  o_043_pedestrian,
  o_044_turn_right,
  o_045_turn_left,
  o_046_u_turn,
  o_047_warning,
  o_048_no_parking,
  o_049_parking,
  o_050_danger
];

export const Ecommerce = [
  auction,
  badge,
  badge_1,
  badge_2,
  badge_3,
  badge_4,
  barcode,
  bedside_table,
  billing_machine,
  browser,
  browser_1,
  calculator,
  cash_register,
  click,
  clock,
  closed,
  coin,
  coin_1,
  coin_stack,
  comments,
  compact_disc,
  cosmetics,
  coupon,
  credit_card,
  credit_card_1,
  credit_card_2,
  customer_service,
  delivery_cart,
  delivery_truck,
  delivery_truck_1,
  diamond,
  discount,
  email,
  faq,
  gift,
  gift_card,
  groceries,
  help,
  like,
  money,
  money_bag,
  monitor,
  news,
  notepad,
  online_shop,
  online_shop_1,
  online_shop_2,
  online_shop_3,
  open,
  order,
  package_0,
  package_1,
  package_2,
  package_3,
  padlock,
  piggy_bank,
  placeholder,
  price_tag,
  promotion,
  purse,
  qr_code,
  receipt,
  refund,
  return_0,
  review,
  rocket_ship,
  safebox,
  search,
  search_1,
  shield,
  shirt,
  shirt_1,
  shopping_bag,
  shopping_bag_1,
  shopping_bag_2,
  shopping_basket,
  shopping_basket_1,
  shopping_basket_2,
  shopping_basket_3,
  shopping_basket_4,
  shopping_cart,
  shopping_cart_1,
  shopping_cart_2,
  shopping_cart_3,
  shopping_cart_4,
  smartphone,
  smartphone_1,
  star,
  statistics,
  stock,
  stock_1,
  stock_2,
  stopwatch,
  store,
  survey,
  user,
  wallet,
  wishlist,
  wishlist_1,
  worldwide
];

export const Shipping = [
  aeroplane,
  barcode,
  box,
  box_1,
  boxes,
  calendar,
  clipboard,
  container,
  delivery,
  delivery_1,
  delivery_2,
  delivery_3,
  delivery_truck,
  envelope,
  find,
  fragile,
  gift,
  laptop,
  package_0,
  package_1,
  package_2,
  package_3,
  package_4,
  package_5,
  package_6,
  package_7,
  route,
  safety,
  ship,
  shipping,
  smartphone,
  stroller,
  support,
  train,
  up,
  warehouse,
  warehouse_1,
  warehouse_2,
  weight,
  worldwide
];

export const Tools = [
  atomic,
  awl,
  axe,
  barrels,
  battery_1,
  battery,
  box,
  charging,
  chisel,
  clamp,
  coal,
  compass,
  cutter,
  driller,
  ecology,
  electric_car,
  electric_tower,
  eolic_energy,
  factory,
  faucet,
  file,
  gas,
  gas_station,
  glue,
  hammer,
  hammer_1,
  hammer_2,
  house,
  hydro_power,
  jigsaw,
  ladder,
  level,
  light_bulb,
  low_battery,
  marker,
  mat,
  measuring_tape,
  nail,
  needle,
  nut,
  oil_platform,
  paint_brush,
  paint_brush_1,
  paint_brush_2,
  paint_bucket,
  paint_palette,
  paint_roller,
  pantone,
  pencil,
  pliers,
  pliers_1,
  pliers_2,
  plug,
  power_button,
  power_plant,
  rain,
  recycle,
  renewable_energy,
  renewable_energy_1,
  ruler,
  ruler_1,
  save_energy,
  saw,
  saw_1,
  scissors,
  screw,
  screwdriver,
  set_square,
  shovel,
  shovel_1,
  socket,
  solar_energy,
  spatula,
  tape,
  toolbox,
  usb_cable,
  workplace,
  wrench,
  wrench_1,
  wrench_2
];

export const Logistics = [
  o_001_distribution_5,
  o_002_aerial,
  o_003_frontal_view,
  o_004_distribution_4,
  o_005_clipboards,
  o_006_oceanic,
  o_007_placeholders_1,
  o_008_international,
  o_009_packages_2,
  o_010_tools_and_utensils_3,
  o_011_weekly_calendar,
  o_012_placeholders,
  o_013_distribution_3,
  o_014_shopping_carts_1,
  o_015_maps_and_flags_1,
  o_016_organizing,
  o_017_maps_and_flags,
  o_018_tools_and_utensils_2,
  o_019_call_center_1,
  o_020_protected,
  o_021_cubes,
  o_022_distribution_2,
  o_023_call_center,
  o_024_bussiness,
  o_025_charter,
  o_026_trolley_cart,
  o_027_distribution_1,
  o_028_tools_and_utensils_1,
  o_029_airplanes,
  o_030_verified,
  o_031_shopping_carts,
  o_032_transportation,
  o_033_packages_1,
  o_034_changing,
  o_035_packages,
  o_036_symbolic,
  o_037_packing,
  o_038_trucks,
  o_039_tools_and_utensils,
  o_040_circular_arrows,
  o_041_debit_card,
  o_042_24_hours,
  o_043_distribution,
  o_044_cargo_ship
];

export const Professions = [
  astronaut,
  athlete,
  builder,
  carpenter,
  cashier,
  chef,
  chemist,
  clown,
  concierge,
  courier,
  detective,
  diver,
  dj,
  doctor,
  dyer,
  electrician,
  farmer,
  firefighter,
  fisherman,
  gardener,
  hairdresser,
  lumberjack,
  magician,
  maid,
  manager,
  mechanic,
  miner,
  nurse,
  painter,
  pensioner,
  photographer,
  pilot,
  plumber,
  policeman,
  politician,
  postman,
  priest,
  professor,
  programmer,
  sailor,
  secretary,
  showman,
  singer,
  soldier,
  stewardess,
  support,
  taxi_driver,
  teacher,
  waiter,
  writer
];

export const GPS = [
  compass,
  compass_1,
  gps,
  location3,
  location_1,
  location_2,
  location_3,
  locations,
  map,
  map_1,
  map_2,
  map_3,
  navigator,
  panel,
  pin,
  placeholder,
  placeholder_1,
  placeholder_2,
  placeholder_3,
  placeholder_4,
  placeholder_5,
  placeholder_6,
  placeholder_7,
  push_pin,
  route,
  route_1,
  satellite,
  smartphone,
  smartphone_1,
  target
];

export const Assets = [
  a_151_actions,
  a_151_advert,
  a_151_antennas,
  a_151_archives,
  a_151_attached,
  a_151_banker,
  a_151_barrow,
  a_151_bed_side,
  a_151_beds,
  a_151_blackboard,
  a_151_blocked,
  a_151_book_bag,
  a_151_book_bag_1,
  a_151_bridges,
  a_151_calculations,
  a_151_carrier,
  a_151_ceremonial,
  a_151_chairs,
  a_151_cleaned,
  a_151_closing,
  a_151_cooling,
  a_151_demostration,
  a_151_deposit,
  a_151_dialogue,
  a_151_disturbance,
  a_151_domestic_phone,
  a_151_door,
  a_151_door_key,
  a_151_doorway,
  a_151_draws,
  a_151_driller,
  a_151_electric,
  a_151_electric_1,
  a_151_electric_2,
  a_151_entrance,
  a_151_facade,
  a_151_facade_1,
  a_151_fader,
  a_151_file_storage,
  a_151_fireplace,
  a_151_fireplace_1,
  a_151_flats,
  a_151_flats_1,
  a_151_frame,
  a_151_garden_work,
  a_151_gardener,
  a_151_gardening,
  a_151_glamour,
  a_151_graphic_design,
  a_151_graphs,
  a_151_hacksaw,
  a_151_hand_gesture,
  a_151_hand_phones,
  a_151_headphones,
  a_151_health_care,
  a_151_hearth,
  a_151_house,
  a_151_house_things,
  a_151_house_things_1,
  a_151_house_things_2,
  a_151_houses,
  a_151_indoor,
  a_151_industrial,
  a_151_industrial_park,
  a_151_interior_design,
  a_151_invention,
  a_151_keyword,
  a_151_kilograms,
  a_151_ladders,
  a_151_line_chart,
  a_151_lover,
  a_151_mail,
  a_151_mailed,
  a_151_mallet,
  a_151_mansory,
  a_151_maps_and_flags,
  a_151_maps_and_flags_1,
  a_151_maps_and_flags_2,
  a_151_maps_and_flags_3,
  a_151_maps_and_flags_4,
  a_151_maps_and_flags_5,
  a_151_maps_and_flags_6,
  a_151_measuring,
  a_151_measuring_1,
  a_151_mechanics,
  a_151_metallic_blind,
  a_151_money_icons,
  a_151_monumental,
  a_151_motorhome,
  a_151_online_store,
  a_151_packaging,
  a_151_paint_roller,
  a_151_painted,
  a_151_paper_printer,
  a_151_paper_work,
  a_151_picket,
  a_151_pincers,
  a_151_plan,
  a_151_plans,
  a_151_plumbering,
  a_151_plumbering_1,
  a_151_printed,
  a_151_property,
  a_151_pruners,
  a_151_purses,
  a_151_radiators,
  a_151_reading_glasses,
  a_151_real_estate,
  a_151_real_estate_1,
  a_151_real_estate_2,
  a_151_real_estate_3,
  a_151_real_estate_4,
  a_151_real_estate_5,
  a_151_real_estate_6,
  a_151_relax,
  a_151_religious,
  a_151_rent,
  a_151_reparation,
  a_151_roof,
  a_151_sand_clock,
  a_151_savings,
  a_151_security_system,
  a_151_security_system_1,
  a_151_seventeen,
  a_151_shower,
  a_151_single,
  a_151_sit_down,
  a_151_skyline,
  a_151_step_ladder,
  a_151_storehouse,
  a_151_studying,
  a_151_switch_off,
  a_151_text_file,
  a_151_title,
  a_151_tools_and_utensils,
  a_151_transportation,
  a_151_trees,
  a_151_trucks,
  a_151_trucks_1,
  a_151_turned_off,
  a_151_up,
  a_151_up_1,
  a_151_up_2,
  a_151_ventilation,
  a_151_wc,
  a_151_wheel_chair,
  a_151_wheelbarrow,
  a_151_winch,
  a_151_work_tools,
  a_151_worker
];

export const Transportation = [
  aeroplane,
  airplane,
  auto_ricksaw,
  bike,
  boat,
  bus,
  bus_1,
  cable_car_cabin,
  car,
  car_1,
  car_2,
  car_3,
  catamaran,
  chairlift,
  classic_car,
  helicopter,
  hot_air_balloon,
  jeep,
  locomotive,
  lorry,
  minibus,
  monorail,
  motorbike,
  quad,
  sailboat,
  sailing_ship,
  school_bus,
  ship,
  ship_1,
  ship_2,
  ship_3,
  sidecar,
  spaceship,
  sportive_car,
  submarine,
  taxi,
  taxi_1,
  tractor,
  train,
  train_1,
  train_2,
  tram,
  tricycle,
  trolleybus,
  truck,
  ufo,
  underground,
  van,
  van_1,
  zeppelin
];

export const Aircraft =
  [Airliner_Black,
    Airliner_Blue,
    Airliner_Green,
    Airliner_Grey,
    Airliner_Red,
    Airliner_Yellow,
    Airplane_Black,
    Airplane_Blue,
    Airplane_Green,
    Airplane_Grey,
    Airplane_Red,
    Airplane_Yellow,
    Helicopter_Black,
    Helicopter_Blue,
    Helicopter_Green,
    Helicopter_Grey,
    Helicopter_Red,
    Helicopter_Yellow
  ];

export const Bikes = [
  Bicycle_Black,
  Bicycle_Blue,
  Bicycle_Green,
  Bicycle_Grey,
  Bicycle_Red,
  Bicycle_Yellow,
  CruiserMotorcycle_Black,
  CruiserMotorcycle_Blue,
  CruiserMotorcycle_Green,
  CruiserMotorcycle_Grey,
  CruiserMotorcycle_Red,
  CruiserMotorcycle_Yellow,
  Motorcycle_Black,
  Motorcycle_Blue,
  Motorcycle_Green,
  Motorcycle_Grey,
  Motorcycle_Red,
  Motorcycle_Yellow,
  QuadBike_Black,
  QuadBike_Blue,
  QuadBike_Green,
  QuadBike_Grey,
  QuadBike_Red,
  QuadBike_Yellow,
  Scooter_Black,
  Scooter_Blue,
  Scooter_Green,
  Scooter_Grey,
  Scooter_Red,
  Scooter_Yellow,
  TouringMotorcycle_Black,
  TouringMotorcycle_Blue,
  TouringMotorcycle_Gray,
  TouringMotorcycle_Green,
  TouringMotorcycle_Red,
  TouringMotorcycle_Yellow
];

export const Watercraft = [
  Barge_Black,
  Barge_Blue,
  Barge_Green,
  Barge_Grey,
  Barge_Red,
  Barge_Yellow,
  Boat_Black,
  Boat_Blue,
  Boat_Gray,
  Boat_Green,
  Boat_Red,
  Boat_Yellow,
  BulkCarrier_Black,
  BulkCarrier_Blue,
  BulkCarrier_Green,
  BulkCarrier_Grey,
  BulkCarrier_Red,
  BulkCarrier_Yellow,
  Motorboat_Black,
  Motorboat_Blue,
  Motorboat_Green,
  Motorboat_Grey,
  Motorboat_Red,
  Motorboat_Yellow,
  Sailboat_Black,
  Sailboat_Blue,
  Sailboat_Green,
  Sailboat_Grey,
  Sailboat_Red,
  Sailboat_Yellow,
  Tanker_Black,
  Tanker_Blue,
  Tanker_Green,
  Tanker_Grey,
  Tanker_Red,
  Tanker_Yellow,
  WaterMotorcycle_Black,
  WaterMotorcycle_Blue,
  WaterMotorcycle_Green,
  WaterMotorcycle_Grey,
  WaterMotorcycle_Red,
  WaterMotorcycle_Yellow
];

export const Cars = [
  Cabriolet_Black,
  Cabriolet_Blue,
  Cabriolet_Green,
  Cabriolet_Grey,
  Cabriolet_Red,
  Cabriolet_Yellow,
  CamperVan_Black,
  CamperVan_Blue,
  CamperVan_Green,
  CamperVan_Grey,
  CamperVan_Red,
  CamperVan_Yellow,
  Car_Black,
  Car_Blue,
  Car_Green,
  Car_Grey,
  Car_Red,
  Car_Yellow,
  ExecutiveCar_Black,
  ExecutiveCar_Blue,
  ExecutiveCar_Green,
  ExecutiveCar_Grey,
  ExecutiveCar_Red,
  ExecutiveCar_Yellow,
  Hatchback_Black,
  Hatchback_Blue,
  Hatchback_Green,
  Hatchback_Grey,
  Hatchback_Red,
  Hatchback_Yellow,
  HybridCar_Black,
  HybridCar_Blue,
  HybridCar_Green,
  HybridCar_Grey,
  HybridCar_Red,
  HybridCar_Yellow,
  Jeep_Black,
  Jeep_Blue,
  Jeep_Green,
  Jeep_Grey,
  Jeep_Red,
  Jeep_Yellow,
  Limousine_Black,
  Limousine_Blue,
  Limousine_Green,
  Limousine_Grey,
  Limousine_Red,
  Limousine_Yellow,
  LuxuryCar__Black,
  LuxuryCar__Blue,
  LuxuryCar__Gray,
  LuxuryCar__Green,
  LuxuryCar__Red,
  LuxuryCar__Yellow,
  Minibus_Black,
  Minibus_Blue,
  Minibus_Green,
  Minibus_Grey,
  Minibus_Red,
  Minibus_Yellow,
  Minicar_Black,
  Minicar_Blue,
  Minicar_Green,
  Minicar_Grey,
  Minicar_Red,
  Minicar_Yellow,
  MinicarTrailer_Black,
  MinicarTrailer_Blue,
  MinicarTrailer_Green,
  MinicarTrailer_Grey,
  MinicarTrailer_Red,
  MinicarTrailer_Yellow,
  Minitruck_Black,
  Minitruck_Blue,
  Minitruck_Green,
  Minitruck_Grey,
  Minitruck_Red,
  Minitruck_Yellow,
  MUV_Black,
  MUV_Blue,
  MUV_Green,
  MUV_Grey,
  MUV_Red,
  MUV_Yellow,
  PanelVan_Black,
  PanelVan_Blue,
  PanelVan_Green,
  PanelVan_Grey,
  PanelVan_Red,
  PanelVan_Yellow,
  Pickup_Black,
  Pickup_Blue,
  Pickup_Green,
  Pickup_Grey,
  Pickup_Red,
  Pickup_Yellow,
  ServiceVan_Black,
  ServiceVan_Blue,
  ServiceVan_Green,
  ServiceVan_Grey,
  ServiceVan_Red,
  ServiceVan_Yellow,
  SportsCar_Black,
  SportsCar_Blue,
  SportsCar_Green,
  SportsCar_Grey,
  SportsCar_Red,
  SportsCar_Yellow,
  StationWagon_Black,
  StationWagon_Blue,
  StationWagon_Green,
  StationWagon_Grey,
  StationWagon_Red,
  StationWagon_Yellow,
  SUV_Black,
  SUV_Blue,
  SUV_Green,
  SUV_Grey,
  SUV_Red,
  SUV_Yellow,
  Van_Black,
  Van_Blue,
  Van_Green,
  Van_Grey,
  Van_Red,
  Van_Yellow
];

export const Lights = [
  amber_light,
  green_light,
  grey_light,
  red_light,
  trafficlight,
  traffic_light_none,
  traffic_light_green,
  traffic_light_red
];

export const Machinery = [
  BackhoeLoader_Black,
  BackhoeLoader_Blue,
  BackhoeLoader_Green,
  BackhoeLoader_Grey,
  BackhoeLoader_Red,
  BackhoeLoader_Yellow,
  Bulldozer_Black,
  Bulldozer_Blue,
  Bulldozer_Green,
  Bulldozer_Grey,
  Bulldozer_Red,
  Bulldozer_Yellow,
  CompactExcavator_Black,
  CompactExcavator_Blue,
  CompactExcavator_Green,
  CompactExcavator_Grey,
  CompactExcavator_Red,
  CompactExcavator_Yellow,
  Excavator_Black,
  Excavator_Blue,
  Excavator_Green,
  Excavator_Grey,
  Excavator_Red,
  Excavator_Yellow,
  FarmTractor_Black,
  FarmTractor_Blue,
  FarmTractor_Green,
  FarmTractor_Grey,
  FarmTractor_Red,
  FarmTractor_Yellow,
  ForkliftTruck__Black,
  ForkliftTruck__Blue,
  ForkliftTruck__Green,
  ForkliftTruck__Grey,
  ForkliftTruck__Red,
  ForkliftTruck__Yellow,
  ForkliftTruck_Loaded_Black,
  ForkliftTruck_Loaded_Blue,
  ForkliftTruck_Loaded_Green,
  ForkliftTruck_Loaded_Grey,
  ForkliftTruck_Loaded_Red,
  ForkliftTruck_Loaded_Yellow,
  LandfillCompactor_Black,
  LandfillCompactor_Blue,
  LandfillCompactor_Green,
  LandfillCompactor_Grey,
  LandfillCompactor_Red,
  LandfillCompactor_Yellow,
  Loader_Black,
  Loader_Blue,
  Loader_Green,
  Loader_Grey,
  Loader_Red,
  Loader_Yellow,
  MaterialHandler_Black,
  MaterialHandler_Blue,
  MaterialHandler_Green,
  MaterialHandler_Grey,
  MaterialHandler_Red,
  MaterialHandler_Yellow,
  RoadRoller_Black,
  RoadRoller_Blue,
  RoadRoller_Green,
  RoadRoller_Grey,
  RoadRoller_Red,
  RoadRoller_Yellow,
  RoadSweeper_Black,
  RoadSweeper_Blue,
  RoadSweeper_Green,
  RoadSweeper_Grey,
  RoadSweeper_Red,
  RoadSweeper_Yellow,
  RollerCompactor_Black,
  RollerCompactor_Blue,
  RollerCompactor_Green,
  RollerCompactor_Grey,
  RollerCompactor_Red,
  RollerCompactor_Yellow,
  SkidSteerLoader_Black,
  SkidSteerLoader_Blue,
  SkidSteerLoader_Green,
  SkidSteerLoader_Grey,
  SkidSteerLoader_Red,
  SkidSteerLoader_Yellow,
  SkipLoader_Black,
  SkipLoader_Blue,
  SkipLoader_Green,
  SkipLoader_Grey,
  SkipLoader_Red,
  SkipLoader_Yellow,
  SoilStabilizer_Black,
  SoilStabilizer_Blue,
  SoilStabilizer_Green,
  SoilStabilizer_Grey,
  SoilStabilizer_Red,
  SoilStabilizer_Yellow,
  SteamRoller_Black,
  SteamRoller_Blue,
  SteamRoller_Green,
  SteamRoller_Grey,
  SteamRoller_Red,
  SteamRoller_Yellow,
  StreetSweeper_Black,
  StreetSweeper_Blue,
  StreetSweeper_Green,
  StreetSweeper_Grey,
  StreetSweeper_Red,
  StreetSweeper_Yellow,
  TelescopicHandler_Black,
  TelescopicHandler_Blue,
  TelescopicHandler_Green,
  TelescopicHandler_Grey,
  TelescopicHandler_Red,
  TelescopicHandler_Yellow,
  TruckMountedCrane__Black,
  TruckMountedCrane__Blue,
  TruckMountedCrane__Green,
  TruckMountedCrane__Grey,
  TruckMountedCrane__Red,
  TruckMountedCrane__Yellow,
  TruckMountedCrane_Working_Black,
  TruckMountedCrane_Working_Blue,
  TruckMountedCrane_Working_Green,
  TruckMountedCrane_Working_Grey,
  TruckMountedCrane_Working_Red,
  TruckMountedCrane_Working_Yellow,
  WheeledExcavator_Black,
  WheeledExcavator_Blue,
  WheeledExcavator_Green,
  WheeledExcavator_Grey,
  WheeledExcavator_Red,
  WheeledExcavator_Yellow,
  WreckingBall_Black,
  WreckingBall_Blue,
  WreckingBall_Green,
  WreckingBall_Grey,
  WreckingBall_Red,
  WreckingBall_Yellow
];

export const PublicTransport = [
  Coach_Black,
  Coach_Blue,
  Coach_Green,
  Coach_Grey,
  Coach_Red,
  Coach_Yellow,
  CommuterBus_Black,
  CommuterBus_Blue,
  CommuterBus_Green,
  CommuterBus_Grey,
  CommuterBus_Red,
  CommuterBus_Yellow,
  Taxi_Black,
  Taxi_Blue,
  Taxi_Green,
  Taxi_Grey,
  Taxi_Red,
  Taxi_Yellow,
  TransitBus_Black,
  TransitBus_Blue,
  TransitBus_Green,
  TransitBus_Grey,
  TransitBus_Red,
  TransitBus_Yellow
];

export const Trailers = [
  BoxTrailer_Black,
  BoxTrailer_Blue,
  BoxTrailer_Green,
  BoxTrailer_Grey,
  BoxTrailer_Red,
  BoxTrailer_Yellow,
  Caravan_Black,
  Caravan_Blue,
  Caravan_Green,
  Caravan_Grey,
  Caravan_Red,
  Caravan_Yellow,
  FullTrailer_Black,
  FullTrailer_Blue,
  FullTrailer_Green,
  FullTrailer_Grey,
  FullTrailer_Red,
  FullTrailer_Yellow,
  SuperSilentGeneratorTrailer_Black,
  SuperSilentGeneratorTrailer_Blue,
  SuperSilentGeneratorTrailer_Green,
  SuperSilentGeneratorTrailer_Grey,
  SuperSilentGeneratorTrailer_Red,
  SuperSilentGeneratorTrailer_Yellow,
  TankerTrailer_Black,
  TankerTrailer_Blue,
  TankerTrailer_Green,
  TankerTrailer_Grey,
  TankerTrailer_Red,
  TankerTrailer_Yellow,
  TravelTrailer_Black,
  TravelTrailer_Blue,
  TravelTrailer_Green,
  TravelTrailer_Grey,
  TravelTrailer_Red,
  TravelTrailer_Yellow,
  WaterBowserTrailer_Black,
  WaterBowserTrailer_Blue,
  WaterBowserTrailer_Green,
  WaterBowserTrailer_Grey,
  WaterBowserTrailer_Red,
  WaterBowserTrailer_Yellow
];

export const Trucks = [
  BinTruck_Black,
  BinTruck_Blue,
  BinTruck_Green,
  BinTruck_Grey,
  BinTruck_Red,
  BinTruck_Yellow,
  FuelTankTruck_Black,
  FuelTankTruck_Blue,
  FuelTankTruck_Green,
  FuelTankTruck_Grey,
  FuelTankTruck_Red,
  FuelTankTruck_Yellow,
  HaulTruck_Black,
  HaulTruck_Blue,
  HaulTruck_Green,
  HaulTruck_Grey,
  HaulTruck_Red,
  HaulTruck_Yellow,
  Lorry_Black,
  Lorry_Blue,
  Lorry_Green,
  Lorry_Grey,
  Lorry_Red,
  Lorry_Yellow,
  LorryCab_Black,
  LorryCab_Blue,
  LorryCab_Green,
  LorryCab_Grey,
  LorryCab_Red,
  LorryCab_Yellow,
  LorryTrailer_Black,
  LorryTrailer_Blue,
  LorryTrailer_Green,
  LorryTrailer_Grey,
  LorryTrailer_Red,
  LorryTrailer_Yellow,
  PetroleumTankTruck_Black,
  PetroleumTankTruck_Blue,
  PetroleumTankTruck_Green,
  PetroleumTankTruck_Grey,
  PetroleumTankTruck_Red,
  PetroleumTankTruck_Yellow,
  SkipTruck_Black,
  SkipTruck_Blue,
  SkipTruck_Green,
  SkipTruck_Grey,
  SkipTruck_Red,
  SkipTruck_Yellow,
  TankerTruck_Black,
  TankerTruck_Blue,
  TankerTruck_Green,
  TankerTruck_Grey,
  TankerTruck_Red,
  TankerTruck_Yellow,
  TractorTrailer2_Black,
  TractorTrailer2_Blue,
  TractorTrailer2_Green,
  TractorTrailer2_Grey,
  TractorTrailer2_Red,
  TractorTrailer2_Yellow,
  TractorTrailer_Black,
  TractorTrailer_Blue,
  TractorTrailer_Green,
  TractorTrailer_Grey,
  TractorTrailer_Red,
  TractorTrailer_Yellow,
  Truck_Black,
  Truck_Blue,
  Truck_Green,
  Truck_Grey,
  Truck_Red,
  Truck_Yellow,
  TruckV_Black,
  TruckV_Blue,
  TruckV_Green,
  TruckV_Grey,
  TruckV_Red,
  TruckV_Yellow
];

export const imageSet = {
  location,
  Airliner_Black,
  Airliner_Blue,
  Airliner_Green,
  Airliner_Grey,
  Airliner_Red,
  Airliner_Yellow,
  Airplane_Black,
  Airplane_Blue,
  Airplane_Green,
  Airplane_Grey,
  Airplane_Red,
  Airplane_Yellow,
  Helicopter_Black,
  Helicopter_Blue,
  Helicopter_Green,
  Helicopter_Grey,
  Helicopter_Red,
  Helicopter_Yellow,
  Bicycle_Black,
  Bicycle_Blue,
  Bicycle_Green,
  Bicycle_Grey,
  Bicycle_Red,
  Bicycle_Yellow,
  CruiserMotorcycle_Black,
  CruiserMotorcycle_Blue,
  CruiserMotorcycle_Green,
  CruiserMotorcycle_Grey,
  CruiserMotorcycle_Red,
  CruiserMotorcycle_Yellow,
  Motorcycle_Black,
  Motorcycle_Blue,
  Motorcycle_Green,
  Motorcycle_Grey,
  Motorcycle_Red,
  Motorcycle_Yellow,
  QuadBike_Black,
  QuadBike_Blue,
  QuadBike_Green,
  QuadBike_Grey,
  QuadBike_Red,
  QuadBike_Yellow,
  Scooter_Black,
  Scooter_Blue,
  Scooter_Green,
  Scooter_Grey,
  Scooter_Red,
  Scooter_Yellow,
  TouringMotorcycle_Black,
  TouringMotorcycle_Blue,
  TouringMotorcycle_Gray,
  TouringMotorcycle_Green,
  TouringMotorcycle_Red,
  TouringMotorcycle_Yellow,
  Barge_Black,
  Barge_Blue,
  Barge_Green,
  Barge_Grey,
  Barge_Red,
  Barge_Yellow,
  Boat_Black,
  Boat_Blue,
  Boat_Gray,
  Boat_Green,
  Boat_Red,
  Boat_Yellow,
  BulkCarrier_Black,
  BulkCarrier_Blue,
  BulkCarrier_Green,
  BulkCarrier_Grey,
  BulkCarrier_Red,
  BulkCarrier_Yellow,
  Motorboat_Black,
  Motorboat_Blue,
  Motorboat_Green,
  Motorboat_Grey,
  Motorboat_Red,
  Motorboat_Yellow,
  Sailboat_Black,
  Sailboat_Blue,
  Sailboat_Green,
  Sailboat_Grey,
  Sailboat_Red,
  Sailboat_Yellow,
  Tanker_Black,
  Tanker_Blue,
  Tanker_Green,
  Tanker_Grey,
  Tanker_Red,
  Tanker_Yellow,
  WaterMotorcycle_Black,
  WaterMotorcycle_Blue,
  WaterMotorcycle_Green,
  WaterMotorcycle_Grey,
  WaterMotorcycle_Red,
  WaterMotorcycle_Yellow,
  Cabriolet_Black,
  Cabriolet_Blue,
  Cabriolet_Green,
  Cabriolet_Grey,
  Cabriolet_Red,
  Cabriolet_Yellow,
  CamperVan_Black,
  CamperVan_Blue,
  CamperVan_Green,
  CamperVan_Grey,
  CamperVan_Red,
  CamperVan_Yellow,
  Car_Black,
  Car_Blue,
  Car_Green,
  Car_Grey,
  Car_Red,
  Car_Yellow,
  ExecutiveCar_Black,
  ExecutiveCar_Blue,
  ExecutiveCar_Green,
  ExecutiveCar_Grey,
  ExecutiveCar_Red,
  ExecutiveCar_Yellow,
  Hatchback_Black,
  Hatchback_Blue,
  Hatchback_Green,
  Hatchback_Grey,
  Hatchback_Red,
  Hatchback_Yellow,
  HybridCar_Black,
  HybridCar_Blue,
  HybridCar_Green,
  HybridCar_Grey,
  HybridCar_Red,
  HybridCar_Yellow,
  Jeep_Black,
  Jeep_Blue,
  Jeep_Green,
  Jeep_Grey,
  Jeep_Red,
  Jeep_Yellow,
  Limousine_Black,
  Limousine_Blue,
  Limousine_Green,
  Limousine_Grey,
  Limousine_Red,
  Limousine_Yellow,
  LuxuryCar__Black,
  LuxuryCar__Blue,
  LuxuryCar__Gray,
  LuxuryCar__Green,
  LuxuryCar__Red,
  LuxuryCar__Yellow,
  Minibus_Black,
  Minibus_Blue,
  Minibus_Green,
  Minibus_Grey,
  Minibus_Red,
  Minibus_Yellow,
  Minicar_Black,
  Minicar_Blue,
  Minicar_Green,
  Minicar_Grey,
  Minicar_Red,
  Minicar_Yellow,
  MinicarTrailer_Black,
  MinicarTrailer_Blue,
  MinicarTrailer_Green,
  MinicarTrailer_Grey,
  MinicarTrailer_Red,
  MinicarTrailer_Yellow,
  Minitruck_Black,
  Minitruck_Blue,
  Minitruck_Green,
  Minitruck_Grey,
  Minitruck_Red,
  Minitruck_Yellow,
  MUV_Black,
  MUV_Blue,
  MUV_Green,
  MUV_Grey,
  MUV_Red,
  MUV_Yellow,
  PanelVan_Black,
  PanelVan_Blue,
  PanelVan_Green,
  PanelVan_Grey,
  PanelVan_Red,
  PanelVan_Yellow,
  Pickup_Black,
  Pickup_Blue,
  Pickup_Green,
  Pickup_Grey,
  Pickup_Red,
  Pickup_Yellow,
  ServiceVan_Black,
  ServiceVan_Blue,
  ServiceVan_Green,
  ServiceVan_Grey,
  ServiceVan_Red,
  ServiceVan_Yellow,
  SportsCar_Black,
  SportsCar_Blue,
  SportsCar_Green,
  SportsCar_Grey,
  SportsCar_Red,
  SportsCar_Yellow,
  StationWagon_Black,
  StationWagon_Blue,
  StationWagon_Green,
  StationWagon_Grey,
  StationWagon_Red,
  StationWagon_Yellow,
  SUV_Black,
  SUV_Blue,
  SUV_Green,
  SUV_Grey,
  SUV_Red,
  SUV_Yellow,
  Van_Black,
  Van_Blue,
  Van_Green,
  Van_Grey,
  Van_Red,
  Van_Yellow,
  amber_light,
  green_light,
  grey_light,
  red_light,
  trafficlight,
  traffic_light_none,
  traffic_light_red,
  traffic_light_green,
  BackhoeLoader_Black,
  BackhoeLoader_Blue,
  BackhoeLoader_Green,
  BackhoeLoader_Grey,
  BackhoeLoader_Red,
  BackhoeLoader_Yellow,
  Bulldozer_Black,
  Bulldozer_Blue,
  Bulldozer_Green,
  Bulldozer_Grey,
  Bulldozer_Red,
  Bulldozer_Yellow,
  CompactExcavator_Black,
  CompactExcavator_Blue,
  CompactExcavator_Green,
  CompactExcavator_Grey,
  CompactExcavator_Red,
  CompactExcavator_Yellow,
  Excavator_Black,
  Excavator_Blue,
  Excavator_Green,
  Excavator_Grey,
  Excavator_Red,
  Excavator_Yellow,
  FarmTractor_Black,
  FarmTractor_Blue,
  FarmTractor_Green,
  FarmTractor_Grey,
  FarmTractor_Red,
  FarmTractor_Yellow,
  ForkliftTruck__Black,
  ForkliftTruck__Blue,
  ForkliftTruck__Green,
  ForkliftTruck__Grey,
  ForkliftTruck__Red,
  ForkliftTruck__Yellow,
  ForkliftTruck_Loaded_Black,
  ForkliftTruck_Loaded_Blue,
  ForkliftTruck_Loaded_Green,
  ForkliftTruck_Loaded_Grey,
  ForkliftTruck_Loaded_Red,
  ForkliftTruck_Loaded_Yellow,
  LandfillCompactor_Black,
  LandfillCompactor_Blue,
  LandfillCompactor_Green,
  LandfillCompactor_Grey,
  LandfillCompactor_Red,
  LandfillCompactor_Yellow,
  Loader_Black,
  Loader_Blue,
  Loader_Green,
  Loader_Grey,
  Loader_Red,
  Loader_Yellow,
  MaterialHandler_Black,
  MaterialHandler_Blue,
  MaterialHandler_Green,
  MaterialHandler_Grey,
  MaterialHandler_Red,
  MaterialHandler_Yellow,
  RoadRoller_Black,
  RoadRoller_Blue,
  RoadRoller_Green,
  RoadRoller_Grey,
  RoadRoller_Red,
  RoadRoller_Yellow,
  RoadSweeper_Black,
  RoadSweeper_Blue,
  RoadSweeper_Green,
  RoadSweeper_Grey,
  RoadSweeper_Red,
  RoadSweeper_Yellow,
  RollerCompactor_Black,
  RollerCompactor_Blue,
  RollerCompactor_Green,
  RollerCompactor_Grey,
  RollerCompactor_Red,
  RollerCompactor_Yellow,
  SkidSteerLoader_Black,
  SkidSteerLoader_Blue,
  SkidSteerLoader_Green,
  SkidSteerLoader_Grey,
  SkidSteerLoader_Red,
  SkidSteerLoader_Yellow,
  SkipLoader_Black,
  SkipLoader_Blue,
  SkipLoader_Green,
  SkipLoader_Grey,
  SkipLoader_Red,
  SkipLoader_Yellow,
  SoilStabilizer_Black,
  SoilStabilizer_Blue,
  SoilStabilizer_Green,
  SoilStabilizer_Grey,
  SoilStabilizer_Red,
  SoilStabilizer_Yellow,
  SteamRoller_Black,
  SteamRoller_Blue,
  SteamRoller_Green,
  SteamRoller_Grey,
  SteamRoller_Red,
  SteamRoller_Yellow,
  StreetSweeper_Black,
  StreetSweeper_Blue,
  StreetSweeper_Green,
  StreetSweeper_Grey,
  StreetSweeper_Red,
  StreetSweeper_Yellow,
  TelescopicHandler_Black,
  TelescopicHandler_Blue,
  TelescopicHandler_Green,
  TelescopicHandler_Grey,
  TelescopicHandler_Red,
  TelescopicHandler_Yellow,
  TruckMountedCrane__Black,
  TruckMountedCrane__Blue,
  TruckMountedCrane__Green,
  TruckMountedCrane__Grey,
  TruckMountedCrane__Red,
  TruckMountedCrane__Yellow,
  TruckMountedCrane_Working_Black,
  TruckMountedCrane_Working_Blue,
  TruckMountedCrane_Working_Green,
  TruckMountedCrane_Working_Grey,
  TruckMountedCrane_Working_Red,
  TruckMountedCrane_Working_Yellow,
  WheeledExcavator_Black,
  WheeledExcavator_Blue,
  WheeledExcavator_Green,
  WheeledExcavator_Grey,
  WheeledExcavator_Red,
  WheeledExcavator_Yellow,
  WreckingBall_Black,
  WreckingBall_Blue,
  WreckingBall_Green,
  WreckingBall_Grey,
  WreckingBall_Red,
  WreckingBall_Yellow,
  Coach_Black,
  Coach_Blue,
  Coach_Green,
  Coach_Grey,
  Coach_Red,
  Coach_Yellow,
  CommuterBus_Black,
  CommuterBus_Blue,
  CommuterBus_Green,
  CommuterBus_Grey,
  CommuterBus_Red,
  CommuterBus_Yellow,
  Taxi_Black,
  Taxi_Blue,
  Taxi_Green,
  Taxi_Grey,
  Taxi_Red,
  Taxi_Yellow,
  TransitBus_Black,
  TransitBus_Blue,
  TransitBus_Green,
  TransitBus_Grey,
  TransitBus_Red,
  TransitBus_Yellow,
  BoxTrailer_Black,
  BoxTrailer_Blue,
  BoxTrailer_Green,
  BoxTrailer_Grey,
  BoxTrailer_Red,
  BoxTrailer_Yellow,
  Caravan_Black,
  Caravan_Blue,
  Caravan_Green,
  Caravan_Grey,
  Caravan_Red,
  Caravan_Yellow,
  FullTrailer_Black,
  FullTrailer_Blue,
  FullTrailer_Green,
  FullTrailer_Grey,
  FullTrailer_Red,
  FullTrailer_Yellow,
  SuperSilentGeneratorTrailer_Black,
  SuperSilentGeneratorTrailer_Blue,
  SuperSilentGeneratorTrailer_Green,
  SuperSilentGeneratorTrailer_Grey,
  SuperSilentGeneratorTrailer_Red,
  SuperSilentGeneratorTrailer_Yellow,
  TankerTrailer_Black,
  TankerTrailer_Blue,
  TankerTrailer_Green,
  TankerTrailer_Grey,
  TankerTrailer_Red,
  TankerTrailer_Yellow,
  TravelTrailer_Black,
  TravelTrailer_Blue,
  TravelTrailer_Green,
  TravelTrailer_Grey,
  TravelTrailer_Red,
  TravelTrailer_Yellow,
  WaterBowserTrailer_Black,
  WaterBowserTrailer_Blue,
  WaterBowserTrailer_Green,
  WaterBowserTrailer_Grey,
  WaterBowserTrailer_Red,
  WaterBowserTrailer_Yellow,
  BinTruck_Black,
  BinTruck_Blue,
  BinTruck_Green,
  BinTruck_Grey,
  BinTruck_Red,
  BinTruck_Yellow,
  FuelTankTruck_Black,
  FuelTankTruck_Blue,
  FuelTankTruck_Green,
  FuelTankTruck_Grey,
  FuelTankTruck_Red,
  FuelTankTruck_Yellow,
  HaulTruck_Black,
  HaulTruck_Blue,
  HaulTruck_Green,
  HaulTruck_Grey,
  HaulTruck_Red,
  HaulTruck_Yellow,
  Lorry_Black,
  Lorry_Blue,
  Lorry_Green,
  Lorry_Grey,
  Lorry_Red,
  Lorry_Yellow,
  LorryCab_Black,
  LorryCab_Blue,
  LorryCab_Green,
  LorryCab_Grey,
  LorryCab_Red,
  LorryCab_Yellow,
  LorryTrailer_Black,
  LorryTrailer_Blue,
  LorryTrailer_Green,
  LorryTrailer_Grey,
  LorryTrailer_Red,
  LorryTrailer_Yellow,
  PetroleumTankTruck_Black,
  PetroleumTankTruck_Blue,
  PetroleumTankTruck_Green,
  PetroleumTankTruck_Grey,
  PetroleumTankTruck_Red,
  PetroleumTankTruck_Yellow,
  SkipTruck_Black,
  SkipTruck_Blue,
  SkipTruck_Green,
  SkipTruck_Grey,
  SkipTruck_Red,
  SkipTruck_Yellow,
  TankerTruck_Black,
  TankerTruck_Blue,
  TankerTruck_Green,
  TankerTruck_Grey,
  TankerTruck_Red,
  TankerTruck_Yellow,
  TractorTrailer2_Black,
  TractorTrailer2_Blue,
  TractorTrailer2_Green,
  TractorTrailer2_Grey,
  TractorTrailer2_Red,
  TractorTrailer2_Yellow,
  TractorTrailer_Black,
  TractorTrailer_Blue,
  TractorTrailer_Green,
  TractorTrailer_Grey,
  TractorTrailer_Red,
  TractorTrailer_Yellow,
  Truck_Black,
  Truck_Blue,
  Truck_Green,
  Truck_Grey,
  Truck_Red,
  Truck_Yellow,
  TruckV_Black,
  TruckV_Blue,
  TruckV_Green,
  TruckV_Grey,
  TruckV_Red,
  TruckV_Yellow,
  aeroplane,
  airplane,
  auto_ricksaw,
  bike,
  boat,
  bus,
  bus_1,
  cable_car_cabin,
  car,
  car_1,
  car_2,
  car_3,
  catamaran,
  chairlift,
  classic_car,
  helicopter,
  hot_air_balloon,
  jeep,
  locomotive,
  lorry,
  minibus,
  monorail,
  motorbike,
  quad,
  sailboat,
  sailing_ship,
  school_bus,
  ship,
  ship_1,
  ship_2,
  ship_3,
  sidecar,
  spaceship,
  sportive_car,
  submarine,
  taxi,
  taxi_1,
  tractor,
  train,
  train_1,
  train_2,
  tram,
  tricycle,
  trolleybus,
  truck,
  ufo,
  underground,
  van,
  van_1,
  zeppelin,
  a_151_actions,
  a_151_advert,
  a_151_antennas,
  a_151_archives,
  a_151_attached,
  a_151_banker,
  a_151_barrow,
  a_151_bed_side,
  a_151_beds,
  a_151_blackboard,
  a_151_blocked,
  a_151_book_bag,
  a_151_book_bag_1,
  a_151_bridges,
  a_151_calculations,
  a_151_carrier,
  a_151_ceremonial,
  a_151_chairs,
  a_151_cleaned,
  a_151_closing,
  a_151_cooling,
  a_151_demostration,
  a_151_deposit,
  a_151_dialogue,
  a_151_disturbance,
  a_151_domestic_phone,
  a_151_door,
  a_151_door_key,
  a_151_doorway,
  a_151_draws,
  a_151_driller,
  a_151_electric,
  a_151_electric_1,
  a_151_electric_2,
  a_151_entrance,
  a_151_facade,
  a_151_facade_1,
  a_151_fader,
  a_151_file_storage,
  a_151_fireplace,
  a_151_fireplace_1,
  a_151_flats,
  a_151_flats_1,
  a_151_frame,
  a_151_garden_work,
  a_151_gardener,
  a_151_gardening,
  a_151_glamour,
  a_151_graphic_design,
  a_151_graphs,
  a_151_hacksaw,
  a_151_hand_gesture,
  a_151_hand_phones,
  a_151_headphones,
  a_151_health_care,
  a_151_hearth,
  a_151_house,
  a_151_house_things,
  a_151_house_things_1,
  a_151_house_things_2,
  a_151_houses,
  a_151_indoor,
  a_151_industrial,
  a_151_industrial_park,
  a_151_interior_design,
  a_151_invention,
  a_151_keyword,
  a_151_kilograms,
  a_151_ladders,
  a_151_line_chart,
  a_151_lover,
  a_151_mail,
  a_151_mailed,
  a_151_mallet,
  a_151_mansory,
  a_151_maps_and_flags,
  a_151_maps_and_flags_1,
  a_151_maps_and_flags_2,
  a_151_maps_and_flags_3,
  a_151_maps_and_flags_4,
  a_151_maps_and_flags_5,
  a_151_maps_and_flags_6,
  a_151_measuring,
  a_151_measuring_1,
  a_151_mechanics,
  a_151_metallic_blind,
  a_151_money_icons,
  a_151_monumental,
  a_151_motorhome,
  a_151_online_store,
  a_151_packaging,
  a_151_paint_roller,
  a_151_painted,
  a_151_paper_printer,
  a_151_paper_work,
  a_151_picket,
  a_151_pincers,
  a_151_plan,
  a_151_plans,
  a_151_plumbering,
  a_151_plumbering_1,
  a_151_printed,
  a_151_property,
  a_151_pruners,
  a_151_purses,
  a_151_radiators,
  a_151_reading_glasses,
  a_151_real_estate,
  a_151_real_estate_1,
  a_151_real_estate_2,
  a_151_real_estate_3,
  a_151_real_estate_4,
  a_151_real_estate_5,
  a_151_real_estate_6,
  a_151_relax,
  a_151_religious,
  a_151_rent,
  a_151_reparation,
  a_151_roof,
  a_151_sand_clock,
  a_151_savings,
  a_151_security_system,
  a_151_security_system_1,
  a_151_seventeen,
  a_151_shower,
  a_151_single,
  a_151_sit_down,
  a_151_skyline,
  a_151_step_ladder,
  a_151_storehouse,
  a_151_studying,
  a_151_switch_off,
  a_151_text_file,
  a_151_title,
  a_151_tools_and_utensils,
  a_151_transportation,
  a_151_trees,
  a_151_trucks,
  a_151_trucks_1,
  a_151_turned_off,
  a_151_up,
  a_151_up_1,
  a_151_up_2,
  a_151_ventilation,
  a_151_wc,
  a_151_wheel_chair,
  a_151_wheelbarrow,
  a_151_winch,
  a_151_work_tools,
  a_151_worker,
  compass,
  compass_1,
  gps,
  location3,
  location_1,
  location_2,
  location_3,
  locations,
  map,
  map_1,
  map_2,
  map_3,
  navigator,
  panel,
  pin,
  placeholder,
  placeholder_1,
  placeholder_2,
  placeholder_3,
  placeholder_4,
  placeholder_5,
  placeholder_6,
  placeholder_7,
  push_pin,
  route,
  route_1,
  satellite,
  smartphone,
  smartphone_1,
  target,
  astronaut,
  athlete,
  builder,
  carpenter,
  cashier,
  chef,
  chemist,
  clown,
  concierge,
  courier,
  detective,
  diver,
  dj,
  doctor,
  dyer,
  electrician,
  farmer,
  firefighter,
  fisherman,
  gardener,
  hairdresser,
  lumberjack,
  magician,
  maid,
  manager,
  mechanic,
  miner,
  nurse,
  painter,
  pensioner,
  photographer,
  pilot,
  plumber,
  policeman,
  politician,
  postman,
  priest,
  professor,
  programmer,
  sailor,
  secretary,
  showman,
  singer,
  soldier,
  stewardess,
  support,
  taxi_driver,
  teacher,
  waiter,
  writer,
  o_001_distribution_5,
  o_002_aerial,
  o_003_frontal_view,
  o_004_distribution_4,
  o_005_clipboards,
  o_006_oceanic,
  o_007_placeholders_1,
  o_008_international,
  o_009_packages_2,
  o_010_tools_and_utensils_3,
  o_011_weekly_calendar,
  o_012_placeholders,
  o_013_distribution_3,
  o_014_shopping_carts_1,
  o_015_maps_and_flags_1,
  o_016_organizing,
  o_017_maps_and_flags,
  o_018_tools_and_utensils_2,
  o_019_call_center_1,
  o_020_protected,
  o_021_cubes,
  o_022_distribution_2,
  o_023_call_center,
  o_024_bussiness,
  o_025_charter,
  o_026_trolley_cart,
  o_027_distribution_1,
  o_028_tools_and_utensils_1,
  o_029_airplanes,
  o_030_verified,
  o_031_shopping_carts,
  o_032_transportation,
  o_033_packages_1,
  o_034_changing,
  o_035_packages,
  o_036_symbolic,
  o_037_packing,
  o_038_trucks,
  o_039_tools_and_utensils,
  o_040_circular_arrows,
  o_041_debit_card,
  o_042_24_hours,
  o_043_distribution,
  o_044_cargo_ship,
  atomic,
  awl,
  axe,
  barrels,
  battery_1,
  box,
  charging,
  chisel,
  clamp,
  coal,
  cutter,
  driller,
  ecology,
  electric_car,
  electric_tower,
  eolic_energy,
  factory,
  faucet,
  file,
  gas,
  gas_station,
  glue,
  hammer,
  hammer_1,
  hammer_2,
  house,
  hydro_power,
  jigsaw,
  ladder,
  level,
  light_bulb,
  low_battery,
  marker,
  mat,
  measuring_tape,
  nail,
  needle,
  nut,
  oil_platform,
  paint_brush,
  paint_brush_1,
  paint_brush_2,
  paint_bucket,
  paint_palette,
  paint_roller,
  pantone,
  pencil,
  pliers,
  pliers_1,
  pliers_2,
  plug,
  power_button,
  power_plant,
  rain,
  recycle,
  renewable_energy,
  renewable_energy_1,
  ruler,
  ruler_1,
  save_energy,
  saw,
  saw_1,
  scissors,
  screw,
  screwdriver,
  set_square,
  shovel,
  shovel_1,
  socket,
  solar_energy,
  spatula,
  tape,
  toolbox,
  usb_cable,
  workplace,
  wrench,
  wrench_1,
  wrench_2,
  battery,
  barcode,
  box_1,
  boxes,
  calendar,
  clipboard,
  container,
  delivery,
  delivery_1,
  delivery_2,
  delivery_3,
  delivery_truck,
  envelope,
  find,
  fragile,
  gift,
  laptop,
  package_0,
  package_1,
  package_2,
  package_3,
  package_4,
  package_5,
  package_6,
  package_7,
  safety,
  shipping,
  stroller,
  up,
  warehouse,
  warehouse_1,
  warehouse_2,
  weight,
  worldwide,
  auction,
  badge,
  badge_1,
  badge_2,
  badge_3,
  badge_4,
  bedside_table,
  billing_machine,
  browser,
  browser_1,
  calculator,
  cash_register,
  click,
  clock,
  closed,
  coin,
  coin_1,
  coin_stack,
  comments,
  compact_disc,
  cosmetics,
  coupon,
  credit_card,
  credit_card_1,
  credit_card_2,
  customer_service,
  delivery_cart,
  delivery_truck_1,
  diamond,
  discount,
  email,
  faq,
  gift_card,
  groceries,
  help,
  like,
  money,
  money_bag,
  monitor,
  news,
  notepad,
  online_shop,
  online_shop_1,
  online_shop_2,
  online_shop_3,
  open,
  order,
  padlock,
  piggy_bank,
  price_tag,
  promotion,
  purse,
  qr_code,
  receipt,
  refund,
  return_0,
  review,
  rocket_ship,
  safebox,
  search,
  search_1,
  shield,
  shirt,
  shirt_1,
  shopping_bag,
  shopping_bag_1,
  shopping_bag_2,
  shopping_basket,
  shopping_basket_1,
  shopping_basket_2,
  shopping_basket_3,
  shopping_basket_4,
  shopping_cart,
  shopping_cart_1,
  shopping_cart_2,
  shopping_cart_3,
  shopping_cart_4,
  star,
  statistics,
  stock,
  stock_1,
  stock_2,
  stopwatch,
  store,
  survey,
  user,
  wallet,
  wishlist,
  wishlist_1,
  o_001_highway_1,
  o_002_two_ways,
  o_003_road_sign,
  o_004_barrier,
  o_005_right_arrow,
  o_006_roundabout,
  o_007_prohibition,
  o_008_curves,
  o_009_intersection_3,
  o_010_caravan_1,
  o_011_caravan,
  o_012_highway,
  o_013_traffic_sign_1,
  o_014_traffic_sign,
  o_015_warning_2,
  o_016_gas_station,
  o_017_turn_right_2,
  o_018_coffee_shop,
  o_019_bicycle_1,
  o_020_bicycle,
  o_021_bump,
  o_022_intersection_2,
  o_023_turn_1,
  o_024_turn_right_1,
  o_025_turn_left_1,
  o_026_intersection_1,
  o_027_intersection,
  o_028_waiting,
  o_029_horn,
  o_030_narrow_road,
  o_031_warning_1,
  o_032_hill,
  o_033_truck,
  o_034_pedestrian_2,
  o_035_pedestrian_1,
  o_036_traffic_light,
  o_037_turn,
  o_038_slippery,
  o_039_no_entry,
  o_040_speed_limit,
  o_041_humps,
  o_042_falling_rocks,
  o_043_pedestrian,
  o_044_turn_right,
  o_045_turn_left,
  o_046_u_turn,
  o_047_warning,
  o_048_no_parking,
  o_049_parking,
  o_050_danger,
  o_001_pirate_3,
  o_001_whale,
  o_002_hook,
  o_002_lifesaver,
  o_003_round_shot,
  o_003_sailor_cap_2,
  o_004_anchor_2,
  o_004_starfish,
  o_005_anchor_1,
  o_005_cannon,
  o_006_dolphin,
  o_006_parrot,
  o_007_island,
  o_007_knot_1,
  o_008_pirate_2,
  o_008_sailor_cap_1,
  o_009_flag,
  o_009_key,
  o_010_barrel,
  o_010_seagull_1,
  o_011_boat,
  o_011_paddle,
  o_012_pirate_ship,
  o_012_porthole,
  o_013_bollard,
  o_013_gold_ingots,
  o_014_lighthouse_1,
  o_014_placeholder,
  o_015_buoys,
  o_015_skull_and_bones,
  o_016_flippers,
  o_016_sword,
  o_017_pirate_1,
  o_017_shirt,
  o_018_chains,
  o_018_pipe,
  o_019_eyepatch,
  o_019_pipe_1,
  o_020_compass_2,
  o_020_gallows,
  o_021_coin,
  o_021_radar_1,
  o_022_compass_1,
  o_022_rum,
  o_023_money_bag,
  o_023_radar,
  o_024_diving_helmet,
  o_024_pirate,
  o_025_bottle,
  o_025_treasure_map,
  o_026_captain,
  o_026_wooden_leg,
  o_027_helm,
  o_027_yatch,
  o_028_message_in_a_bottle,
  o_028_oars,
  o_029_lighthouse,
  o_029_ship,
  o_030_binoculars,
  o_030_torch,
  o_031_jolly_roger,
  o_031_propeller,
  o_032_bell,
  o_032_hook,
  o_033_dice,
  o_033_map,
  o_034_sailor_cap,
  o_034_telescope,
  o_035_knot,
  o_035_tortoise,
  o_036_letter,
  o_036_waves,
  o_037_anchor,
  o_037_pipe,
  o_038_compass,
  o_038_lifebuoy,
  o_039_bomb,
  o_039_rudder_1,
  o_040_sailboat,
  o_040_treasure,
  o_041_starfish,
  o_042_lighthouse,
  o_043_spyglass,
  o_044_fish,
  o_045_compass,
  o_046_anchor,
  o_047_seagull,
  o_048_rudder,
  o_049_snorkel,
  o_050_life_jacket,
  o_001_air_pump,
  o_002_helicopter,
  o_003_defibrillator,
  o_004_toolbox,
  o_005_smartphone,
  o_006_heart,
  o_007_hospital_2,
  o_008_tools,
  o_009_caution,
  o_010_hospital_1,
  o_011_pin,
  o_012_blood_transfusion,
  o_013_firefighter_1,
  o_014_barrier,
  o_015_fire_truck,
  o_016_police_badge,
  o_017_hospital,
  o_018_bucket,
  o_019_smoke_detector,
  o_020_shovel,
  o_021_walkie_talkie,
  o_022_firefighter,
  o_023_stretcher,
  o_024_lifebuoy,
  o_025_fire_extinguisher,
  o_026_warning,
  o_027_hydrant,
  o_028_matches,
  o_029_phone_call,
  o_030_life_jacket,
  o_031_band_aid,
  o_032_lighter,
  o_033_megaphone,
  o_034_sos,
  o_035_whistle,
  o_036_flashlight,
  o_037_sprinkler,
  o_038_fire_alarm_1,
  o_039_gas_mask,
  o_040_ambulance,
  o_041_emergency_call,
  o_042_first_aid_kit,
  o_043_fire,
  o_044_hose,
  o_045_exit,
  o_046_axe,
  o_047_cone,
  o_048_ladder,
  o_049_siren,
  o_050_fire_alarm,
  ambulance,
  band_aid,
  bandage,
  blood_donation,
  blood_sample,
  blood_transfusion,
  broken_bone,
  cardiogram,
  clinic_history,
  coffee_cup,
  crutches,
  dna,
  dropper,
  electrocardiogram,
  eye,
  eye_drops,
  first_aid_kit,
  flask,
  hospital,
  hospital_1,
  hospital_2,
  hospital_bed,
  id_card,
  mask,
  medicine,
  medicine_1,
  medicine_2,
  medicine_3,
  medicine_4,
  microscope,
  optometry,
  pill,
  pills,
  pills_1,
  prescription,
  serum,
  stethoscope,
  tag,
  test_tube,
  thermometer,
  tooth,
  vaccine,
  virus,
  o_001_fire,
  o_002_umbrella,
  o_003_shield_1,
  o_004_lifebuoy,
  o_005_cone,
  o_006_eye,
  o_007_helmet,
  o_008_safety_belt,
  o_009_first_aid_kit,
  o_010_barbed_wire,
  o_011_shield,
  o_012_cctv_1,
  o_013_cctv,
  o_014_fire_alarm_1,
  o_015_hose,
  o_016_hydrant,
  o_017_flasher,
  o_018_kitchen_hood,
  o_019_air_compressor,
  o_020_gas_mask,
  o_021_carbon_monoxide,
  o_022_extinguisher,
  o_023_sprinkler,
  o_024_pull,
  o_025_fire_alarm,
  o_026_smoke_detector,
  o_027_safety_code,
  o_028_signal,
  o_029_strongbox,
  o_030_password,
  o_031_key,
  o_032_keyhole,
  o_033_exit_1,
  o_034_door,
  o_035_padlock_1,
  o_036_padlock,
  o_037_house,
  o_038_exit,
  o_039_bell,
  o_040_stop,
  o_041_radiation,
  o_042_forbidden,
  o_043_not_touch,
  o_044_no_smoking,
  o_045_no_fire,
  o_046_high_voltage,
  o_047_fire_sign,
  o_048_warning,
  o_049_bomb,
  o_050_antenna,
  o_001_knife,
  o_002_bomb_1,
  o_003_radar,
  o_004_medal_1,
  o_005_launcher_1,
  o_006_helmet,
  o_007_chamber,
  o_008_helicopter,
  o_009_soldier,
  o_010_lieutenant,
  o_011_shotgun,
  o_012_backpack,
  o_013_bomb_detonation_1,
  o_014_bomb_detonation,
  o_015_fighter_plane,
  o_016_dynamite,
  o_017_first_aid_kit_1,
  o_018_boat,
  o_019_submarine,
  o_020_compass,
  o_021_insignia,
  o_022_first_aid_kit,
  o_023_placeholder,
  o_024_jet,
  o_025_flag,
  o_026_boots,
  o_027_gas_mask,
  o_028_bullet_1,
  o_029_launcher,
  o_030_satellite_dish,
  o_031_parachute,
  o_032_gun,
  o_033_bulletproof,
  o_034_swiss_army_knife,
  o_035_bullet,
  o_036_binoculars,
  o_037_target,
  o_038_walkie_talkie,
  o_039_rank,
  o_040_barbed,
  o_041_bomb,
  o_042_radiation,
  o_043_chevron,
  o_044_map,
  o_045_medal,
  o_046_tank,
  o_047_tent,
  o_048_dog_tags_military,
  o_049_grenade,
  o_050_badge,
  adhesive_tape,
  bookshelf,
  briefcase,
  business_card,
  cabinet,
  computer,
  copy_machine,
  documents,
  fax,
  folder,
  folder_1,
  folder_2,
  garbage,
  headphones,
  highlighter,
  hole_puncher,
  lamp,
  letters,
  mail,
  mouse,
  mug,
  note,
  notebook,
  notebook_1,
  office_chair,
  paper_shredder,
  pen,
  pencil_case,
  plant,
  presentation,
  printer,
  projector,
  router,
  rubber_stamp,
  stapler,
  table,
  telephone,
  uniform,
  webcam,
  o_001_couple_3,
  o_002_house,
  o_003_stroller,
  o_004_son,
  o_005_suitcase,
  o_006_family_2,
  o_007_pet_house,
  o_008_cups,
  o_009_photo_album,
  o_010_fireplace,
  o_012_insurance,
  o_013_swing,
  o_014_sofa,
  o_015_couple_2,
  o_016_home_1,
  o_017_daughter,
  o_018_gift_1,
  o_019_shopping_basket,
  o_020_television,
  o_021_divorce,
  o_022_picture_1,
  o_023_briefcase,
  o_024_graduate,
  o_025_pacifier,
  o_026_beach_ball,
  o_027_love,
  o_028_couple_1,
  o_029_couple,
  o_030_scooter,
  o_031_gift,
  o_032_picture,
  o_033_wedding_rings,
  o_034_wedding_cake,
  o_035_newlyweds,
  o_036_grave,
  o_037_crib,
  o_038_cat,
  o_039_father,
  o_040_dog,
  o_041_care,
  o_042_family_1,
  o_043_grandparents,
  o_044_family,
  o_045_home,
  o_046_motherhood,
  o_047_grandfather,
  o_048_grandmother,
  o_049_newborn,
  o_050_mother,
  alarm_clock,
  alcoholic,
  alcoholic_1,
  bake,
  bathing,
  bedroom,
  bedroom_1,
  bedroom_2,
  bedroom_3,
  bedroom_4,
  bedroom_5,
  bedroom_6,
  bookcase,
  carpet,
  chairs,
  chairs_1,
  chairs_2,
  chinese_food,
  coffee_cup_1,
  coffee_cup_2,
  coffee_shop,
  coffees,
  comfortable,
  comfortable_1,
  coolnes,
  couch,
  door_key,
  drinking,
  elegant,
  firefighting,
  fry,
  game_console,
  garbage_bin,
  gardening,
  hair_salon,
  hangers,
  home_repair,
  hot_drink,
  hygienic,
  hygienic_1,
  invention,
  japanese_food,
  kitchen_pack,
  kitchen_pack_1,
  kitchen_pack_2,
  kitchen_pack_3,
  kitchen_pack_4,
  kitchen_pack_5,
  kitchen_pack_6,
  kitchen_pack_7,
  kitchen_pack_8,
  kitchen_pack_9,
  kitchen_pack_10,
  kitchen_pack_11,
  kitchen_pack_12,
  kitchen_pack_13,
  kitchen_pack_14,
  kitchen_pack_15,
  kitchen_pack_16,
  kitchen_tools,
  laptop_computer,
  livingroom,
  livingroom_1,
  livingroom_2,
  passkey,
  phone_call,
  phone_receiver,
  plugging,
  plugging_1,
  protein,
  real_estate,
  real_estate_1,
  real_estate_2,
  real_estate_3,
  reflecting,
  refreshment,
  restauran,
  restroom,
  seat,
  seatting,
  showers,
  sinks,
  sleepy,
  studying,
  studying_1,
  studying_2,
  supper,
  technological,
  televisions,
  televisions_1,
  ventilator,
  wooden_chair,
  yard,
  c_3d_glasses,
  antenna,
  camera,
  camera_1,
  camera_2,
  camera_3,
  cd,
  chat,
  clapperboard,
  cloud,
  dvd,
  equalizer,
  film,
  floppy_disk,
  game,
  ipod,
  ipod_shuffle,
  keyboard,
  loudspeaker,
  loudspeaker_1,
  memory_card,
  microphone,
  microphone_1,
  mobile_phone,
  modem,
  negative,
  negative_film,
  next_track,
  pause,
  photos,
  play,
  previous_track,
  radio,
  rec,
  speaker,
  stop,
  tablet,
  tv,
  tv_1,
  web_cam,
  o_093_chevron,
  o_093_chevron_1,
  o_093_chevron_2,
  o_093_chevron_3,
  o_093_chevron_4,
  o_093_chevron_5,
  o_093_chevron_6,
  o_093_chevron_7,
  o_093_chevron_8,
  o_093_chevron_9,
  o_093_chevron_10,
  o_093_chevron_11,
  o_093_compress,
  o_093_compress_1,
  o_093_curved_arrow,
  o_093_curved_arrow_1,
  o_093_curved_arrow_2,
  o_093_curved_arrow_3,
  o_093_diagonal_arrow,
  o_093_diagonal_arrow_1,
  o_093_diagonal_arrow_2,
  o_093_diagonal_arrow_3,
  o_093_diagonal_arrow_4,
  o_093_diagonal_arrow_5,
  o_093_diagonal_arrow_6,
  o_093_diagonal_arrow_7,
  o_093_diagonal_arrow_8,
  o_093_diagonal_arrow_9,
  o_093_diagonal_arrow_10,
  o_093_diagonal_arrow_11,
  o_093_diagonal_arrow_12,
  o_093_diagonal_arrow_13,
  o_093_diagonal_arrow_14,
  o_093_diagonal_arrow_15,
  o_093_down_arrow,
  o_093_down_arrow_1,
  o_093_down_arrow_2,
  o_093_down_arrow_3,
  o_093_down_arrow_4,
  o_093_down_arrow_5,
  o_093_exchange,
  o_093_exchange_1,
  o_093_expand,
  o_093_expand_1,
  o_093_expand_2,
  o_093_expand_3,
  o_093_left_arrow,
  o_093_left_arrow_1,
  o_093_left_arrow_2,
  o_093_left_arrow_3,
  o_093_left_arrow_4,
  o_093_left_arrow_5,
  o_093_left_arrow_6,
  o_093_move,
  o_093_move_1,
  o_093_redo,
  o_093_redo_1,
  o_093_resize,
  o_093_resize_1,
  o_093_resize_2,
  o_093_resize_3,
  o_093_right_arrow,
  o_093_right_arrow_1,
  o_093_right_arrow_2,
  o_093_right_arrow_3,
  o_093_right_arrow_4,
  o_093_right_arrow_5,
  o_093_right_arrow_6,
  o_093_right_arrow_7,
  o_093_scroll,
  o_093_scroll_1,
  o_093_sort,
  o_093_sort_1,
  o_093_sort_2,
  o_093_sort_3,
  o_093_turn_left,
  o_093_turn_left_1,
  o_093_turn_left_2,
  o_093_turn_left_3,
  o_093_turn_right,
  o_093_turn_right_1,
  o_093_turn_right_2,
  o_093_turn_right_3,
  o_093_undo,
  o_093_undo_1,
  o_093_up_arrow,
  o_093_up_arrow_1,
  o_093_up_arrow_2,
  o_093_up_arrow_3,
  o_093_up_arrow_4,
  o_093_up_arrow_5,
  o_093_up_arrow_6,
  personplaceholder,
};