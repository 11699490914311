import React, { useRef, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../api";
import AsyncSelect from "react-select/async";
import DateTime from "react-datetime";
import moment from "moment-timezone";
import CustomOptions from "./CustomOptions";
import { colourStyles } from "../../utils/staticMethods";

const ScheduleTaskModal = ({
                             action,
                             show,
                             closeModal,
                             current,
                             apiName,
                             handleSuccess,
                             curPage,
                             handleEdit,
                             handleComplete,
                             completed
                           }) => {
  const [showDelete, setShowDelete] = useState(false);
  const formRef = useRef();

  const isDateValid = (cur) => {
    return cur.endOf("days").diff(moment()) > 0;
  };

  const personPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      api.get("people?filter=" + inputValue).then(res => {
        let arr = res.data.concat([]);
        if (current?.childId && current?.childName && inputValue === "" && arr.filter(item => item.id === current.childId).length === 0) arr.push({
          id: current.childId,
          name: current.childName,
          reference: current.childReference
        });
        resolve(formatResponse(arr, 'person'));
      });
    });
  };

  const taskPromiseOptions = (inputValue) =>
    new Promise((resolve) => {
      api.get("tasks/types?filter=" + inputValue).then(res => {
        resolve(formatResponse(res.data, 'task'));
      });
    });

  const parentPromiseOptions = (inputValue) =>
    new Promise(resolve => {
      let value = inputValue;
      let apiArr = [api.get("assets?limit=10&filter=" + value), api.get("zones?limit=10&filter=" + value)];
      Promise.all(apiArr).then(res => {
        let arr = res[0].data.concat([]);
        let arr1 = res[1].data.concat([]);
        if (current?.parentId && current?.parentName && current?.parentType && inputValue === "") {
          if (current?.parentType === "asset" && arr.filter(item => item.id === current.parentId).length === 0) {
            arr.push({ id: current.parentId, name: current.parentName, reference: current.parentReference });
          }
          if (current?.parentType === "zone" && arr1.filter(item => item.id === current.parentId).length === 0) {
            arr1.push({ id: current.parentId, name: current.parentName, reference: current.parentReference });
          }
        }
        let assets = formatResponse(arr, 'asset');
        let zones = formatResponse(arr1, 'zone');
        let asyncOptions = [
          {
            label: "Assets",
            options: assets
          },
          {
            label: "Zones",
            options: zones
          }
        ];
        resolve(asyncOptions);
      });
    });

  const formatResponse = (list, type) => {
    let arr = list.map(item => {
      return { value: item.id, label: item.compoundName || item.name, reference: item.reference, icon: type };
    });
    return arr;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.typeId) {
      errors.typeId = "Task type required";
    }
    if (!values.childId) {
      errors.childId = "Person required";
    }
    if (!values.parentId) {
      errors.parentId = "Asset/Zone required";
    }
    if (!values.due) {
      errors.due = "Due date required";
    }
    if (values.due && moment(values.due).diff(moment()) < 0) {
      errors.due = "Due time already past!";
    }
    formRef.current.errors = errors;
    return errors;
  };

  const getTitle = (a) => {
    if(a === 'Create') return 'Schedule a Task'
    if(a === 'View') return current?.name
    if(a === 'Edit') return 'Edit a Task'
  }

  return <Modal show={show} onHide={() => {
    closeModal();
    setTimeout(() => setShowDelete(false), 500);
  }}>
    <Modal.Header closeButton>
      {!showDelete && <Modal.Title>{getTitle(action)}</Modal.Title>}
      {showDelete && <Modal.Title>Please Confirm</Modal.Title>}
    </Modal.Header>
    <Modal.Body>
      <Card className="mb-0">
        <Card.Body>
          {!showDelete ? <Formik
            innerRef={formRef}
            initialValues={{
              id: current?.id,
              name: current?.name || "",
              description: current?.description || "",
              statusStrings: current?.statusStrings || [],
              childName: current?.childName || "",
              childId: current?.childId || "",
              parentId: current?.parentId || "",
              parentName: current?.parentName || "",
              typeId: current?.taskTypeId || "",
              due: current?.due || ""
            }}
            validationSchema={Yup.object().shape({
              childId: Yup.string().max(255).required("Person is required"),
              parentId: Yup.string().max(255).required("Asset/Zone is required"),
              due: Yup.string().max(255).required("Due date is required")
            })}
            validate={validate}
            enableReinitialize
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              try {
                action === "Create" && api.post(apiName, {
                  typeId: values.typeId,
                  childId: values.childId,
                  parentId: values.parentId,
                  due: values.due
                })
                  .then(res => {
                    handleSuccess && handleSuccess(res.data);
                  });
                action === "Edit" && api.put(apiName, values)
                  .then(res => {
                    handleSuccess && handleSuccess(res.data);
                  });
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                {(action === "Edit" || action === "View") && <><Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <div className="text-dark">{values.name}</div>
                </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <p className="text-dark">{values.description}</p>
                  </Form.Group>

                  {(curPage || action === "View") && <Form.Group className="mb-3">
                    <Form.Label>Asset / Zone</Form.Label>
                    <div className="text-dark">{`${current.parentName} ${current.parentReference ? `(${current.parentReference})`: ''}`}</div>
                  </Form.Group>}
                  {action === "View" && <Form.Group className="mb-3">
                    <Form.Label>Person carry out the task</Form.Label>
                    <div className="text-dark">{`${current.childName} ${current.childReference ? `(${current.childReference})`: ''}`}</div>
                  </Form.Group>}
                  <Form.Group className="mb-3">
                    <Form.Label>Options</Form.Label>
                    <div className="d-flex">
                      {current.optionStrings.map((item, index) => <div key={index} style={{ borderRadius: "2px" }}
                                                                       className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-light text-black">
                        <span>{item}</span>
                      </div>)}
                    </div>
                  </Form.Group>
                </>}
                {completed && <Form.Group className="mb-3">
                  <Form.Label>Completed Options</Form.Label>
                  <div className="d-flex">
                    {current?.statusStrings && current.statusStrings.map((item, index) => <div key={index} style={{ borderRadius: "2px" }}
                                                                     className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-success text-black">
                      <span>{item}</span>
                    </div>)}
                  </div>
                </Form.Group>}

                {action !== "View" && <Form.Group className="mb-3">
                  <Form.Label>Which person would you like to carry out this task ?</Form.Label>
                  <AsyncSelect components={{Option: CustomOptions}} onChange={e => {
                    setFieldValue("childName", e.label);
                    setFieldValue("childId", e.value);
                  }} defaultInputValue={values.childName} defaultValue={{value: values.childId, label: values.childName}} styles={colourStyles}
                               defaultOptions
                               loadOptions={personPromiseOptions} />
                  {touched.childId && errors.childId && (
                    <div className="text-danger">
                      {errors.childId}
                    </div>
                  )}
                </Form.Group>}

                {action === "View" && current.due.indexOf('0001-01-01') < 0 && <Form.Group className="mb-3">
                  <Form.Label>Due Date</Form.Label>
                  <div className="text-dark">{moment(current.due).format("DD/MM/YY HH:mm")}</div>
                </Form.Group>}

                {action === "View" && completed && <Form.Group className="mb-3">
                  <Form.Label>Complete Date</Form.Label>
                  <div className="text-dark">{moment(current?.completed).format("DD/MM/YY HH:mm")}</div>
                </Form.Group>}

                {!curPage && action !== "View" && <Form.Group className="mb-3">
                  <Form.Label>Which asset or zone would you like this task to be carried out upon ?</Form.Label>
                  <AsyncSelect components={{Option: CustomOptions}}  onChange={e => {
                    setFieldValue("parentName", e.label);
                    setFieldValue("parentId", e.value);
                  }} defaultInputValue={values.parentName} defaultValue={{value: values.parentId, label: values.parentName}} styles={colourStyles}
                               defaultOptions
                               loadOptions={parentPromiseOptions} />
                  {!!touched.parentId && (
                    <div className="text-danger">
                      {errors.parentId}
                    </div>
                  )}
                </Form.Group>}
                {action === "Create" && <Form.Group className="mb-3">
                  <Form.Label>Which task type would you like to be carried out?</Form.Label>
                  <AsyncSelect components={{Option: CustomOptions}}  onChange={e => {
                    setFieldValue("name", e.label);
                    setFieldValue("typeId", e.value);
                  }} defaultInputValue={values.name} defaultValue={{value: values.typeId, label: values.name}} styles={colourStyles}
                               defaultOptions
                               loadOptions={taskPromiseOptions} />
                  {!!touched.typeId && (
                    <div className="text-danger">
                      {errors.typeId}
                    </div>
                  )}
                </Form.Group>}
                {action !== "View" && <Form.Group className="mb-3">
                  <Form.Label>When would you like this task to be done ?</Form.Label>
                  <DateTime input
                            inputProps={{ readOnly: true}}
                            className="no-change-date-picker"
                            isValidDate={isDateValid}
                            initialValue={moment(values.due)}
                            onChange={e => {
                              setFieldValue("due", e.format());
                            }}
                            timeFormat="HH:mm"
                            dateFormat="DD/MM/YY" />
                  {!!touched.due && (
                    <div className="text-danger">
                      {errors.due}
                    </div>
                  )}
                </Form.Group>}
              </Form>
            )}
          </Formik> : <span>{`Would you like to remove the task '${current?.name}'?`}</span>}
        </Card.Body>
      </Card>
    </Modal.Body>
    <Modal.Footer>
      {!showDelete && action !== "View" && <Button
        onClick={() => {
          formRef.current?.handleSubmit();
        }
        }
        variant="primary"
        size="md"
        disabled={formRef.current?.isSubmitting}
      >
        Save
      </Button>}
      {action !== "View" && <Button variant={"secondary"} onClick={() => {
        closeModal();
        setTimeout(() => setShowDelete(false), 500);
      }}>Cancel</Button>}
      {action === "View" && <>
      { !completed && <><Button onClick={() => handleEdit()}>{curPage ? "Reschedule" : "Edit"}</Button>
          <Button onClick={() => handleComplete()} variant="success">Complete</Button></>}
        <Button onClick={() => closeModal()} variant="secondary">Close</Button>
      </>}
    </Modal.Footer>
  </Modal>;
};
export default ScheduleTaskModal;