import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Form, Image, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { File } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import CommonTable from "../tables/CommonTable";
import GoogleMapReact from "google-map-react";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faBan,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBolt,
  faPlayCircle,
  faStopCircle
} from "@fortawesome/free-solid-svg-icons";
import close from "../../assets/img/svgs/close.svg";
import NotyfContext from "../../contexts/NotyfContext";
import a_0 from "../../assets/img/directions/0.png";
import a_45 from "../../assets/img/directions/45.png";
import a_90 from "../../assets/img/directions/90.png";
import a_135 from "../../assets/img/directions/135.png";
import a_180 from "../../assets/img/directions/180.png";
import a_225 from "../../assets/img/directions/225.png";
import a_270 from "../../assets/img/directions/270.png";
import a_315 from "../../assets/img/directions/315.png";
import a_360 from "../../assets/img/directions/360.png";
import SearchBox from "../../pages/maps/components/SearchBox";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import api from "../../api";
import { downloadFile, getDistance, getUrl, middle, radiusZoomLevel, resArr } from "../../utils/staticMethods";
import { globalConfig } from "../../config";
import axios from "axios";
import FloorplanSelector from "../../pages/readers/FloorplanSelector";
import useAuth from "../../hooks/useAuth";

const queryString = require("query-string");

let flightPath = null;
let markerArr = [];
let overlay1 = null;
let heatmap = null;

const getMarkerUrl = (item) => {
  let markerurl;
  if (item.heading == null) {
    markerurl = a_0;
  } else {
    let heading = item.heading;
    if (heading === 0) {
      markerurl = a_0;
    } else if (heading > 337 || heading <= 22) {
      markerurl = a_360;
    } else if (heading > 22 && heading <= 67) {
      markerurl = a_45;
    } else if (heading > 67 && heading <= 112) {
      markerurl = a_90;
    } else if (heading > 112 && heading <= 157) {
      markerurl = a_135;
    } else if (heading > 157 && heading <= 202) {
      markerurl = a_180;
    } else if (heading > 202 && heading <= 247) {
      markerurl = a_225;
    } else if (heading > 247 && heading <= 292) {
      markerurl = a_270;
    } else if (heading > 292) {
      markerurl = a_315;
    }
  }
  return markerurl;
};

const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: "absolute",
    bottom: "25px",
    left: "-75px",
    width: 150,
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    fontSize: 13,
    zIndex: 100,
    flexWrap: "nowrap",
    borderRadius: "8px"
  };
  if (!props.show) {
    return null;
  }

  if (place.speed !== null && place.speed >= 0) {
    return <div id="content" className="text-primary gm-style gm-style-iw-t" style={infoWindowStyle}>
      <div className="d-flex justify-content-end mt-1 me-1">
        <Button size="sm" variant="link" onClick={(e) => props.onClose(place, e)}><Image style={{ width: "15px" }}
                                                                                         className="cursor-pointer"
                                                                                         src={close} /></Button>
      </div>
      <div className="p-2">{moment(place.dateTime).format("DD/MM/YYYY HH:mm:ss")}</div>
      <div className="ms-2 me-2 mt-1 mb-2">Speed: {Math.round((place.speed * 0.6214))} mph</div>
    </div>;
  } else {
    return <div id="content" className="text-primary gm-style gm-style-iw-t" style={infoWindowStyle}>
      <div className="d-flex justify-content-end mt-1 me-1">
        <Button size="sm" onClick={(e) => props.onClose(place, e)} variant="link"><Image style={{ width: "15px" }}
                                                                                         className="cursor-pointer"
                                                                                         src={close} /></Button>
      </div>
      <div className="ms-2 me-2 mt-1">{moment(place.dateTime).format("DD/MM/YYYY HH:mm:ss")}</div>
    </div>;
  }
};

let source = null;
let timer = null;
const Positions = () => {

  const location = useLocation();
  const { id, name, type } = queryString.parse(location.search);
  const [positions, setPositions] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [resultCount, setResultCount] = useState(0);
  const [mapInstance, setInstance] = useState();
  const mapRef = useRef(mapInstance);
  const [positionTable, setPositionTable] = useState([]);
  const [heatMap, setHeatMap] = useState(false);
  const [mapApi, setApi] = useState();
  const apiRef = useRef(mapApi);
  const [duration, setDuration] = useState();
  const [address, setAddress] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [findAddress, setFindAddress] = useState(null);
  const [curFloor, setCurFloor] = useState(null);
  const curFloorRef = useRef(curFloor);
  const [noData, setNoData] = useState();
  const [firstLoad, setFirstLoad] = useState(false);
  const [picture, setPicture] = useState();
  const [loadingPlan, setLoadingPlan] = useState(true);
  const notify = useContext(NotyfContext);
  const { user } = useAuth();

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 0,
      scrollwheel: false,
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    };
  };

  const columns = [
    {
      Header: "Type",
      accessor: "type",
      Cell: cell => {
        if (cell.value === "position") {
          return <><FontAwesomeIcon icon={faArrowCircleUp} /><span className="text-primary cursor-pointer"
                                                                   onClick={(e) => {
                                                                     setPositions(pre => {
                                                                       let newP = pre.concat([]);
                                                                       newP.filter(info => info.id === cell?.row?.original.id)[0].show = true;
                                                                       return newP;
                                                                     });
                                                                     let latLng = new mapApi.LatLng(cell?.row?.original?.latitude, cell?.row?.original?.longitude);
                                                                     mapInstance.panTo(latLng);
                                                                   }}><span> Position</span></span></>;
        } else if (cell.value === "start") {
          return <><FontAwesomeIcon icon={faPlayCircle} /><span className="text-primary cursor-pointer"
                                                                onClick={(e) => {
                                                                  setPositions(pre => {
                                                                    let newP = pre.concat([]);
                                                                    newP.filter(info => info.id === cell?.row?.original.id)[0].show = true;
                                                                    return newP;
                                                                  });
                                                                  let latLng = new mapApi.LatLng(cell?.row?.original?.latitude, cell?.row?.original?.longitude);
                                                                  mapInstance.panTo(latLng);
                                                                }}><span> Start</span></span></>;
        } else if (cell.value === "stop") {
          return <><FontAwesomeIcon icon={faStopCircle} /><span className="text-primary cursor-pointer"
                                                                onClick={(e) => {
                                                                  setPositions(pre => {
                                                                    let newP = pre.concat([]);
                                                                    newP.filter(info => info.id === cell?.row?.original.id)[0].show = true;
                                                                    return newP;
                                                                  });
                                                                  let latLng = new mapApi.LatLng(cell?.row?.original?.latitude, cell?.row?.original?.longitude);
                                                                  mapInstance.panTo(latLng);
                                                                }}><span> Stop</span></span></>;
        } else if (cell.value === "charging stop") {
          return <><FontAwesomeIcon icon={faBan} /><span className="text-primary cursor-pointer" onClick={(e) => {
            setPositions(pre => {
              let newP = pre.concat([]);
              newP.filter(info => info.id === cell?.row?.original.id)[0].show = true;
              return newP;
            });
            let latLng = new mapApi.LatLng(cell?.row?.original?.latitude, cell?.row?.original?.longitude);
            mapInstance.panTo(latLng);
          }}><span> Charging Stop</span></span></>;
        } else {
          return <><FontAwesomeIcon icon={faBolt} /> <span className="text-primary cursor-pointer" onClick={(e) => {
            setPositions(pre => {
              let newP = pre.concat([]);
              newP.filter(info => info.id === cell?.row?.original.id)[0].show = true;
              return newP;
            });
            let latLng = new mapApi.LatLng(cell?.row?.original?.latitude, cell?.row?.original?.longitude);
            mapInstance.panTo(latLng);
          }}><span> Charging Start</span></span></>;
        }
      }
    },
    {
      Header: "Date/Time",
      accessor: "dateTime",
      type: "date",
      dateFormat: "DD/MM/YYYY HH:mm:ss"
    },
    {
      Header: "Location",
      accessor: "latitude",
      Cell: cell => (
        <span>{Number(cell.row.original?.latitude).toFixed(6)}, {Number(cell.row.original?.longitude).toFixed(6)}
          <span
            className="text-primary cursor-pointer ms-1"
            onClick={() => {
              showAddress(cell.row.original?.latitude, cell.row.original?.longitude);
            }}>Show Address</span></span>
      )
    },
    {
      Header: "Speed",
      accessor: "speed",
      Cell: cell => {
        return (
          <span>{cell.value !== null && cell.value >= 0 ? Math.round((cell.value * 0.6214)) + " mph" : "-"}</span>
        );
      }
    },
    {
      Header: "Supply",
      accessor: "supply",
      Cell: cell => (
        <span>{cell.value ? cell.value + " Mv" : "-"}</span>
      )
    },
    {
      Header: "Battery",
      accessor: "battery",
      Cell: cell => {
        if (cell.value !== null && cell.value !== "") {
          if (cell.value > 75) {
            return <><FontAwesomeIcon icon={faBatteryFull} /> {cell.value}<span> %</span></>;
          } else if (cell.value > 50 && cell.value <= 75) {
            return <><FontAwesomeIcon icon={faBatteryThreeQuarters} /> {cell.value} <span> %</span></>;
          } else if (cell.value >= 25 && cell.value <= 50) {
            return <><FontAwesomeIcon icon={faBatteryHalf} /> {cell.value} <span> %</span></>;
          } else {
            return <><FontAwesomeIcon icon={faBatteryEmpty} /> {cell.value} <span> %</span></>;
          }
        } else {
          return "-";
        }
      }
    }
  ];

  useEffect(() => {
    return () => {
      markerArr.forEach(item => {
        item.setMap(null);
      });
      markerArr = [];
      flightPath = null;
      heatmap && heatmap.setMap(null)
      heatmap = null
    };
  }, []);


  useEffect(() => {
    if (user && !user.showFloorPlans) {
      setLoadingPlan(false);
    }
  }, [user]);

  const showAddress = (lat, lng) => {
    if (!mapApi) {
      notify.open({
        type: "error",
        message: "Google Map Api errors, please refresh the page and try again"
      });
      return;
    }
    setFindAddress(true);
    getReverseGeocodingData(lat, lng);
  };

  const getReverseGeocodingData = (lat, lng) => {
    let latlng = new mapApi.LatLng(lat, lng);
    let geocoder = new mapApi.Geocoder();
    geocoder.geocode({ "location": latlng }, (results, status) => {
      if (status !== mapApi.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === mapApi.GeocoderStatus.OK) {
        setAddress((results[0].formatted_address));
      }
    });
  };

  const getZone = () => {
    if (!mapRef.current) return;
    if (source) {
      source.cancel("request canceled");
    }
    source = axios.CancelToken.source();
    api.get(`maps/nearby?radius=${radiusZoomLevel[mapRef.current.getZoom()]}&latitude=${mapRef.current.getCenter().lat()}&longitude=${mapRef.current.getCenter().lng()}&limit=100`, {
      cancelToken: source.token
    }).then(res => {
      let arr = res.data.filter(item => item.type === "zone");
      let newArr = resArr(arr, markerArr);
      let removeArr = resArr(markerArr, arr);
      newArr.forEach(item => {
        let m = new apiRef.current.Marker({
          id: item.id,
          type: "zones",
          asset: item.type === "asset" ? 1 : 0,
          person: item.type === "person" ? 1 : 0,
          zone: item.type === "zone" ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
          label: {
            text: item.compoundName,
            color: curFloorRef.current ? "#FFFFFF" : "#000000",
            fontWeight: "bold",
            className: curFloorRef.current ? "marker-position-bottom bg-danger ps-1 pe-1 radius-3" : "marker-position-bottom"
          },
          icon: {
            url: imageSet[getUrl(item.icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
            scaledSize: new apiRef.current.Size(24, 24)
          },
          anchor: new apiRef.current.Point(14, 43),
          map: mapRef.current
        });
        markerArr.push(m);
        if (curFloorRef.current) return;
        if (item.radius) {
          let c = new apiRef.current.Circle({
            id: item.id,
            ids: [item],
            idsArr: [item.id],
            strokeColor: "#090B29",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#090B29",
            fillOpacity: 0.4,
            shape: "circle",
            type: "zone",
            center: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
            map: mapRef.current,
            radius: Number(item.radius)
          });
          m.diam = Number(item.radius) * 2;
          markerArr.push(c);
        } else if (item.polygon) {
          let firstItem = item.polygon[0].split(",");
          let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
          let arr1 = item.polygon.map(item => {
            let me = item.split(",");
            let lng = Number(me[0]);
            let lat = Number(me[1]);
            if (lat > maxLat.lat) maxLat = { lat, lng };
            if (lat < minLat.lat) minLat = { lat, lng };
            if (lng > maxLng.lng) maxLng = { lat, lng };
            if (lng < minLng.lng) minLng = { lat, lng };
            return { lng, lat };
          });
          let polygon = new apiRef.current.Polygon({
            paths: arr1,
            idsArr: [item.id],
            id: item.id,
            ids: [item],
            type: "zone",
            shape: "polygon",
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#242424",
            fillOpacity: 0.4,
            editable: false,
            map: mapRef.current
          });
          markerArr.push(polygon);
        }
      });
      removeArr.forEach(item => {
        item.setMap(null);
        for (let i = 0; i < markerArr.length; i++) {
          if (markerArr[i].id === item.id) {
            markerArr.splice(i, 1);
            break;
          }
        }
      });
    });
  };

  useEffect(() => {
    if (mapApi && mapInstance) {
      onGetList();
    }
  }, [mapApi, mapInstance]);

  useEffect(() => {
    if (mapApi && mapInstance && positions && positions.length > 0) {
      let pathArr = [];
      let heatArr = []
      positions.forEach(item => {
        pathArr.push({ lat: Number(item.latitude), lng: Number(item.longitude) });
        heatArr.push(new mapApi.LatLng(item.latitude, item.longitude))
      });
      flightPath && flightPath.setMap(null);
      heatmap && heatmap.setMap(null);
      heatmap = null;
      flightPath = null;
      heatmap = new mapApi.visualization.HeatmapLayer({
        data: heatArr,
        radius: 20
      });
      flightPath = new mapApi.Polyline({
        path: pathArr,
        geodesic: true,
        strokeColor: "#0a192e",
        strokeOpacity: 1.0,
        strokeWeight: 3
      });
      !heatMap && flightPath.setMap(mapInstance);
      heatMap && heatmap.setMap(mapInstance);
      !curFloor && mapInstance.setZoom(18);
    }
  }, [mapApi, mapInstance, positions]);

  const onGetList = () => {
    api.get(`assets/positions/${id}?page=0&limit=0${duration ? `&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}` : ""}`).then(r => {
      setPositions(r.data);
      if (r.data.length > 0) {
        let end = moment(r.data[0].dateTime).endOf("days");
        let start = moment(r.data[r.data.length - 1].dateTime).startOf("days");
        !duration && !loaded && setDuration([start, end]);
      }
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }).catch(() => {
      setPositions(null);
    });
  };

  useEffect(() => {
    if (loaded && firstLoad) {
      onGetList();
    }
  }, [duration, loaded]);

  useEffect(() => {
    if (!positions && positionTable.length > 0) {
      setPositions(positionTable);
      setErrorText("Too many records are requested, now only show the data for current page");
    }
    if (positions && positionTable.length > 0 && errorText) {
      setPositions(positionTable);
    }
  }, [positions, positionTable]);

  const getList = (data) => {
    setPositionTable(data);
  };

  const onClose = (item, e) => {
    e.stopPropagation();
    let newP = Array.from(positions);
    let filter = newP.findIndex(info => info.id === item.id);
    newP[filter]["show"] = false;
    setPositions(newP);
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    mapRef.current = map;
    apiRef.current = maps;
    map.addListener("bounds_changed", () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => getZone(), 500);
    });
  };

  const resetDuration = (start, end) => {
    if (duration && duration[0].isSame(start) && duration[1].isSame(end)) return;
    setDuration([start, end]);
  };
  const handleExport = () => {
    api.get(`${type}/positions/${id}/export?startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DDTHH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DDTHH:mm")}`, { responseType: "blob" }).then(res => downloadFile(res));
  };

  const getPci = () => {
    api.get(`files/floorplans/${curFloor.id}`, {
      responseType: "arraybuffer"
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(() => setPicture(null));
  };

  useEffect(() => {
    if (mapApi && mapInstance && curFloor) {
      getPci();
      mapInstance.setOptions({ mapTypeControl: false, streetViewControl: false });
      markerArr.forEach(item => {
        if (item.type === "zone") {
          item.setMap(null);
        }
        if (item.type === "zones") {
          item.label.className = "marker-position-bottom bg-danger ps-1 pe-1 radius-3";
        }
      });
      markerArr = markerArr.filter(item => item.type !== "zone");
    } else if (mapApi && mapInstance && !curFloor) {
      setPicture(null);
      overlay1 && overlay1.setMap(null);
      overlay1 = null;
      mapInstance.setOptions({ mapTypeControl: true, streetViewControl: true });
    }
  }, [mapApi, mapInstance, curFloor]);

  useEffect(() => {
    if (picture) initFloorPlan();
  }, [picture]);

  const initFloorPlan = () => {
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0);
      if (overlay1) {
        overlay1.setMap(null);
        overlay1 = null;
      }
      let northWest = new mapApi.LatLng(curFloor ? (Number(curFloor.northWestLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.northWestLongitude) || 0.1276) : 0.1276);
      let southEast = new mapApi.LatLng(curFloor ? (Number(curFloor.southEastLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.southEastLongitude) || 0.1276) : 0.1276);
      overlay1 = new mapApi.OverlayView();
      overlay1.div = null;
      window.overlay = overlay1;
      overlay1.image = picture;
      overlay1.draw = function() {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(
          southEast
        );
        const nw = overlayProjection.fromLatLngToDivPixel(
          northWest
        );
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      };
      overlay1.onRemove = function() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      overlay1.onAdd = function() {
        this.div = document.createElement("div");
        this.div.id = "whole-container";
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      };
      overlay1.setMap(mapInstance);
      let d = getDistance(northWest, southEast);
      let keys = Object.keys(radiusZoomLevel).reverse();
      let value = 1000000;
      let index = 0;
      for (let i = 0; i < keys.length; i++) {
        let v = Math.abs(radiusZoomLevel[keys[i]] - d);
        if (v < value) {
          value = v;
          index = keys[i];
        }
      }
      mapInstance.setZoom(Number(index));
      mapInstance.panTo(middle(northWest, southEast));
      setTimeout(() => {
        setLoadingPlan(false);
      }, 800);
    }
  };

  const onSelect = (data) => {
    if (data === null) {
      setLoadingPlan(false);
      setNoData(true);
    } else {
      setLoadingPlan(true);
    }
    setCurFloor(data);
    curFloorRef.current = data;
    setFirstLoad(true);
  };

  useEffect(() => {
    if(heatMap) {
      heatmap.setMap(mapInstance);
      flightPath.setMap(null);
    } else {
      heatmap && heatmap.setMap(null)
      flightPath && flightPath.setMap(mapInstance);
    }
  }, [heatMap, positions])


  return <React.Fragment>
    <Helmet defer={false} title="Reporting" />
    <h3 className="mb-3">Reporting - <NavLink to={{ pathname: "/assets/edit", search: `?id=${id}` }}>{name}</NavLink>
    </h3>
    {errorText && <div className="p-3 bg-light">{errorText}</div>}
    <div style={{ height: 500, width: "100%", position: "relative" }}
         className={`position-relative ${curFloor ? "hidden-map floorplan-zone" : ""}`}>
      {!noData && user?.showFloorPlans && <FloorplanSelector showDefault onSelect={onSelect} asset={id} />}
      {loadingPlan &&
      <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" />
      </div>}
      <GoogleMapReact
        options={getMapOptions}
        bootstrapURLKeys={{
          key: globalConfig.googleMapKey,
          libraries: ["places", "geometry", "drawing", "visualization"]
        }}
        center={{
          lat: positions && positions.length > 0 ? Number(positions[0]?.latitude) : 2.8,
          lng: positions && positions.length > 0 ? Number(positions[0]?.longitude) : -187.3
        }}
        defaultZoom={2}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        {mapInstance && mapApi && <SearchBox hide={curFloor} map={mapInstance} mapApi={mapApi} />}
        {mapApi && !heatMap && mapInstance && positions && positions.length > 0 && positions.map(item => (
          <MarkerComponent showInfo={(item) => {
            setPositions(pre => {
              let newP = pre.concat([]);
              newP.filter(info => info.id === item.id)[0].show = true;
              return newP;
            });
            let latLng = new mapApi.LatLng(item.latitude, item.longitude);
            mapInstance.panTo(latLng);
          }} item={item} onClose={(info, e) => onClose(info, e)} key={item.id} lat={item.latitude}
                           lng={item.longitude} />))}
      </GoogleMapReact>
    </div>
    <div className="tab mt-3">
      <Tab.Container id="left-tabs-example" defaultActiveKey="table">
        <Nav variant="tabs">
          <Nav.Link eventKey="table">Table</Nav.Link>
          <Nav.Link eventKey="export">Export</Nav.Link>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="table">
            {mapApi && <Form.Check
              type="switch"
              label="Heatmap"
              style={{float: 'right'}}
              defaultChecked={heatMap}
              onChange={(e) => setHeatMap(e.target.checked)}
            />}
            <CommonTable hideSearch onGetList={getList} durationSubstract={duration} showRange
                         setCountParent={setResultCount}
                         durationParam="dateTime"
                         resetDuration={resetDuration}
                         delayLoading={!!(mapApi && mapInstance && loaded)}
                         apiName={`assets/positions/${id}`} columns={columns} />
          </Tab.Pane>
          <Tab.Pane eventKey="export">
            {Number(resultCount) > 0 && <>
              <h4 className="tab-title d-flex align-items-center"><File className="me-1" size={18} />Export</h4>
              <p>This will create a csv export file containing all data recorded between the selected dates.</p>
              <div>
                <Button variant="success" onClick={() => handleExport()}>Export</Button>
              </div>
            </>}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
    <Modal show={findAddress} onHide={() => {
      setFindAddress(false);
      setAddress(null);
    }}>
      <Modal.Header closeButton><Modal.Title>Address Search</Modal.Title></Modal.Header>
      <Modal.Body>
        {address ? <p>{address}</p> : <p>Searching for address...</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          setFindAddress(false);
          setAddress(null);
        }}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};
const MarkerComponent = ({ item, onClose, showInfo }) => {
  return <div onClick={(e) => {
    showInfo(item);
  }} style={{
    width: "20px",
    height: "20px",
    background: "#314A5E",
    display: "flex",
    borderRadius: "8px",
    marginLeft: "-10px",
    marginTop: "-10px",
    cursor: "pointer"
  }}>
    <img src={getMarkerUrl(item)} alt="marker-icon" />
    <InfoWindow show={item.show} onClose={(info, e) => onClose(info, e)} place={item} />
  </div>;
};

export default Positions;