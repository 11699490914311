import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import AssetBatteryExchanges from "../statistics/AssetBatteryExchanges";
import AssetUtilisation from "../statistics/AssetUtilisation";
import AssetAverageBatteryLifespan from "../statistics/AssetAverageBatteryLifespan";
import AssetBatteryVoltage from "../statistics/AssetBatteryVoltage";
import AssetBatteryLevel from "../statistics/AssetBatteryLevel";

const StatisticsTab = ({ data, tab, id, asset }) => {

  const getComponent = (props) => {
    const { name } = props;
    let obj = JSON.parse(JSON.stringify(props))
    obj.assetId = id
    obj.asset = asset
    if (name === "AssetBatteryExchanges") {
      return <AssetBatteryExchanges {...obj} />;
    } else if (name === "AssetUtilisation") {
      return <AssetUtilisation {...obj} />;
    } else if (name === "AssetAverageBatteryLifespan") {
      return <AssetAverageBatteryLifespan {...obj} />;
    } else if (name === "AssetBatteryVoltage") {
      return <AssetBatteryVoltage {...obj} />;
    } else if (name === "AssetBatteryLevel") {
      return <AssetBatteryLevel {...obj} />;
    }
    return null;
  };

  return <Card>
    {tab === 'statistics' && <Card.Body>
      <Row className="pb-4">
        {data.map(item =>
          <Col key={item.id} lg={item.width / 100 * 12} className="mb-2 d-flex flex-column">
            {getComponent(item)}
          </Col>
        )}
      </Row>
    </Card.Body>}
  </Card>;
};
export default StatisticsTab;