import React from "react";

import { Card, Col } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink } from "react-router-dom";


const RecentItems = ({ width, name }) => {

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      className: 'w-10',
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "name",
      disableSortBy: true,
      Cell: cell => (
        <NavLink to={{ pathname: cell.row.original.link, search: cell.row.original.search }}>{cell.value}</NavLink>
      )
    }
  ];


  return (
    <React.Fragment>
      <Col key={name} lg={width / 100 * 12} className="mb-4">
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h4 style={{ color: "#E0E0E0" }}>Recent Items</h4>
          </div>
          <CommonTable singlePage showRange={false} type="dashboard" data={JSON.parse(localStorage.getItem("recentItem")) || []}
                       columns={columns} emptyMessage="No Recent Items" />
        </Card>
      </Col>
    </React.Fragment>
  );
}
;

export default RecentItems;
