import React from "react";

// All widgetPages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Assets
import Assets from "./pages/assets/Assets";
import AssetsEdit from "./pages/assets/AssetsEdit";

// Access Profiles
import AccessProfiles from "./pages/accessprofiles/AccessProfiles";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Dashboards
import Default from "./pages/dashboards";


// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// UI components
import Alerts from "./pages/alerts/Alerts";

// Protected routes
import Icons from "./pages/icons/AssetIcon/Icons";
import Attendance from "./components/report/Attendance";
import AccessProfilesEdit from "./pages/accessprofiles/AccessProfilesEdit";
import HashTags from "./pages/hastags/Hashtags";
import People from "./pages/people/People";
import Zones from "./pages/zones/Zones";
import Trackers from "./pages/trackers/Trackers";
import Readers from "./pages/readers/Readers";
import Tags from "./pages/tags/Tags";
import ContactGroups from "./pages/contactgroups/ContactGroups";
import Rules from "./pages/rules/Rules";
import ZonesEdit from "./pages/zones/ZonesEdit";
import PeopleEdit from "./pages/people/PeopleEdit";
import Create from "./pages/rules/Create";
import RulesEdit from "./pages/rules/RulesEdit";
import Positions from "./components/report/Positions";
import ContactEdit from "./pages/contactgroups/ContactEdit";
import PasswordReset from "./pages/auth/PasswordReset";
import GlobalSearch from "./pages/search/GlobalSearch";
import Customers from "./pages/customers/Customers";
import CustomerEdit from "./pages/customers/CustomerEdit";
import DeviceProfile from "./pages/deviceProfile/DeviceProfile";
import DeviceEdit from "./pages/deviceProfile/DeviceEdit";
import ReaderEdit from "./pages/readers/ReaderEdit";
import TagEdit from "./pages/tags/TagEdit";
import TrackerEdit from "./pages/trackers/TrackerEdit";
import RealLocation from "./components/detail/RealLocation";
import MapDashboard from "./pages/maps/MapDashboard";
import CreateWizard from "./pages/rules/CreateWizard";
import FavouritePage from "./pages/widgetPages/FavouritePage";
import TaskTypes from "./pages/task/TaskTypes";
import TaskScheduled from "./pages/task/TaskScheduled";
import CompletedTask from "./pages/task/CompletedTask";
import OutstandingTask from "./pages/task/OutlandingTask";
import ScanResult from "./pages/qrcode/ScanResult";
import Floorplans from "./pages/floorplans/Floorplans";
import FloorPlanView from "./pages/floorplans/FloorPlanView";
import ReaderView from "./pages/readers/ReaderView";
import PlanBy from "./components/planby";
import FloorplanForm from "./pages/floorplans/FloorplanForm";
import CagesByCustomerPage from "./pages/widgetPages/CagesByCustomerPage";
import CagesByDepotPage from "./pages/widgetPages/CagesByDepotPage";
import Chargers from "./pages/chargers/Chargers";
import ChargerEdit from "./pages/chargers/ChargerEdit";
import CagesInTransitPage from "./pages/widgetPages/CagesInTransitPage";
import ImageEdit from "./components/imageEdit/ImageEdit";
import AssetsNew from "./pages/assets/AssetsNew";
import BatteryLevelPage from "./pages/widgetPages/BatteryLevelPage";
import BatteryExchangePage from "./pages/widgetPages/BatteryExchangePage";
import DeploymentPage from "./pages/widgetPages/DeploymentPage";
import BatteryVoltagePage from "./pages/widgetPages/BatteryVoltagePage";
import DeploymentsPage from "./pages/widgetPages/DeploymentsPage";

// routers, different roles has their own router
const routes = [
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin", "User"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    exact: true,
    children: [
      {
        path: "scheduledtask",
        element: <TaskScheduled type={"showTasks"} />
      },
      {
        path: "completedtask",
        element: <CompletedTask type={"showTasks"} />
      },
      {
        path: "outstandingtask",
        element: <OutstandingTask type={"showTasks"} />
      },
      {
        path: "",
        element: <Default />
      },
      {
        path: "favourite",
        element: <FavouritePage />
      },
      {
        path: "batteryLevel",
        element: <BatteryLevelPage />
      },
      {
        path: "batteryExchanges",
        element: <BatteryExchangePage />
      },
      {
        path: "deployment",
        element: <DeploymentPage />
      },
      {
        path: "deployments",
        element: <DeploymentsPage />
      },
      {
        path: "batteryVoltage",
        element: <BatteryVoltagePage />
      },
      {
        path: "maps",
        element: <MapDashboard type={"showMap"} />
      }
    ]
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin", "User", "Root"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "assets",
        element: <Assets />
      },
      {
        path: "assetsnew",
        element: <AssetsNew />
      },
      {
        path: "people",
        element: <People />
      },
      {
        path: "zones",
        element: <Zones />
      },
      {
        path: "floorplans",
        element: <Floorplans type={"showFloorPlans"} />
      },
      {
        path: "trackers",
        element: <Trackers />
      },
      {
        path: "floorplanview",
        element: <FloorPlanView type={"showFloorPlans"} />
      },
      {
        path: "readers",
        element: <Readers />
      },
      {
        path: "tags",
        element: <Tags />
      },
      {
        path: "chargers",
        element: <Chargers />
      },
      {
        path: "assets/edit",
        element: <AssetsEdit />
      },
      {
        path: "zones/edit",
        element: <ZonesEdit />
      },
      {
        path: "/cagesByCustomer",
        element: <CagesByCustomerPage />
      },
      {
        path: "/cagesInTransit",
        element: <CagesInTransitPage />
      },
      {
        path: "/cagesByDepot",
        element: <CagesByDepotPage />
      },
      {
        path: "people/edit",
        element: <PeopleEdit />
      },
      {
        path: "rules/edit",
        element: <RulesEdit />
      },
      {
        path: "globalsearch",
        element: <GlobalSearch />
      },
      {
        path: "scanresult",
        element: <ScanResult />
      },
      {
        path: "pictureediting",
        element: <ImageEdit />
      }
    ]
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "hashtags",
        element: <HashTags />
      },
      {
        path: "alerthistory",
        element: <Alerts />
      },
      {
        path: "tasktypes",
        element: <TaskTypes type={"showTasks"} />
      },
      {
        path: "contactgroups",
        element: <ContactGroups />
      },
      {
        path: "rules",
        element: <Rules />
      },
      {
        path: "rules/create",
        element: <Create />
      },
      {
        path: "rules/wizard",
        element: <CreateWizard />
      },
      {
        path: "contactgroups/edit",
        element: <ContactEdit />
      }
    ]
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Root"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "customers",
        element: <Customers />
      },
      {
        path: "reader/flooplan",
        element: <ReaderView />
      },
      {
        path: "reallocate",
        element: <RealLocation />
      },
      {
        path: "customers/edit",
        element: <CustomerEdit />
      }, {
        path: "deviceProfiles",
        element: <DeviceProfile />
      },
      {
        path: "deviceProfiles/edit",
        element: <DeviceEdit />
      },
      {
        path: "readers/edit",
        element: <ReaderEdit />
      },
      {
        path: "tags/edit",
        element: <TagEdit />
      },
      {
        path: "solarchargers/edit",
        element: <ChargerEdit />
      },
      {
        path: "trackers/edit",
        element: <TrackerEdit />
      },
      {
        path: "floorplan/edit",
        element: <FloorplanForm />
      }
    ]
  },
  {
    path: "report",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "attendance",
        element: <Attendance />
      },
      {
        path: "positions",
        element: <Positions />
      }
    ]
  },
  {
    path: "/",
    element: <AuthGuard role={["Admin"]}>
      <DashboardLayout />
    </AuthGuard>,
    children: [
      {
        path: "planby",
        element: <PlanBy />
      },
      {
        path: "accessprofiles",
        element: <AccessProfiles />
      },
      {
        path: "accessprofiles/edit",
        element: <AccessProfilesEdit />
      }
    ]
  },
  {
    path: "account",
    element: <AuthLayout />,
    children: [{
      path: "register",
      element: <SignUp />
    }, {
      path: "resetpassword",
      element: <PasswordReset />
    }]
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />
      },
      {
        path: "reset-password",
        element: <ResetPassword />
      },
      {
        path: "404",
        element: <Page404 />
      },
      {
        path: "500",
        element: <Page500 />
      }
    ]
  },
  {
    path: "icons",
    element: <AuthGuard>
      <DashboardLayout />
    </AuthGuard>,
    children: [
      {
        path: "asset-icon",
        element: <Icons />
      }
    ]
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />
      }
    ]
  }
];

export default routes;
