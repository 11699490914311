import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NearByAssets = ({ result }) => {
  const [showModal, setShow] = useState(false);
  const [assets, setAssets] = useState([]);
  const assetsCurrent = useRef(assets);

  const getNearByAssets = () => {

    let arr = result.filter(item => (item.type === "asset"));
    if (assetsCurrent.current.join() === arr.join()) return;
    setAssets(arr);
    assetsCurrent.current = arr;
  };

  useEffect(() => {
    getNearByAssets();
  }, [result]);

  const tableColumns = [{
    Header: "",
    accessor: "icon",
    type: "icon",
    width: 40,
    headerStyle: {
      width: "40px"
    },
    disableSortBy: true
  }, {
    Header: "Name",
    accessor: "compoundName",
    Cell: cell => <NavLink onClick={() => setShow(false)} to={{
      pathname: `/assets/edit`,
      search: `?id=${cell.row.original.id}`
    }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
  }, {
    Header: "Voltage",
    accessor: "supply",
    Cell: cell => <div>{cell.value && cell.value > 0 ? `${cell.value} Mv` : ""}</div>
  }];

  return <React.Fragment>
    <div onClick={() => setShow(true)} className={`m-1 position-absolute z-50 cursor-pointer`}>
      <FontAwesomeIcon title={'Nearby Assets'} icon={faListAlt}
                       size={'lg'} color={'#293042'} className="bg-black"
                       fixedWidth />
    </div>
    <Modal show={showModal} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Visible Assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CommonTable data={assets} columns={tableColumns} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"secondary"} onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};

export default NearByAssets;