import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import moment from "moment-timezone";

const BatteryLevelPage = () => {

  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const { level } = queryString.parse(location.search);

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: `/assets/edit`,
      stateId: "id"
    },
    {
      Header: "Supply",
      accessor: "supply",
      Cell: cell => (
        <span>{cell.row.original?.lastPosition?.supply}</span>
      )
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={i} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>),
      disableSortBy: true
    }
  ];

  const highThreshold = () => {
    switch (level) {
      case 'Exchange':
        return 10999
      case 'High':
        return ''
      case 'Low':
        return 12000
      case 'Medium':
        return 13000
    }
  }

  const lowThreshold = () => {
    switch (level) {
      case 'Exchange':
        return ''
      case 'High':
        return 13001
      case 'Low':
        return 11000
      case 'Medium':
        return 12001
    }
  }

  return  <React.Fragment>
    <Helmet defer={false}  title={`Battery Level`} />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">Battery Level ({level}) ({resultCount})</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable secFilterId={'highThreshold'} secId={highThreshold()} id={lowThreshold()} filterId={'lowThreshold'} setCountParent={setResultCount} apiName={`reports/assetsbysupply`} columns={columns}/>
      </Card>
    </Container>
  </React.Fragment>
}
export default BatteryLevelPage